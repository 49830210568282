import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import "./star.css";

class Star extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment_rating: "",
      name_rating: "",
      email_rating: "",
      city_rating: "",
      ratingSelected: 1,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendReview = this.sendReview.bind(this);
    this.setStar = this.setStar.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async sendReview() {
    try {
      let docProd = await dbFirestore
        .collection("rating_products")
        .doc(this.props.id)
        .get();
      if (docProd.exists) {
        let newReview = docProd.data().review;
        newReview.push({
          rating: this.state.ratingSelected,
          comment: this.state.comment_rating,
          full_name: this.state.name_rating,
          email: this.state.email_rating,
          city_state: this.state.city_rating,
        });

        await dbFirestore
          .collection("rating_products")
          .doc(this.props.id)
          .update({ review: newReview });
        this.toggleModal();
      } else {
        const newReview = [];
        newReview.push({
          rating: this.state.ratingSelected,
          comment: this.state.comment_rating,
          full_name: this.state.name_rating,
          email: this.state.email_rating,
          city_state: this.state.city_rating,
        });
        await dbFirestore
          .collection("rating_products")
          .doc(this.props.id)
          .set({ review: newReview });
        this.toggleModal();
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.sendReview();
  }

  toggleModal() {
    this._isMounted &&
      this.setState({
        comment_rating: "",
        name_rating: "",
        email_rating: "",
        city_rating: "",
        ratingSelected: 1,
      });
    document.querySelector("#modal-review-close").click();
    document.querySelectorAll(".stars-file i").forEach((element, i) => {
      if (i !== 0) {
        element.classList.remove("fas");
        element.classList.remove("far");
        element.classList.add("far");
      }
    });
  }

  setStar(index) {
    this._isMounted && this.setState({ ratingSelected: index });
    document.querySelectorAll(".stars-file i").forEach((element, i) => {
      if (i !== 0) {
        element.classList.remove("fas");
        element.classList.remove("far");
        if (i <= index) {
          element.classList.add("fas");
        } else {
          element.classList.add("far");
        }
      }
    });
  }

  componentWillReceiveProps(props) {
    this.setStar(0);
    this._isMounted &&
      this.setState({
        comment_rating: "",
        name_rating: "",
        email_rating: "",
        city_rating: "",
        ratingSelected: 1,
      });
  }

  render() {
    return (
      <div className="Star my-5 py-3">
        <div className="row">
          <div className="col-12">
            <h1 className="rating_title center closit_text">
              Ratings and Reviews
            </h1>
          </div>
          <div className="col-12">
            <p className="rating_text center">
              Tell us what's up! Rate this item
            </p>
          </div>
          <div className="col-12 center stars-file">
            <i
              className="fas fa-star rating-star star-1"
              onClick={(e) => this.setStar(0)}
            />
            <i
              className="far fa-star rating-star star-2"
              onClick={(e) => this.setStar(1)}
            />
            <i
              className="far fa-star rating-star star-3"
              onClick={(e) => this.setStar(2)}
            />
            <i
              className="far fa-star rating-star star-4"
              onClick={(e) => this.setStar(3)}
            />
            <i
              className="far fa-star rating-star star-5"
              onClick={(e) => this.setStar(4)}
            />
          </div>
          <div className="col-12 center mt-3">
            <button
              className="btn btn-secondary btn-menu btn-size star_btn"
              type="button"
              data-toggle="modal"
              data-target="#exampleModalCenter"
            >
              Review Now
            </button>

            <div
              className="modal fade"
              id="exampleModalCenter"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="container modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content star-modal">
                  <button
                    type="button"
                    className="close x"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="modal-review-close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="modal-header">
                    <div className="col-12 col-lg-12">
                      <p
                        className="modal-title star-title_container mt-3"
                        id="exampleModalCenterTitle"
                      >
                        Review Now
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-12">
                    <p className="modal-subtitle">
                      Give us the dish on your items, shopping experience, or
                      any else you'd like to share with the Gary Nader
                      community!
                    </p>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.handleSubmit}>
                      <div className="col-12 mb-3">
                        <textarea
                          className="form-control modal-input"
                          placeholder="What do you like/dislike the most about this item? Describe the fit of the item, would you recommend this product to others? Why or why not?"
                          id="exampleFormControlTextarea1"
                          rows="6"
                          onChange={(e) => {
                            this._isMounted &&
                              this.setState({ comment_rating: e.target.value });
                          }}
                          value={this.state.comment_rating}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control mb-3 modal-input"
                          name="name"
                          type="text"
                          placeholder="Full name (Optional)"
                          onChange={(e) => {
                            this._isMounted &&
                              this.setState({ name_rating: e.target.value });
                          }}
                          value={this.state.name_rating}
                        />
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control mb-3 modal-input"
                          name="email"
                          type="email"
                          placeholder="Email (Optional)"
                          onChange={(e) => {
                            this._isMounted &&
                              this.setState({ email_rating: e.target.value });
                          }}
                          value={this.state.email_rating}
                        />
                        <p className="hidden error-text error-email">
                          Please enter a valid email.
                        </p>
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control mb-3 modal-input"
                          name="city"
                          type="text"
                          placeholder="City, State (Optional)"
                          onChange={(e) => {
                            this._isMounted &&
                              this.setState({ city_rating: e.target.value });
                          }}
                          value={this.state.city_rating}
                        />
                      </div>
                      <div className="col-12 center mt-4 mb-4">
                        <button
                          className="btn btn-secondary btn-menu btn-size"
                          type="submit"
                        >
                          Submit Review
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Star;
