//Dependencies
import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase/index";
import { Link, Redirect } from "react-router-dom";
import "./cards.css";
import Loader from "react-loader-spinner";
import * as firebase from "firebase/app";
import $ from "jquery";
import { metasTags } from "../../#constants";
import { toast } from "react-toastify";
require("firebase/functions");

class CardsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      user: {},
      logged: false,
      infoUser: { default_card: "" },
      default_card: "",
      redirectLot: false,
      lot: "",
      bidSelected: 0,
      auction: false,
      redirect: false,
      visibleLoader: true,
      visibleLoaderAll: false,
      checkout: false,
      continue: false,
      showModal: false,
      auctionData: {}
    };
    this.dismarkAll = this.dismarkAll.bind(this);
    this.init = this.init.bind(this);
    this.handleMakeBid = this.handleMakeBid.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.data.auction) {
        this._isMounted && this.setState({ auction: true, auctionData: this.props.location.state.data.auctionData, lot: this.props.location.state.data.lot, bidSelected: this.props.location.state.data.bidSelected });
      }
      if (this.props.location.state.data.checkout) {
        this._isMounted && this.setState({ checkout: true });
      }
    }
    else {
      //sin props se debe mandar al home
      this._isMounted && this.setState({ redirect: true });
    }
    auth.onAuthStateChanged(user => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted && this.setState({ user: userLogged, logged: true, redirect: false });
        this.init();
      }
      else {
        this._isMounted && this.setState({ user: {}, logged: false, redirect: true, infoUser: {}, visibleLoader: false });
      }
    });
  }

  async init() {
    try {
      let snapshot = await dbFirestore.collection("users").doc(auth.currentUser.uid).get();
      let infoUser = snapshot.data();
      this._isMounted && this.setState({ infoUser: infoUser, default_card: infoUser.default_card });
      if (infoUser.customer_id !== "") {

        var listCreditCards = firebase.functions().httpsCallable("listCards");
        let result = await listCreditCards({ customer_id: infoUser.customer_id })
        this._isMounted && this.setState({ data: result.data.data }, () => {
          this._isMounted && this.setState({ visibleLoader: false });
        });
      }
      else {
        this._isMounted && this.setState({ visibleLoader: false });
      }
    } catch (error) {
      this._isMounted && this.setState({ visibleLoader: false });
      console.log(error);
    }
  }

  componentWillReceiveProps(props) {
    this.componentDidMount();
  }

  async selectDefaultCard(event, item, indice) {
    const value = event.target.checked;
    var actual_defaultCard = this.state.default_card;
    this._isMounted && this.setState({ visibleLoaderAll: true }, async () => {

      if (!value) {
        this._isMounted && this.setState({ default_card: "" }, () => {
          this._isMounted && this.setState({ default_card: item.id, visibleLoaderAll: false });
        }
        );
      }
      else {
        this._isMounted && this.setState({ default_card: item.id });
        var updateCard = firebase.functions().httpsCallable("updateDefaultCard");
        try {
          await updateCard({ customer_id: this.state.infoUser.customer_id, card_id: item.id });
          await dbFirestore.collection("users").doc(this.state.user.uid).update({ default_card: item.id });
          this.dismarkAll(indice);
          this._isMounted && this.setState({ default_card: item.id, visibleLoaderAll: false });
        }
        catch (error) {
          this._isMounted && this.setState({ default_card: actual_defaultCard, visibleLoaderAll: false });
          console.error("Error ", error);
        }
      }
    });
  }

  dismarkAll(index) {
    document.querySelectorAll(".switch input").forEach((value, i) => {
      if (index !== i) {
        value.checked = false;
      }
    });
  }

  deleteCard = item => {
    var deleteCreditCart = firebase.functions().httpsCallable("deleteCard");
    this._isMounted && this.setState({ visibleLoaderAll: true }, async () => {
      try {
        let result = await deleteCreditCart({ customer_id: this.state.infoUser.customer_id, card_id: item.id })
        if (result.data.deleted) {
          let actualData = this.state.data;
          let newdata = actualData.filter(value => value.id !== item.id);
          this._isMounted && this.setState({ data: newdata }, async () => {
            if (item.id === this.state.default_card) {
              try {
                await dbFirestore.collection("users").doc(this.state.user.uid).update({ default_card: "" })
                this._isMounted && this.setState({ default_card: "", visibleLoaderAll: false });
              }
              catch (error) {
                this._isMounted && this.setState({ visibleLoaderAll: false });
                console.error("Error ", error);
              }
            }
            else {
              this._isMounted && this.setState({ visibleLoaderAll: false });
            }
          });
        }
      }
      catch (error) {
        this._isMounted && this.setState({ visibleLoaderAll: false });
        console.error("Error ", error);
      }

    });
  };

  renderItems = () => {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.data !== null && this.state.data !== undefined) {
        if (this.state.data.length > 0) {
          let cards = [];
          this.state.data.forEach((card, index) => {
            cards.push(
              <div className="card cards-card" key={"card-" + index}>
                <div className="card-body cards-card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-6 first-col-delivery">
                        <p className="name-card closit_text">
                          {card.brand} end {card.last4}{" "}
                        </p>
                        <p className="year-card">
                          {card.exp_month}/{card.exp_year}
                        </p>
                        {this.state.default_card === card.id && (
                          <p className="prefered-card">My preferred card</p>
                        )}
                      </div>
                      <div className="col-6 second-col-delivery">
                        <div className="top-delivery">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                this.state.default_card === card.id
                                  ? true
                                  : false
                              }
                              onChange={value => {
                                this.selectDefaultCard(value, card, index);
                              }}
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="bottom-delivery">
                          <p
                            className="trash-cards"
                            onClick={() => {
                              this.deleteCard(card);
                            }}
                          >
                            <i className="fas fa-trash" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          return cards;
        }
      }
    }
  };

  async handleMakeBid(bidSelected) {

    $('#ConfirmBidModal').modal('hide');
    this.setState({ visibleLoaderAll: true });
    var makeBid = firebase.functions().httpsCallable("makeBid");

    const cards_data = this.state.data;
    const index_default_card = cards_data.findIndex(x => x.id === this.state.default_card);

    try {
      await makeBid({ 
        bidSelected, 
        lotID: this.state.lot, 
        auction: this.state.auctionData,
        card_data: cards_data[index_default_card] 
      });
      this._isMounted && this.setState({ continue: true });
    }
    catch (error) {
      this._isMounted && this.setState({ visibleLoaderAll: false });
      if (error.details) {
        this.showNotification("Bid Error", error.message);
      }
      else {
        this.showNotification("Bid Error", "An error occurred, please try again.");
      }
    }
  }

  async showConfirm() {
    this.handleMakeBid(this.state.bidSelected);
    // this.setState({ showModal: true }, () => {
    //   $('#ConfirmBidModal').modal('show');
    //   this.handleClose();
    // });
  }

  handleClose() {
    $('#ConfirmBidModal').on('hidden.bs.modal', () => {
      this.setState({ showModal: false });
    });
  }

  showNotification(title, message) {

    toast.info(
      <div>
        <p className="notify-title">{title}</p>
        <p className="notify-description">{message}</p>
      </div>,
      {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        className: "notify",
        bodyClassName: "notify-body"
      }
    );

  }

  render() {
    return (
      <div className="Cards">
        {this.state.visibleLoader && (
          <div className="loader-cards">
            <Loader type="Oval" color="#000" height="50" width="50" />
          </div>
        )}
        {this.state.visibleLoaderAll && (
          <div className="loader-area">
            <div className="loader-box">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        {this.state.redirect && <Redirect push to={"/"} />}
        <div className="container-fluid bg-intro-cards">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center title-cards my-3 closit_text">
                  Your Credit Cards
                </h1>
              </div>
              <div className="col-12 col-md-6">
                {!this.state.checkout && this.state.auction && (
                  <Link to={"/lot/" + this.state.lot}>
                    <button className="btn btn-secondary btn-new-cards">Go Back</button>
                  </Link>
                )}
                {!this.state.checkout && !this.state.auction && (
                  <Link to={"/settings"}>
                    <button className="btn btn-secondary btn-new-cards">Go Back</button>
                  </Link>
                )}
                {this.state.checkout && !this.state.auction && (
                  <Link to={"/checkout"}>
                    <button className="btn btn-secondary btn-new-cards">Go Back</button>
                  </Link>
                )}

              </div>
              <div className="col-12 col-md-6">
                {this.state.auction && this.state.data.length === 0 && (
                  <Link
                    to={{
                      pathname: "/new-card",
                      state: {
                        data: {
                          auction: true,
                          lot: this.state.lot,
                          bidSelected: this.state.bidSelected,
                          auctionData: this.state.auctionData
                        }
                      }
                    }}
                  >
  
                    <button className="btn btn-secondary btn-new-cards">
                      Add New Credit Card
                    </button>
                  </Link>
                )}

                
                {!this.state.auction && (
                  <Link to={{ pathname: "/new-card", state: { data: { checkout: this.state.checkout } } }}>
                    <button className="btn btn-secondary btn-new-cards">
                      Add New Credit Card
                    </button>
                  </Link>
                )}
              </div>

            </div>
          </div>
        </div>
        <div className="container-fluid no-pd">
          <div className="container">
            <div className="row">
              <div className="col-12 new-col-pd">{this.renderItems()}</div>
            </div>
          </div>
        </div>
        {this.state.auction && this.state.lot !== "" && (
          <div className="container-fluid bg-intro-cards cards-auctions">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <button disabled={this.state.default_card === "" ? true : false}
                    className="btn btn-secondary btn-new-cards auctions"
                    onClick={() => { this.showConfirm() }}
                  >
                    PROCEED WITH THE BID
                  </button>
                  {this.state.continue && (<Redirect push to={"/lot/" + this.state.lot} />)}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {this.state.auction && this.state.lot !== "" && this.state.showModal && (
          <ConfirmBidModal lot={this.state.lot} bidSelected={this.state.bidSelected} handleMakeBid={this.handleMakeBid.bind(this)} />
        )} */}
      </div>
    );
  }
}

export default CardsPage;
