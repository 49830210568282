//Dependencies
import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import { Link } from "react-router-dom";
import "./editor-card.css";

class EditorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          principalImage: ".",
          title: ".",
          description: "."
        }
      ],
      empty:false
    };
    this.init = this.init.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.init();
  }

  async init(){
    let snapshot = await dbFirestore.collection("categorys").where("publish", "==", true).where("typeOf", "==", "editor").orderBy("updatedAt", "desc").limit(5).get()
    let data = [];
    snapshot.forEach(doc => {data.push(doc.data());});
    if (data.length !== 0) {
      this._isMounted && this.setState({data: data});
    }
    else{
      this._isMounted && this.setState({data: [], empty:true });
    }
    if (data.length <= this.props.position) {
      this.setState({empty:true});
    }

  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  createSlide(){
    if (this.state.data.length > 0) {
      if (this.state.data.length > this.props.position) {
        const pos = this.props.position;
        let slides = [];
        slides.push(
          <div className="carousel-item active" key={"editor-card-" + pos}>
            <Link to={"/categories/editor/" + this.state.data[pos].id}>
              <div className="overlay" />
              <img className="d-block w-100" src={this.state.data[pos].principalImageWeb} alt={"Slide" + pos}/>
            </Link>

            <div className="carousel-caption">
              <h5 className="title-editor-card">
                <Link to={"/categories/editor/" + this.state.data[pos].id} className="closit_title">
                  {this.state.data[pos].title}
                </Link>
              </h5>
              <p className="text-editor-card">
                <Link to={"/categories/editor/" + this.state.data[pos].id}>
                  {this.state.data[pos].description}
                </Link>
              </p>
            </div>
          </div>
        );
        return slides;
      }
    }
  };

  render() {
    if(this.state.empty){
      return(<div/>)
    }
    return (
      <div className="EditorCards">
        <div className="container-fluid separator-section">
          <div className="row">
            <div className="col-12 no-pd">
              <div className="carousel container slide carousel-editor-card no-pd" data-ride="carousel">
                <div className="carousel-inner">{this.createSlide()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditorCard;
