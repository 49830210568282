//Dependencies
import React, { Component } from "react";
import "./categories.css";
import CategoryDetails from "../../@components/category-details";
import { metasTags } from "../../#constants";

class CategoriesPage extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
    metasTags.setMetaTags();
  }
  render() {
    const typeCategory = this.props.match.params.cat;
    const idCategory = this.props.match.params.id;
    return (
      <div className="Categories-Page">
        <CategoryDetails categoryType={typeCategory} idCategory={idCategory}/>
      </div>
    );
  }
}

export default CategoriesPage;
