//Dependencies
import React, { Component } from "react";
import PropTypes from "prop-types";
// import Navbar from "./@components/navbar";
import Nav from "./@components/nav";
import Footer from "./@components/footer";
import BtnTop from "./@components/btn-top";
import $ from "jquery";
import { ToastContainer } from "react-toastify";

class Layout extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.backBtnMobile = this.backBtnMobile.bind(this);
  }
  componentDidMount() {
    this.backBtnMobile();
  }
  backBtnMobile() {
    $(window).on('popstate', function () {
      if ($(".modal-backdrop.show").length > 0) {
        $(".modal-backdrop.show").remove();
        $("body").removeClass("modal-open");
      }
    });
  }

  render() {
    const { children } = this.props;
    return (
      <div className="layout">
        <Nav ref={this.shopBadge} />
        {children}
        <Footer />
        <BtnTop />
        <ToastContainer position="bottom-center" autoClose={500} />
      </div>
    );
  }
}
export default Layout;
