import React, { useEffect, useState, Fragment } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import ClositRoutes from "./routes";

import { withSnackbar } from "notistack";
import * as serviceWorker from "./registerServiceWorker";
import { Button } from "@material-ui/core";


const App = (props) => {
  const [state, setState] = useState({
    newVersionAvailable: false,
    waitingWorker: {}
  });

  useEffect(() => {
    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
  }, []);


  useEffect(() => {
    const updateServiceWorker = () => {
      const { waitingWorker } = state;
      waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
      setState(s => ({ ...s, newVersionAvailable: false }));
      window.location.reload();
    };

    const refreshAction = (key) => { //render the snackbar button
      return (
        <Fragment>
          <Button
            className="snackbar-button"
            size="small"
            onClick={updateServiceWorker}
          >
            {"Update"}
          </Button>
        </Fragment>
      );
    };

    const { enqueueSnackbar } = props;

    if (state.newVersionAvailable) //show snackbar with refresh button
      enqueueSnackbar("A new version was released", {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        persist: true,
        variant: 'info',
        action: refreshAction(),
      });

  }, [state, props]);



  const onServiceWorkerUpdate = (registration) => {
    setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  return (
    <Router>
      <ClositRoutes />
    </Router>
  );
}

export default withSnackbar(App);