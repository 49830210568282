import React from 'react'

const Quote = (quote, index) => {

  return (
    <div className='quote' key={index}>
      <p style={{ textAlign: 'center' }}> {quote.description} </p>
      <p style={{ paddingTop: 20 }}>{quote.writer}</p>
    </div>
  )
}

export default Quote