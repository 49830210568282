//Dependencies
import React, { Component } from "react";
import "./shop.css";
import ShopItemCard from "../../@components/shop-item-card";
import { metasTags } from "../../#constants";

class ShopInfluencerPage extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
    metasTags.setMetaTags();
  }
  render() {
    return (
      <div className="Shop">
        <ShopItemCard type="influencer"/>
      </div>
    );
  }
}

export default ShopInfluencerPage;
