import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
require("firebase/firestore");

/**
 * Production Credentials
 */
var prodConfig = {
  apiKey: "AIzaSyD52QNGeGUXgIdPNiypU8YFniXgtJ3NU-k",
  authDomain: "nader-art.firebaseapp.com",
  databaseURL: "https://nader-art.firebaseio.com",
  projectId: "nader-art",
  storageBucket: "nader-art.appspot.com",
  messagingSenderId: "935703803584",
  appId: "1:935703803584:web:cf4178a22529edcc",
  measurementId: "G-P0W4GLSSCL",
};

var devConfig = {
  apiKey: "AIzaSyDRkraYhFMwehn2AuV7h6G8jdTlc425-eQ",
  authDomain: "nader-art-dev.firebaseapp.com",
  databaseURL: "https://nader-art-dev.firebaseio.com",
  projectId: "nader-art-dev",
  storageBucket: "nader-art-dev.appspot.com",
  messagingSenderId: "1054792276916",
  appId: "1:1054792276916:web:a5aa29476bcea35d28f0e1",
  measurementId: "G-HV0B7CLN4X",
};

const config =
  process.env.REACT_APP_INSTA_ENV === "production" ? prodConfig : prodConfig;
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const storage = firebase.storage();
const dbFirestore = firebase.firestore();
const auth = firebase.auth();
const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export { storage, dbFirestore, auth, timestamp };
