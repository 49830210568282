import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
// eslint-disable-next-line
import $ from "jquery";
// eslint-disable-next-line
import * as JQ from "jquery-touchswipe";
// eslint-disable-next-line
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "animate.css";
import "./styles/index.css";
import "./styles/fonts.css";

import App from "./App";

import { SnackbarProvider } from "notistack";

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);