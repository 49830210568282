//Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "../../assets/logo-white.png";

class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        {/* <div className="footer_container">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 line">
                <p className="footer_title closit_title">
                  Partnering with Gary Nader
                </p>
                <p className="footer_text">Gary Nader for Brands</p>
                <p className="footer_text mb-3">Gary Nader for Agencies</p>
              </div>
              <div className="col-lg-4 line">
                <p className="footer_title closit_title">
                  General questions & feedback
                </p>
                <p className="footer_text">Contact Gary Nader</p>
                <p className="footer_text">Send us Feedback</p>
              </div>

              <div className="col-lg-3 line">
                <p className="footer_title closit_title">About Gary Nader</p>
                <p className="footer_text">About</p>
                <p className="footer_text">Blog</p>
                <p className="footer_text">Jobs</p>
                <p className="footer_text mb-3">Press</p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="footer_container">
          <div className="container">
            {/* <div className="footer_separator" /> */}
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <Link to={"/"} className="logo-footer">
                  <img src={logo} className="logoFooter" alt="Gary Nader" />
                </Link>
              </div>

              {/* <div className="final_container col-lg-8 col-md-7 col-sm-12 col-12 mb-2">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-3 col-sm-12 col-lg-3">
                    <p className="footer_text">&copy;2020 Gary Nader</p>
                  </div>
                  <div className="col-12  col-sm-12 col-md-3 col-lg-2">
                    <p className="footer_text">
                      <Link to={"/terms"}>Terms of Use</Link>
                    </p>
                  </div>
                  <div className="col-12  col-sm-12 col-md-3 col-lg-2">
                    <p className="footer_text">Privacy Policy</p>
                  </div>
                  <div className="col-12  col-sm-12 col-md-3 col-lg-2">
                    <p className="footer_text">Security</p>
                  </div>
                </div>
              </div> */}

              {/* <div className="social_container col-lg-2 col-md-2 col-sm-12 col-12">
                <div className="row justify-content-center">
                  <div className="col-2">
                    <a
                      href="http://www.facebook.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fab fa-facebook" />{" "}
                    </a>
                  </div>
                  <div className="col-2">
                    <a
                      href="http://wwww.twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />{" "}
                    </a>
                  </div>
                  <div className="col-2">
                    <a
                      href="http://www.instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <i className="fab fa-instagram" />{" "}
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
