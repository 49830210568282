//Dependencies
import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./home.css";
import Slider from "../../@components/slider";
import Category from "../../@components/category";
import EditorCard from "../../@components/editor-card";
import JournalCardMini from "../../@components/journal-card-mini";
import NotificationSlider from "../../@components/notification-slider";
import HomeShop from "../../@components/home-shop";
import { metasTags } from "../../#constants";
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.category1 = React.createRef();
    this.category2 = React.createRef();
    this.category3 = React.createRef();
    this.category4 = React.createRef();
    this.category5 = React.createRef();
    this.category6 = React.createRef();
    this.category7 = React.createRef();
    this.category8 = React.createRef();
    this.category9 = React.createRef();
    this.category10 = React.createRef();
  }
  componentDidMount() {
    window.scroll(0, 0);
    metasTags.setMetaTags();
  }

  activeRefreshWish() {
    this.category1.current.refreshWish();
    this.category2.current.refreshWish();
    this.category3.current.refreshWish();
    this.category4.current.refreshWish();
    this.category5.current.refreshWish();
    this.category6.current.refreshWish();
    this.category7.current.refreshWish();
    this.category8.current.refreshWish();
    this.category9.current.refreshWish();
    this.category10.current.refreshWish();
  }
  render() {
    return (
      <div className="Home">
        <NotificationSlider />
        <Slider />
        <HomeShop />
        <Category
          categoryType="featured"
          postSize={6}
          index={0}
          ref={this.category1}
          refresh={this.activeRefreshWish.bind(this)}
        />
        <Category
          categoryType="featured"
          postSize={6}
          index={1}
          ref={this.category2}
          refresh={this.activeRefreshWish.bind(this)}
        />
        <EditorCard position={0} />
        <Category
          categoryType="general"
          postSize={6}
          index={0}
          ref={this.category3}
          refresh={this.activeRefreshWish.bind(this)}
        />
        <Category
          categoryType="general"
          postSize={6}
          index={1}
          ref={this.category4}
          refresh={this.activeRefreshWish.bind(this)}
        />
        <EditorCard position={1} />
        <Category
          categoryType="general"
          postSize={6}
          index={2}
          ref={this.category5}
          refresh={this.activeRefreshWish.bind(this)}
        />
        <Category
          categoryType="general"
          postSize={6}
          index={3}
          ref={this.category6}
          refresh={this.activeRefreshWish.bind(this)}
        />
        <JournalCardMini />
        <Category
          categoryType="designer"
          postSize={6}
          index={0}
          ref={this.category7}
          refresh={this.activeRefreshWish.bind(this)}
        />
        <Category
          categoryType="designer"
          postSize={6}
          index={1}
          ref={this.category8}
          refresh={this.activeRefreshWish.bind(this)}
        />
        <Category
          categoryType="designer"
          postSize={6}
          index={2}
          ref={this.category9}
          refresh={this.activeRefreshWish.bind(this)}
        />
        <Category
          categoryType="designer"
          postSize={6}
          index={3}
          ref={this.category10}
          refresh={this.activeRefreshWish.bind(this)}
        />
      </div>
    );
  }
}

export default HomePage;
