//Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { dbFirestore } from "../../@firebase";
import Loader from "react-loader-spinner";
import "./home-shop.css";

class HomeShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      visibleLoader: true,
    };

    this.getRamdonImages = this.getRamdonImages.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getRamdonImages();
  }

  async getRamdonImages() {
    let general = await dbFirestore
      .collection("categorys")
      .where("publish", "==", true)
      .where("typeOf", "==", "general")
      .orderBy("updatedAt", "desc")
      .limit(50)
      .get();
    let influencer = await dbFirestore
      .collection("categorys")
      .where("publish", "==", true)
      .where("typeOf", "==", "influencer")
      .orderBy("updatedAt", "desc")
      .limit(50)
      .get();
    let designer = await dbFirestore
      .collection("categorys")
      .where("publish", "==", true)
      .where("typeOf", "==", "designer")
      .orderBy("updatedAt", "desc")
      .limit(50)
      .get();
    //GENERAL
    var ramdomNumber;
    if (general.size > 0) {
      ramdomNumber = Math.floor(Math.random() * (general.size - 0) + 0);
      var imgG = general.docs[ramdomNumber].data().principalImage;
    }

    //INFLUENCER
    if (influencer.size > 0) {
      ramdomNumber = Math.floor(Math.random() * (influencer.size - 0) + 0);
      var imgI = influencer.docs[ramdomNumber].data().principalImage;
    }

    //DESIGNER
    if (designer.size > 0) {
      ramdomNumber = Math.floor(Math.random() * (designer.size - 0) + 0);
      var imgD = designer.docs[ramdomNumber].data().principalImage;
    }

    this._isMounted &&
      this.setState({
        general: imgG,
        influencer: imgI,
        designer: imgD,
        visibleLoader: false,
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const designerStyle = {
      backgroundImage: "url(" + this.state.designer + ")",
      width: "100%",
      height: "100%",
      float: "left",
      lineHeight: "400px",
      fontSize: 24,
      color: "#fff",
      backgroundSize: "cover",
      backgroundPosition: "top",
    };
    const generalStyle = {
      backgroundImage: "url(" + this.state.general + ")",
      width: "100%",
      height: "100%",
      float: "left",
      lineHeight: "400px",
      fontSize: 24,
      color: "#fff",
      backgroundSize: "cover",
      backgroundPosition: "top",
    };
    return (
      <div className="home-shop">
        {this.state.visibleLoader && (
          <div className="loader-home-shop-area">
            <div className="loader-home-shop">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}

        <div className="row">
          {/* <div className="col-12 col-md-4 pdr-0">
            <div className="box-home-shop">
              <Link
                to={"/categories/influencer"}
                style={influencerStyle}
                className="closit_title"
              >
                INFLUENCER
              </Link>
            </div>
          </div> */}

          <div className="home-shop-image">
            <div className="box-home-shop">
              <Link
                to={"/categories/artists"}
                style={designerStyle}
                className="closit_title"
              >
                ARTISTS
              </Link>
            </div>
          </div>

          <div className="home-shop-image">
            <div className="box-home-shop">
              <Link
                to={"/categories/general"}
                style={generalStyle}
                className="closit_title"
              >
                CATEGORIES
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeShop;
