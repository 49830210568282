//Dependencies
import React, { Component } from "react";
import "./journal.css";
import JournalCard from "../../@components/journal-card";
import { metasTags } from "../../#constants";
class JournalPage extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
    metasTags.setMetaTags();
  }
  render() {
    return (
      <div className="Journal">
        <JournalCard/>
      </div>
    );
  }
}

export default JournalPage;
