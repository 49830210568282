//Dependencies
import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import { Link } from "react-router-dom";
import "./shop-item-card.css";
import Loader from "react-loader-spinner";

class ShopItemCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      data: [],
      visibleLoader: true
    };
    this.orderAlpha = this.orderAlpha.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.type === "influencer") {
      this.influencer();
    } else if (this.props.type === "designer") {
      this.designer();
    } else {
      this.general();
    }
  }
  async general() {
    try {
      let snapshot = await dbFirestore.collection("categorys").where("publish", "==", true).where("typeOf", "==", "general").orderBy("updatedAt", "desc").get();
      let data = [];
      let dataCategory = [];
      snapshot.forEach(doc => {
        dataCategory.push({ data: doc.data() });
        data.push(doc.data());
      });
      this._isMounted && this.setState({data: data,visibleLoader: false});
    } catch (error) {
      console.log(error);
      this._isMounted && this.setState({visibleLoader: false});
    }
      
  }
  async influencer() {
    try {
      let snapshot = await dbFirestore.collection("categorys").where("publish", "==", true).where("typeOf", "==", "influencer").orderBy("updatedAt", "desc").get();
      let data = [];
      snapshot.forEach(doc => {
        data.push(doc.data());
      });
      let sortRandom = this.shuffle(data);
      this._isMounted && this.setState({data: sortRandom,visibleLoader: false});
    } catch (error) {
      console.log(error);
      this._isMounted && this.setState({visibleLoader: false});
    }    
  }

  async designer() {
    try {
      let snapshot = await dbFirestore.collection("categorys").where("publish", "==", true).where("typeOf", "==", "designer").get();
      let data = [];
      snapshot.forEach(doc => {
        data.push(doc.data());
      });
      let sortRandom = this.orderAlpha(data);
      this._isMounted && this.setState({data: sortRandom,visibleLoader: false});
    } catch (error) {
      console.log(error);
      this._isMounted && this.setState({visibleLoader: false});
    }  
  }
  componentWillUnmount() {this._isMounted = false;}

  orderAlpha(data) {
    return data.sort(function(a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
  }

  shuffle(array) {
    var currentIndex = array.length,temporaryValue,randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  createSlide = () => {
    if (this.state.data.length > 0) {
      let slides = [];
      for (var i = 0; i < this.state.data.length; i++) {
        slides.push(
          <div className="col-12 col-md-6 col-lg-4 col-shop-item-card" key={"shop-item-" + i} >
            <div className="carousel slide carousel-shop-item-card" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <Link to={"/categories/" +this.props.type +"/" +this.state.data[i].id}>
                    <div className="sidebar-overlay" />
                    <img className="d-block w-100" src={this.state.data[i].principalImage} alt={"Slide" + i}/>
                  </Link>
                  <div className="carousel-caption caption-center">
                    <Link className="caption-tgt" to={"/categories/" +this.props.type +"/" +this.state.data[i].id}>
                      <h5 className="title-shop-item-card closit_title">{this.state.data[i].title}</h5>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return slides;
    }
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.visibleLoader && (
          <div className="loader-shop">
            <Loader type="Oval" color="#000" height="50" width="50" />
          </div>
        )}
        <div className="row">{this.createSlide()}</div>
      </div>
    );
  }
}

export default ShopItemCard;
