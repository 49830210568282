import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { dbFirestore, auth } from "../../@firebase";
import "./auction-detail.css";
import Loader from "react-loader-spinner";
import LoginForm from "../../@components/login";
import { monthNames, metasTags, formatNumber } from "../../#constants";
import CountDownSan from "../../@components/countdown";

class AuctionDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      totalLots: 0,
      auction: {},
      timed: true,
      end: 0,
      start: 0,
      logged: false,
      redirect404: false,
      visibleLoader: true,
      wishlist_lots: [],
      infoUser: {},
      formVisible: false,
      formType: "",
      redirectAuctions: false,
    };
    this.terminate = this.terminate.bind(this);
    this.existWishList = this.existWishList.bind(this);
    this.gotoWishlistItem = this.gotoWishlistItem.bind(this);
    this.close = this.close.bind(this);
    this.displayForm = this.displayForm.bind(this);
    this.createBannerTimed = this.createBannerTimed.bind(this);
    this.createBannerLive = this.createBannerLive.bind(this);
    this._isMounted = false;
    this._completed = false;
  }

  rendererTimed = ({ days, hours, minutes, seconds, completed }) => {
    const countdown = (
      <div className="clockdiv-transparent">
        <div>
          <span className="days">{days}</span>
          <div className="smalltext">Days</div>
        </div>
        <div>
          <span className="hours">{hours}</span>
          <div className="smalltext">Hours</div>
        </div>
        <div>
          <span className="minutes">{minutes}</span>
          <div className="smalltext">Minutes</div>
        </div>
        <div>
          <span className="seconds">{seconds}</span>
          <div className="smalltext">Seconds</div>
        </div>
      </div>
    );
    if (completed) {
      // Render a complete state
      this.terminate();
      return countdown;
    } else {
      // Render a countdown
      return countdown;
    }
  };

  rendererLive = ({ days, hours, minutes, seconds }) => {
    const countdown = (
      <div className="clockdiv-transparent">
        <div>
          <span className="days">{days}</span>
          <div className="smalltext">Days</div>
        </div>
        <div>
          <span className="hours">{hours}</span>
          <div className="smalltext">Hours</div>
        </div>
        <div>
          <span className="minutes">{minutes}</span>
          <div className="smalltext">Minutes</div>
        </div>
        <div>
          <span className="seconds">{seconds}</span>
          <div className="smalltext">Seconds</div>
        </div>
      </div>
    );
    // Render a countdown
    return countdown;
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this._isMounted &&
          this.setState({
            logged: true,
            visible: true,
            visibleLoader: true,
            user: auth.currentUser,
          });
        let snapshot = await dbFirestore
          .collection("users")
          .doc(user.uid)
          .get();
        this._isMounted &&
          this.setState({
            infoUser: snapshot.data(),
            wishlist_lots: snapshot.data().wishlist_lots,
          });
      } else {
        this._isMounted &&
          this.setState({ logged: false, visible: true, visibleLoader: true });
      }
      this.init();
    });
  }

  async init() {
    const auctionId = this.props.match.params.id;
    let snapshot = await dbFirestore
      .collection("lots")
      .where("publish", "==", true)
      .where("auction", "==", auctionId)
      .orderBy("order", "asc")
      .get();
    if (!snapshot.empty) {
      let dataLot;
      let dataSource = [];
      let json = { data: [], key: "" };
      let number = 1;
      let cant = 0;
      snapshot.forEach((doc) => {
        dataLot = doc.data();
        dataLot.number = number;
        dataLot.cantBids = doc.data().bids_users.length;
        json.data.push(dataLot);
        number++;
        cant++;
      });
      dataSource.push(json);
      this._isMounted &&
        this.setState({
          totalLots: cant,
          data: dataSource,
          visible: false,
          visibleLoader: false,
        });
    } else {
      this._isMounted && this.setState({ visibleLoader: false });
    }

    let snapshotA = await dbFirestore
      .collection("auctions")
      .doc(auctionId)
      .get();
    if (snapshotA.exists) {
      const auction = snapshotA.data();
      this._isMounted && this.setState({ auction: auction });
      /// Verificar aca el complete con la data auction
      let seconds = Math.round(new Date().getTime() / 1000);
      let end = auction.end.seconds;
      let start = auction.start.seconds;
      this._isMounted && this.setState({ end: end, start: start });

      if (end < seconds) {
        this._isMounted && this.setState({ timed: false });
      }

      if (start < seconds) {
        this._isMounted && this.setState({ timed: false });
      }
    } else {
      this._isMounted &&
        this.setState({ redirect404: true, visibleLoader: false });
    }
  }

  terminate() {
    // si termina y es timed cambiar tiempo pq ahora es live
    if (this.state.timed) {
      this._isMounted && this.setState({ timed: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setCloseDate() {
    if (this.state.auction !== null && this.state.auction !== undefined) {
      if (
        this.state.auction.end !== null &&
        this.state.auction.end !== undefined
      ) {
        const dayEnd = new Date(this.state.auction.end.seconds * 1000);
        const day = dayEnd.getDate();
        const month = dayEnd.getMonth();
        const year = dayEnd.getFullYear();
        var hours = dayEnd.getHours();
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutes = dayEnd.getMinutes();
        return (
          <p className="lot_date">
            Closes {monthNames[month]} {day} {year} at{" "}
            {hours < 10 ? ":0" + hours : hours}
            {minutes < 10 ? ":0" + minutes : ":" + minutes}{" "}
            {dayEnd.getHours() >= 12 ? " P.M." : " A.M."}
          </p>
        );
      }
    }
  }

  createCardLot() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.auction !== null && this.state.auction !== undefined) {
        let lot = [];
        for (var i = 0; i < this.state.totalLots; i++) {
          lot.push(
            <div className="col-6 col-md-4 col-lg-15 lot" key={"lot-" + i}>
              <div className="card card_lot ">
                {/* <div className="wishlist-box">
                  {!this.state.logged && (<i className=" far fa-heart"onClick={() => this.displayForm("login")}/>)}

                  {this.state.logged && !this.existWishList(prod.id) && (
                    <i className=" far fa-heart"onClick={e => {this.gotoWishlistItem(prod.id, 1);}}/>
                  )}

                  {this.state.logged && this.existWishList(prod.id) && (
                    <i className=" fas fa-heart"onClick={e => {this.gotoWishlistItem(prod.id, 0);}}/>
                  )}
                </div> */}
                <Link to={"/lot/" + this.state.data[0].data[i].id}>
                  <img
                    className="lot-img-cover"
                    src={this.state.data[0].data[i].principalImage}
                    alt={this.state.data[0].data[i].title}
                  />
                </Link>
                <div className="card-body card-body-lots">
                  <div className="top-card">
                    <h5 className="card-title center closit_text">
                      {this.state.data[0].data[i].title}
                    </h5>
                    <p className="card-text center ">
                      LOT {this.state.data[0].data[i].number}
                    </p>
                    {this.state.data[0].data[i].estimatedPrice !==
                      undefined && (
                      <p className="card-text center card-text-price">
                        $
                        {formatNumber(
                          this.state.data[0].data[i].estimatedPrice
                        )}
                      </p>
                    )}
                    <p className="card-text center card-text-price">
                      (Bids: {this.state.data[0].data[i].bids_users.length})
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return lot;
      }
    }
  }

  createBannerTimed() {
    if (
      this.state.auction.end !== undefined &&
      this.state.auction.start !== undefined
    ) {
      //auction timed
      var slider;
      if (
        this.state.auction.principalImageWeb !== undefined &&
        this.state.auction.principalImageWeb !== ""
      ) {
        slider = (
          <div className="image-box" key="banners-auctions-timed">
            <div className="overlay" />
            <img
              className="lot_banner lot-web"
              src={this.state.auction.principalImageWeb}
              alt={this.state.auction.title}
            />
            <img
              className="lot_banner lot-mobile"
              src={this.state.auction.principalImage}
              alt={this.state.auction.title}
            />
            <CountDownSan
              date={new Date(
                this.state.auction.start.seconds * 1000
              ).toString()}
              className="CountDown-transparent"
              onEnd={() => {
                this.terminate();
              }}
            />
          </div>
        );
      } else {
        slider = (
          <div className="image-box" key="banners-auctions-timed">
            <div className="overlay" />
            <img
              className="lot_banner"
              src={this.state.auction.principalImage}
              alt={this.state.auction.title}
            />
            <CountDownSan
              date={new Date(
                this.state.auction.start.seconds * 1000
              ).toString()}
              className="CountDown-transparent"
              onEnd={() => {
                this.terminate();
              }}
            />
          </div>
        );
      }

      return slider;
    }
  }

  createBannerLive() {
    if (
      this.state.auction.end !== undefined &&
      this.state.auction.start !== undefined
    ) {
      var slider;
      //auction live
      if (
        this.state.auction.principalImageWeb !== undefined &&
        this.state.auction.principalImageWeb !== ""
      ) {
        slider = (
          <div className="image-box" key="banners-auctions-live">
            <div className="overlay" />
            <img
              className="lot_banner lot-web"
              src={this.state.auction.principalImageWeb}
              alt={this.state.auction.title}
            />
            <img
              className="lot_banner lot-mobile"
              src={this.state.auction.principalImage}
              alt={this.state.auction.title}
            />
            {/*<Countdown
                date={new Date(this.state.auction.end.seconds * 1000).getTime()}
                renderer={this.rendererLive}
              />*/}
            <CountDownSan
              date={new Date(this.state.auction.end.seconds * 1000).toString()}
              className="CountDown-transparent"
            />
          </div>
        );
      } else {
        slider = (
          <div className="image-box" key="banners-auctions-live">
            <div className="overlay" />
            <img
              className="lot_banner"
              src={this.state.auction.principalImage}
              alt={this.state.auction.title}
            />
            {/*<Countdown
                date={new Date(this.state.auction.end.seconds * 1000).getTime()}
                renderer={this.rendererLive}
              />*/}
            <CountDownSan
              date={new Date(this.state.auction.end.seconds * 1000).toString()}
              className="CountDown-transparent"
            />
          </div>
        );
      }
      return slider;
    }
  }

  existWishList(id) {
    let exist = this.state.wishlist_lots.filter((value) => value === id);
    if (exist.length === 1) return true;
    return false;
  }

  async gotoWishlistItem(id, action) {
    if (this.state.logged) {
      let newDataWishlist = this.state.wishlist_lots;
      if (action) {
        newDataWishlist.push(id);
        try {
          dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist_lots: newDataWishlist });
          this._isMounted && this.setState({ wishlist_lots: newDataWishlist });
        } catch (error) {
          console.error("Error ", error);
        }
      } else {
        //remove item
        let data = newDataWishlist.filter((value) => value !== id);
        try {
          dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist_lots: data });
          this._isMounted && this.setState({ wishlist_lots: data });
        } catch (error) {
          console.error("Error ", error);
        }
      }
    } else {
      //go to login
      this._isMounted &&
        this.setState({ formVisible: true, formType: "login" });
    }
  }

  close() {
    this._isMounted && this.setState({ formVisible: false });
  }

  displayForm(value) {
    this._isMounted && this.setState({ formVisible: true, formType: value });
  }

  render() {
    return (
      <div className="AuctionDetailPage">
        {this.state.redirect404 && <Redirect push to={"/404"} />}
        {this.state.redirectAuctions && <Redirect push to={"/auctions"} />}
        <div className="container-fluid no-pd">
          <div
            className="container banner-act"
            style={{ position: "relative" }}
          >
            <p
              style={{
                position: "absolute",
                zIndex: 1,
                color: "#fff",
                textAlign: "center",
                width: "100%",
                height: 30,
                top: "28%",
                left: 0,
              }}
            >
              AUCTION ENDS IN:
            </p>
            <div className="row">
              <div className="col-12 no-pd">
                {this.state.timed && this.createBannerTimed()}
                {!this.state.timed && this.createBannerLive()}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid no-pd-mobile">
          <div className="container banner-act">
            <div className="row">
              <div className="col-12 no-pd">
                <div className="intro-box-auction">
                  <h1 className="auction_detail_title">
                    {this.state.auction.title}
                  </h1>
                  <p className="auction_detail_date">
                    Total Lots: {this.state.totalLots}
                    <Link to="/conditions" className="icon-auction-info">
                      <i className="fas fa-exclamation-circle auction_detail_date" />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <React.Fragment>
          <div className="container-fluid">
            <div className="container">
              <div className="row">
                <div
                  style={{
                    marginTop: 15,
                    paddingVertical: 20,
                    backgroundColor: "#f7f7f7",
                    padding: "70px",
                  }}
                >
                  <span
                    style={{
                      padding: "30px 0",
                      fontSize: "30px",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    {this.state.auction.title}
                  </span>
                  <div
                    className="viewing-room-history"
                    style={{
                      marginTop: "30px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: this.state.auction.history,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="lot_product_container container mt-4">
                {this.state.visibleLoader && (
                  <div className="loader-auction">
                    <Loader type="Oval" color="#000" height="50" width="50" />
                  </div>
                )}
                <div className="row">{this.createCardLot()}</div>
              </div>
            </div>
          </div>
        </div>
        {this.state.formVisible && (
          <LoginForm
            typeForm={this.state.formType}
            close={this.close.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default AuctionDetailPage;
