import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import * as firebase from "firebase/app";
import "firebase/firestore";

class InquireViewingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      lastname: "",
      phone: "",
      lotId: undefined,
      message: "",
      uid: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendInquire = this.sendInquire.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted &&
      this.setState({
        phone: "",
        name: "",
        email: "",
        lastname: "",
        lotId: undefined,
        message: "",
      });
    this._isMounted = false;
  }

  async sendInquire() {
    try {
      const { name, email, lastname, phone } = this.state;
      const doc = await dbFirestore.collection("viewing_rooms_orders").add({
        name,
        email,
        lastname,
        phone,
        lotId: this.props.lot.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await dbFirestore
        .collection("viewing_rooms_orders")
        .doc(doc.id)
        .update({ id: doc.id });
      this.props.showResult("Your request has been sent successfully.");
      this.toggleModal();
    } catch (error) {
      this.props.showResult("An error has occurred please try again.");
      console.log(error);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.sendInquire();
  }

  toggleModal() {
    this._isMounted &&
      this.setState({
        phone: "",
        name: "",
        email: "",
        lastname: "",
        lotId: undefined,
        message: "",
      });
    document.querySelector("#modal-review-close").click();
  }

  componentWillReceiveProps(props) {
    this._isMounted &&
      this.setState({
        phone: "",
        name: "",
        email: "",
        lastname: "",
        lotId: undefined,
        message: "",
      });
  }

  render() {
    return (
      <div>
        {!this.props.logged && (
          <button
            className="btn custom btn-primary btn-sm"
            type="button"
            disabled={this.props.disabled}
            onClick={() => {
              this.props.showLoginModal();
            }}
          >
            {this.props.textBtn}
          </button>
        )}
        {this.props.logged && (
          <button
            className="btn custom btn-primary btn-sm"
            type="button"
            data-toggle="modal"
            data-target="#Inquire"
            disabled={this.props.disabled}
          >
            {this.props.textBtn}
          </button>
        )}
        <div
          className="modal fade"
          id="Inquire"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="InquireTitle"
          aria-hidden="true"
        >
          <div
            className="container modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content star-modal">
              <button
                type="button"
                className="close x"
                data-dismiss="modal"
                aria-label="Close"
                id="modal-review-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-header">
                <div className="col-12 col-lg-12">
                  <p
                    className="modal-title star-title_container mt-3"
                    id="exampleModalCenterTitle"
                  >
                    Inquire Now
                  </p>
                </div>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="name"
                      type="text"
                      placeholder="Name"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ name: e.target.value });
                      }}
                      value={this.state.name}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="lastname"
                      type="text"
                      placeholder="Last Name"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ lastname: e.target.value });
                      }}
                      value={this.state.lastname}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ email: e.target.value });
                      }}
                      value={this.state.email}
                      required
                    />
                    <p className="hidden error-text error-email">
                      Please enter a valid email.
                    </p>
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="phon"
                      type="text"
                      placeholder="Phone"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ phone: e.target.value });
                      }}
                      value={this.state.phone}
                      required
                    />
                    <p className="hidden error-text error-phone">
                      Please enter a phome.
                    </p>
                  </div>

                  <div className="col-12 center mt-4 mb-4">
                    <button
                      className="btn btn-secondary btn-menu btn-size"
                      type="submit"
                    >
                      Inquire
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InquireViewingRoom;
