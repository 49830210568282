import React, { Component } from "react";
import "./achievement.css";
import ProgressRing from "../progress-ring";
import $ from "jquery";

class Achievement extends Component {
  constructor(props){
    super(props);
    this.state={
      unlocked:false,
      active: true,
      shares:0,
      sharesLeft:0 ,
      progress:0,
      codeAchievement: "",
      tooltip: "Copy to clipboard",
    }
    this.openShareModal = this.openShareModal.bind(this);
    this._isMounted = false;
  }
  componentDidMount(){
    window.scrollTo(0, 0);
    this._isMounted =true;
    //Verificar si ya fue usado
    let exist = this.props.dataAchiviment.achievementsUsed.filter(value => value.userID === this.props.userID);
    if( exist.length === 1 && exist[0].used === true ){
      this._isMounted && this.setState({active:false});
    }
    else if(exist.length === 1 && exist[0].used === false){
      this._isMounted && this.setState({unlocked: true, progress: 100, shares: this.props.dataAchiviment.quantity, codeAchievement: exist[0].codeAchievement });
    }
    else{
      let response = this.props.referrals.filter(value => value.id_reward === this.props.dataAchiviment.id);
      var shares;
      if(response.length === 0){
        shares = 0
      }
      else{
        shares = response.length;
      }
      const sharesLeft = this.props.dataAchiviment.quantity - shares;
      const progress = (shares *100)/this.props.dataAchiviment.quantity ;
      this._isMounted && this.setState({shares: shares,sharesLeft: sharesLeft, progress:Math.round(progress) });
    }
  }
  
  openShareModal() {
    this.props.openShareModal(this.props.dataAchiviment.id);
  }
  copyClipboard() {
    var copyText = document.getElementById("unlockedCode");
    copyText.select();
    document.execCommand("copy");
    this._isMounted && this.setState({tooltip: "Copied"},() => {
      $("#copyAchievement").tooltip("hide");
      $("#copyAchievement").tooltip("show");
    });
  }
  render() {
    if(this.state.active){
      $("#copyAchievement").tooltip();
      return (
        <div className="Achievement">
          <div className="container p-0">
            <div className="row align-items-center justify-content-center achievement_container m-2 p-2">
              <div className="col-8 col-sm-8 col-md-9 col-lg-10 ">
                <h4 className="view_all white sept-1 closit_title achievement_pointer achievement_cap" onClick={()=>{this.openShareModal()}}>{this.props.dataAchiviment.title}</h4>
                <p className="achievement_text achievement_pointer" onClick={()=>{this.openShareModal()}}>{this.props.dataAchiviment.description}</p>
                
                {!this.state.unlocked && (
                  <p className="mb-0 achievement_text sept-2">{this.state.shares} shares - {this.state.sharesLeft} more to unlock the achievement </p>
                )}
  
                {this.state.unlocked && (
                <p className="mb-0 achievement_text sept-2">Unlocked - <a id="copyAchievement" data-toggle="tooltip"data-placement="left" data-html="true" data-original-title={this.state.tooltip} onClick={() => {this.copyClipboard();}}>Code: <input id="unlockedCode" defaultValue={this.state.codeAchievement} readOnly/></a>
                </p>)}
              </div>
              <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                <div className="achievement_trophy_container">
                  <ProgressRing radius={50}  stroke={5}  progress={100} class={"bg"}/>
                  <ProgressRing radius={50}  stroke={5}  progress={this.state.progress} class={"achiviment"}/>
                  <p className="achievement_torphy closit_text mb-0 achievement_pointer" onClick={()=>{this.openShareModal()}}>{this.state.progress}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else{
      return (<div/>)
    }
  }
}

export default Achievement;
