//Dependencies
import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import { Link } from "react-router-dom";
import "./journal-card.css";
import Loader from "react-loader-spinner";
import { services } from "../../#constants";

class JournalCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          principalImage: ".",
          title: ".",
          description: "."
        }
      ],
      introWhere: {},
      introBlog: {},
      dataWhere: [],
      dataBlog: [],
      visibleBlog: false,
      visibleLoaderBlog: true,
      visibleLoaderWhere: true
    };
    this.initBlog = this.initBlog.bind(this);
    this.initWhere = this.initWhere.bind(this);
    this.createJournalCardBlog = this.createJournalCardBlog.bind(this);
    this.createJournalCardWhere = this.createJournalCardWhere.bind(this);
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.initBlog();
    this.initWhere();
  }

  async getIntroBlog() {
    // Find intro data Blog
    let docsBlog = await dbFirestore.collection("journal_blog").get();
    if (!docsBlog.empty) {
      docsBlog.forEach(doc => {
        this._isMounted && this.setState({ introBlog: doc.data() });
      });
    }
  }

  async initBlog() {
    this.getIntroBlog();
    //Find data journal blog
    let snapshot = await dbFirestore.collection("journal").where("publish", "==", true).where("typeOf", "==", "blog").orderBy("updatedAt", "desc").get();
    let data = [];
    await services.asyncForEach(snapshot.docs, (doc) => {
      data.push(doc.data());
    })
    this._isMounted && this.setState({ dataBlog: data, visibleLoaderBlog: false });
  }

  async getIntroWhere() {
    // Find intro data where
    let docsWhere = await dbFirestore.collection("journal_where").get();
    if (!docsWhere.empty) {
      docsWhere.forEach(doc => {
        this._isMounted && this.setState({ introWhere: doc.data() });
      });
    }
  }

  async initWhere() {
    this.getIntroWhere();
    // Find data journal where
    let snapshot = await dbFirestore.collection("journal").where("publish", "==", true).where("typeOf", "==", "where").orderBy("updatedAt", "desc").get();
    let data = [];
    services.asyncForEach(snapshot.docs, (doc) => {
      data.push(doc.data());
    })
    this._isMounted && this.setState({ dataWhere: data, visibleLoaderWhere: false });
  }

  createJournalCard = () => {
    if (this.state !== null && this.state !== undefined) {
      let journals = [];
      for (let i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].createdAt !== undefined) {
          var day = new Date(this.state.data[i].createdAt.seconds * 1000);

          journals.push(
            <div
              className="col-12 separation-journal "
              key={"journal-card-" + i}
            >
              <div className="card journal-card">
                <div className="row">
                  <div className="col-12 col-md-7 no-pd-mobile">
                    <Link to={"/journal/" + this.state.data[i].id}>
                      <div className="journal-card-img-box">
                        <div className="overlay" />
                        <img
                          className="journal-card-img"
                          src={this.state.data[i].principalImage}
                          alt={this.state.data[i].title}
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="col-12 col-md-5 no-pd-mobile">
                    <div className="card-body">
                      <p className="card-date">
                        {day.getDate() +
                          "/" +
                          (day.getMonth() + 1) +
                          "/" +
                          day.getFullYear()}
                      </p>
                      <Link to={"/journal/" + this.state.data[i].id}>
                        <h5 className="journal-ind-card-title">
                          {this.state.data[i].title}
                        </h5>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
      return journals;
    }
  };

  createJournalCardBlog = () => {
    if (this.state !== null && this.state !== undefined) {
      let journals = [];
      for (let i = 0; i < this.state.dataBlog.length; i++) {
        if (this.state.dataBlog[i].updatedAt !== undefined) {
          var day = new Date(this.state.dataBlog[i].updatedAt.seconds * 1000);

          journals.push(
            <div
              className="col-12 separation-journal "
              key={"journal-card-" + i}
            >
              <div className="card journal-card">
                <div className="row">
                  <div className="col-12 col-md-7 no-pd-mobile">
                    <Link to={"/journal/" + this.state.dataBlog[i].id}>
                      <div className="journal-card-img-box">
                        <div className="overlay" />
                        <img
                          className="journal-card-img"
                          src={this.state.dataBlog[i].principalImage}
                          alt={this.state.dataBlog[i].title}
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="col-12 col-md-5 no-pd-mobile">
                    <div className="card-body journal-text-sep">
                      <p className="card-date">
                        {day.getDate() +
                          "/" +
                          (day.getMonth() + 1) +
                          "/" +
                          day.getFullYear()}
                      </p>
                      <Link to={"/journal/" + this.state.dataBlog[i].id}>
                        <h5 className="journal-ind-card-title">
                          {this.state.dataBlog[i].title}
                        </h5>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
      return journals;
    }
  };

  createJournalCardWhere = () => {
    if (this.state !== null && this.state !== undefined) {
      let journals = [];
      for (let i = 0; i < this.state.dataWhere.length; i++) {
        if (this.state.dataWhere[i].updatedAt !== undefined) {
          var day = new Date(this.state.dataWhere[i].updatedAt.seconds * 1000);

          journals.push(
            <div
              className="col-12 separation-journal "
              key={"journal-card-" + i}
            >
              <div className="card journal-card">
                <div className="row">
                  <div className="col-12 col-md-7 no-pd-mobile">
                    <Link to={"/journal/" + this.state.dataWhere[i].id}>
                      <div className="journal-card-img-box">
                        <div className="overlay" />
                        <img
                          className="journal-card-img"
                          src={this.state.dataWhere[i].principalImage}
                          alt={this.state.dataWhere[i].title}
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="col-12 col-md-5 no-pd-mobile">
                    <div className="card-body">
                      <p className="card-date">
                        {day.getDate() +
                          "/" +
                          (day.getMonth() + 1) +
                          "/" +
                          day.getFullYear()}
                      </p>
                      <Link to={"/journal/" + this.state.dataWhere[i].id}>
                        <h5 className="journal-ind-card-title">
                          {this.state.dataWhere[i].title}
                        </h5>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
      return journals;
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="container-fluid px-0">
        <ul
          className="nav  align-items-center new_bg row justify-content-center"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item text-center col-6 col-lg-2 align-self-center no-pd">
            <a
              className="nav-link active no_bg upper hover active"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={() => {
                document.getElementById("pills-home-tab").classList.remove("active");
                document.getElementById("pills-profile-tab").classList.add("active");
                this._isMounted && this.setState({ visibleBlog: false });
              }}
            >
              Shows
          </a>
          </li>

          <li className="nav-item text-center col-6 col-lg-2 align-self-center no-pd ">
            <a
              className="nav-link no_bg upper hover"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => {
                document.getElementById("pills-profile-tab").classList.remove("active");
                document.getElementById("pills-home-tab").classList.add("active");
                this._isMounted && this.setState({ visibleBlog: true });
              }}
            >
              Art World
            </a>
          </li> </ul>
        <div className="tab-content new_bg" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="col-lg-12 col-12 no-pd text-center">
              <img
                className="no-pd journal_new_img"
                src={this.state.introBlog.principalImage}
                alt={this.state.introBlog.title}
              />
            </div>

            <div className="container px-0 py-4">
              <div className="row">
                <div className="col-12">
                  <h1 className="journal_new_title closit_title m-0 mb-3">{this.state.introBlog.title}</h1>
                  <p className="journal_new_text">{this.state.introBlog.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="col-lg-12 col-12 no-pd text-center">
              <img
                className="no-pd journal_new_img"
                src={this.state.introWhere.principalImage}
                alt={this.state.introWhere.title}
              />
            </div>

            <div className="container px-0 py-4">
              <div className="row">
                <div className="col-12">
                  <h1 className="journal_new_title closit_title m-0 mb-3">{this.state.introWhere.title}</h1>
                  <p className="journal_new_text px-2">{this.state.introWhere.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-4">
          <div className="row">
            <div className="col-12 mb-3">
              <h1 className="latest_title closit_title">Latest Journal</h1>
              <hr className="category-hr" />
            </div>
          </div>
        </div>

        {this.state.visibleBlog && (
          <div className="container container-journal no-pd-mobile journal-blogs">
            <div className="row">
              {this.state.visibleLoaderBlog && (
                <div className="loader-journal">
                  <Loader type="Oval" color="#000" height="50" width="50" />
                </div>
              )}
              {this.createJournalCardBlog()}
            </div>
          </div>
        )}
        {!this.state.visibleBlog && (
          <div className="container container-journal no-pd-mobile journal-wheres">
            <div className="row">
              {this.createJournalCardWhere()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default JournalCard;
