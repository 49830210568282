import React, { Component } from "react";
import "./create-account-notification.css";
import Loader from "react-loader-spinner";

class CreateAccountNotification extends Component {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <p
            className="notify-title mb-0 mr-2"
            style={{
              color: "#fff",
            }}
          >
            Creating your account
          </p>
          <Loader type="Oval" color="#000" height="25" width="25" />
        </div>
      </div>
    );
  }
}

export default CreateAccountNotification;
