//Dependencies
import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import { Link, Redirect } from "react-router-dom";
import "./search-detail.css";
class SearchDetailsPage extends Component {

    constructor(props) {
        super(props);

        

        this.state = {
            data: [],
            redirect:false
        }

    }

    async componentDidMount() {
        if(this.props.location.state.data !== null && this.props.location.state.data !== undefined){
            const products = this.props.location.state.data.products;
            var newProducts = [];
            for(var i =0; i< products.cant; i++){
                let product = await dbFirestore.collection("products")
                .doc(products.content[i].id)
                .get();
                newProducts.push(product.data());
            }
            this.setState({
                data: newProducts
            });
        }
        else{
            this.setState({redirect:true});
        }       
    }

    componentWillUnmount() {
    }
    createProdsGridInitial(){
        if(this.state !== null && this.state !== undefined){
            let prods= [];
            for(var i=0; i < this.state.data.length; i++){
                prods.push(
                    <div
                        className="col-6 col-md-15"
                        key={this.state.data[i].title + "-" + i}
                    >
                        <div className="card card-search-detail">
                            <Link
                                to={"/product/" + this.state.data[i].id}
                            >
                                <img
                                className="card-search-detail-img"
                                src={this.state.data[i].principalImage}
                                alt={this.state.data[i].title}
                                />
                            </Link>
                            <div className="card-body card-search-detail-body">
                                <Link
                                to={"/product/" + this.state.data[i].id}
                                >
                                <h4 className="card-title card-search-detail-title">
                                    {this.state.data[i].title}
                                </h4>
                                </Link>
                                <p className="card-text card-search-detail-text">
                                {this.state.data[i].shortDescription}
                                </p>
                                <p className="card-text card-search-detail-price">
                                ${this.state.data[i].price}
                                </p>
                            </div>
                        </div>
                    </div>
                      
                );
            }
            return prods;
        }
    }
    render() {
        return (
        <div className="SearchDetails">
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        {this.createProdsGridInitial()}
                    </div>
                </div>     
            </div>
            {this.state.redirect && ( <Redirect push to={"/"}/>)}
        </div>
        );
    }
}

export default SearchDetailsPage;
