import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./product-detail.css";
import { dbFirestore, auth } from "../../@firebase";
import SliderNav from "../../@components/slider-nav";
import LoginForm from "../../@components/login";
import Star from "../../@components/star";
import Similar from "../../@components/similar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SizeGuidePage from "../size-guide";
import $ from "jquery";
import CarouselProduct from "../../@components/carousel-product";
import ShareModal from "../../@components/share-modal";
import Inquire from "../../@components/inquire";
import { metasTagsProds, formatNumber } from "../../#constants";
class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          title: "",
          description: "<p></p>",
          sizeAndFit: "<p></p>",
          compositionAndCare: "<p></p>",
          shippingAndReturns: "<p></p>",
          aboutDesigner: "<p></p>",
          shortDescription: "",
          price: 0,
        },
      ],
      firstPriceSelect: 0,
      priceSelected: 0,
      formVisible: false,
      logged: false,
      user: {},
      infoUser: {},
      photos: [],
      sizes: [{ label: "Size", value: "any" }],
      sizeSelected: "",
      availableQuantity: 1,
      iconButtonWithlistState: "ios-heart-outline",
      redirect: false,
      redirect404: false,
      shareLink: "",
      title_alert: "",
      message_alert: "",
      sale: false,
      discountSale: 0,
      designerInfo: "",
    };
    this.handleChangeSize = this.handleChangeSize.bind(this);
    this.gotoShoppingCart = this.gotoShoppingCart.bind(this);
    this.addToWish = this.addToWish.bind(this);
    this.removeToWish = this.removeToWish.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.showResult = this.showResult.bind(this);
    this.initProd = this.initProd.bind(this);
    this.setvisist = this.setvisist.bind(this);
    this.showDiscount = this.showDiscount.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this._isMounted && this.setState({ shareLink: window.location.href });
    this.initProd();
    this.setvisist();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted && this.setState({ user: userLogged, logged: true });
        let snapshot = await dbFirestore
          .collection("users")
          .doc(userLogged.uid)
          .get();

        let infoUser = snapshot.data();
        let exist = infoUser.wishlist.filter(
          (value) => value === this.props.match.params.id
        );
        if (exist.length === 1) {
          this._isMounted &&
            this.setState({
              iconButtonWithlistState: "ios-heart",
              infoUser: snapshot.data(),
            });
        } else {
          this._isMounted &&
            this.setState({
              iconButtonWithlistState: "ios-heart-outline",
              infoUser: snapshot.data(),
            });
        }
      } else {
        this._isMounted &&
          this.setState({
            user: {},
            logged: false,
            infoUser: {},
            iconButtonWithlistState: "ios-heart-outline",
          });
      }
    });
  }

  async setvisist() {
    const prodId = this.props.match.params.id;
    try {
      await dbFirestore.runTransaction(async (transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        const doc = dbFirestore.collection("products_visits").doc(prodId);
        let snap = await transaction.get(doc);

        if (!snap.exists) {
          throw Error("Document does no exist");
        }
        var visits = snap.data().visits;
        var allVisit = snap.data().countTotal;
        const today =
          "" +
          (new Date().getMonth() + 1) +
          "/" +
          new Date().getDate() +
          "/" +
          new Date().getFullYear();
        if (visits[today] !== undefined) {
          visits[today] = visits[today] + 1;
          allVisit = allVisit + 1;
          transaction.update(doc, { visits: visits, countTotal: allVisit });
        } else {
          visits[today] = 1;
          allVisit = allVisit + 1;
          transaction.update(doc, { visits: visits, countTotal: allVisit });
        }
      });
    } catch (error) {
      //console.log("error transaction failed", error);
    }
  }

  async initProd() {
    const prodId = this.props.match.params.id;
    let snapshot = await dbFirestore.collection("products").doc(prodId).get();

    if (snapshot.exists && snapshot.data().publish) {
      let data = snapshot.data();
      //meta datos
      metasTagsProds.setMetaTags(data);
      //end meta datos
      let photos = [];
      photos.push({ principalImage: data.principalImage });

      let tam = data.secondaryImages.length;

      for (let i = 0; i < tam; i++) {
        photos.push({ principalImage: data.secondaryImages[i] });
      }

      let sizes = data.sizes;
      let items = [];
      let quantityCorrect;
      sizes.forEach((val) => {
        if (
          parseInt(val.quantityAllowToBuy, 10) <=
          parseInt(val.availableQuantity, 10)
        ) {
          quantityCorrect = val.quantityAllowToBuy;
        } else {
          quantityCorrect = val.availableQuantity;
        }

        items.push({
          label: val.label + " - Price: " + val.price,
          value: val.label,
          quantity: quantityCorrect,
          price: val.price,
        });
      });

      this._isMounted &&
        this.setState({
          data: data,
          sizes: items,
          photos: photos,
          priceSelected: sizes[0].price,
          firstPriceSelect: sizes[0].price,
        });

      //Peticion para obtener la nueva info del artista

      let designerSnapshot = await dbFirestore
        .collection("categorys")
        .doc(this.state.data.linkTo.designer)
        .get();

      this.setState({ designerInfo: designerSnapshot.data().about_artist });

      if (data.sale) {
        this._isMounted &&
          this.setState({
            sale: data.sale,
            discountSale: data.discountSale,
          });
      }
    } else {
      this._isMounted && this.setState({ redirect404: true });
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps !== null && nextProps !== undefined) {
      const prodId = nextProps.match.params.id;
      let snapshot = await dbFirestore.collection("products").doc(prodId).get();

      if (snapshot.exists && snapshot.data().publish) {
        let data = snapshot.data();
        //meta datos
        metasTagsProds.setMetaTags(data);
        //end meta datos
        let photos = [];
        photos.push({ principalImage: data.principalImage });

        let tam = data.secondaryImages.length;

        for (let i = 0; i < tam; i++) {
          photos.push({ principalImage: data.secondaryImages[i] });
        }
        let sizes = data.sizes;
        let items = [];
        let quantityCorrect;
        sizes.forEach((val) => {
          if (
            parseInt(val.quantityAllowToBuy, 10) <=
            parseInt(val.availableQuantity, 10)
          ) {
            quantityCorrect = val.quantityAllowToBuy;
          } else {
            quantityCorrect = val.availableQuantity;
          }

          items.push({
            label: val.label + " - Qty: " + quantityCorrect,
            value: val.label,
            quantity: quantityCorrect,
          });
        });
        this._isMounted &&
          this.setState(
            {
              data: data,
              sizes: items,
              photos: photos,
              priceSelected: sizes[0].price,
              firstPriceSelect: sizes[0].price,
            },
            () => {
              if ($("#description-tab").length > 0)
                $("#description-tab").click();
            }
          );
        if (data.sale) {
          this._isMounted &&
            this.setState({ sale: data.sale, discountSale: data.discountSale });
        }
        window.scrollTo(0, 0);
      } else {
        this._isMounted && this.setState({ redirect404: true });
      }
    }
  }

  createSelectSizeProduct() {
    if (this.state.sizes.length > 0) {
      let options = [];
      for (var i = 0; i < this.state.sizes.length; i++) {
        options.push(
          <option value={this.state.sizes[i].value} key={"op-prod-" + i}>
            {this.state.sizes[i].value}
          </option>
        );
      }
      return options;
    }
  }

  async gotoShoppingCart() {
    if (this.state.logged) {
      if (this.state.sizeSelected === "") {
        //notify size has been selected
        document.getElementById("product-size").classList.add("error");
        document.querySelector(".product-error-text").classList.add("visible");
      } else {
        let newDataCart = this.state.infoUser.cart;
        let exist = newDataCart.filter(
          (value) => value.id === this.state.data.id
        );
        if (exist.length === 1) {
          //Go to cart view
          this._isMounted && this.setState({ redirect: true });
        } else {
          newDataCart.push({
            id: this.state.data.id,
            quantity: 1,
            size: this.state.sizeSelected,
            shipping: 0.0,
            shippingID: "",
            object_id: "",
          });
          try {
            await dbFirestore
              .collection("users")
              .doc(this.state.user.uid)
              .update({ cart: newDataCart });
            this._isMounted && this.setState({ redirect: true });
          } catch (error) {
            console.error("Error ", error);
          }
        }
      }
    } else {
      //Show login
      this._isMounted && this.setState({ formVisible: true });
    }
  }

  async addToWish() {
    if (this.state.logged) {
      let newDataWishlist = this.state.infoUser.wishlist;
      if (newDataWishlist.indexOf(this.state.data.id) < 0) {
        newDataWishlist.push(this.state.data.id);
        try {
          await dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist: newDataWishlist });
          this._isMounted &&
            this.setState(
              {
                iconButtonWithlistState: "ios-heart",
                message_alert: "Product successfully added to the wishlist",
              },
              () => {
                this.showNotification();
              }
            );
        } catch (e) {
          console.error("Error ", e);
        }
      } else {
        //do nothing the element exist yet.
      }
    } else {
      //Show login
      this.displayForm();
    }
  }

  async removeToWish() {
    if (this.state.logged) {
      let newDataWishlist = this.state.infoUser.wishlist;
      let data = newDataWishlist.filter(
        (value) => value !== this.state.data.id
      );
      var info = this.state.infoUser;
      info.wishlist = data;
      try {
        await dbFirestore
          .collection("users")
          .doc(this.state.user.uid)
          .update({ wishlist: data });
        this._isMounted &&
          this.setState(
            {
              iconButtonWithlistState: "ios-heart-outline",
              infoUser: info,
              message_alert: "Product successfully removed from the wishlist",
            },
            () => {
              this.showNotification();
            }
          );
      } catch (error) {
        console.error("Error ", error);
      }
    } else {
      //Show login
      this.displayForm();
    }
  }

  handleChangeSize(e) {
    document.getElementById("product-size").classList.remove("error");
    document.querySelector(".product-error-text").classList.remove("visible");

    if (e.target.value === null) {
      this._isMounted &&
        this.setState({
          sizeSelected: "",
          availableQuantity: 1,
          priceSelected: this.state.firstPriceSelect,
        });
    } else {
      let size = this.state.sizes;
      let selectSize = size.filter((val) => val.value === e.target.value);
      let quantity = parseInt(selectSize[0].quantity, 10);
      this._isMounted &&
        this.setState({
          sizeSelected: e.target.value,
          availableQuantity: quantity,
          priceSelected: selectSize[0].price,
        });
    }
  }

  displayForm() {
    this._isMounted && this.setState({ formVisible: true, formType: "login" });
  }

  close() {
    this._isMounted && this.setState({ formVisible: false });
  }

  showNotification() {
    toast.info(
      <div>
        <p className="notify-text">{this.state.message_alert}</p>
      </div>,
      {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "notify",
        bodyClassName: "notify-body",
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showDiscount() {
    if (this.state.sale) {
      let label = [];
      let discount = (this.state.priceSelected * this.state.discountSale) / 100;
      let newPrice = this.state.priceSelected - discount;
      newPrice = newPrice.toFixed(2);
      newPrice = parseFloat(newPrice);
      label.push(
        <div key="price-discount">
          <p className="product_price">
            <span className="marked-price">
              ${formatNumber(this.state.priceSelected)}
            </span>
            <span className="new-price">${formatNumber(newPrice)}</span>
            <span className="discount-save">
              {" "}
              Save {this.state.discountSale}%
            </span>
          </p>
        </div>
      );

      return label;
    }
  }
  showResult(message) {
    this._isMounted &&
      this.setState({ message_alert: message }, () => {
        this.showNotification();
      });
  }
  render() {
    if (this.state.data.idBrand !== undefined) {
      return (
        <div className="ProductDetail">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 no-pd-mobile">
                <CarouselProduct
                  photos={this.state.photos}
                  data={this.state.data}
                />
              </div>
              <div className="col center">
                <h1 className="product_title closit_text">
                  {this.state.data.title}
                </h1>
                <p className="product_description">
                  {this.state.data.shortDescription}
                </p>

                {/* {!this.state.data.sold_online && (
                  
                )} */}

                {this.state.data.show_price && (
                  <p className="product_price">
                    ${formatNumber(this.state.priceSelected)}
                  </p>
                )}

                {!this.state.sale && this.state.data.sold_online && (
                  <p className="product_price">
                    ${formatNumber(this.state.priceSelected)}
                  </p>
                )}

                {this.state.sale &&
                  this.state.data.sold_online &&
                  this.showDiscount()}

                {this.state.data.sold_online && (
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="product-size"
                      onChange={this.handleChangeSize}
                      value={this.state.sizeSelected}
                    >
                      <option value="" disabled>
                        {" "}
                        Select size{" "}
                      </option>
                      {this.createSelectSizeProduct()}
                    </select>
                    <p className="product-error-text">You must select a size</p>
                    <p className="size-guide-text my-3">
                      <a data-toggle="modal" data-target="#sizeModal">
                        Size Guide
                      </a>
                    </p>
                  </div>
                )}

                <div className="row">
                  <div className="col-4 special-pd-mobile">
                    {/*<button
                      className="btn custom btn-primary btn-sm"
                      onClick={this.gotoShoppingCart}
                      disabled={this.state.availableQuantity > 0 ? false : true}
                    >
                      {this.state.availableQuantity > 0
                        ? "Add to bag"
                        : "Sold Out"}
                      </button>*/}
                    <Inquire
                      textBtn={
                        this.state.availableQuantity > 0
                          ? "Inquire"
                          : "Sold Out"
                      }
                      disabled={this.state.availableQuantity > 0 ? false : true}
                      logged={this.state.logged}
                      showLoginModal={this.displayForm.bind(this)}
                      product={this.state.data}
                      showResult={this.showResult.bind(this)}
                    />
                  </div>
                  <div className="col-4 special-pd-mobile">
                    {this.state.iconButtonWithlistState === "ios-heart" && (
                      <button
                        className="btn custom btn-primary btn-sm btn-add-wish btn-add-wish-active"
                        onClick={this.removeToWish}
                      >
                        Wishlist
                        <i className=" fas fa-heart" />
                      </button>
                    )}
                    {this.state.iconButtonWithlistState ===
                      "ios-heart-outline" && (
                      <button
                        className="btn custom btn-primary btn-sm btn-add-wish btn-add-wish-inactive"
                        onClick={this.addToWish}
                      >
                        Wishlist
                        <i className=" far fa-heart" />
                      </button>
                    )}
                  </div>
                  <div className="col-4 special-pd-mobile">
                    <a
                      className="btn custom btn-primary btn-sm btn-add-wish"
                      data-toggle="modal"
                      data-target="#shareModal"
                    >
                      Share
                      <i className="fas fa-share-square" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <SliderNav
              prodInfo={this.state.data}
              designerInfo={this.state.designerInfo}
            />
          </div>
          <Star dataItem={this.state.data} id={this.props.match.params.id} />
          <Similar id={this.props.match.params.id} />

          {/*MODAL SHARE LINK */}
          <ShareModal shareLink={this.state.shareLink} />

          {/*MODAL SIZE GUIDE*/}
          <div
            className="modal fade"
            id="sizeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="sizeModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content lot_modal">
                <div className="p-3 text-center">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="lot_modal_x" aria-hidden="true">
                      &times;
                    </span>
                  </button>
                  <h5 id="sizeModalLabel" className="title-size">
                    Size Guide
                  </h5>
                </div>
                <div className="modal-body">
                  <SizeGuidePage />
                </div>
              </div>
            </div>
          </div>
          {/*END MODAL SIZE GUIDE*/}
          {this.state.formVisible && (
            <LoginForm typeForm="login" close={this.close.bind(this)} />
          )}
          {this.state.redirect && <Redirect push to={"/cart"} />}
        </div>
      );
    } else {
      return (
        <div>{this.state.redirect404 && <Redirect push to={"/404"} />}</div>
      );
    }
  }
}

export default ProductDetail;
