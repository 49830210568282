//Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Causes from "../../@components/causes";
import { dbFirestore, auth } from "../../@firebase";
import Timestamp from "react-timestamp";
import { monthNames, metasTags } from '../../#constants';
import CountDownSan from "../../@components/countdown";
import "./viewing-room.css";

class ViewingRoomPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLive: {},
      dataTimed: {},
      visible: false,
      existLive: false,
      existTimed: false,
      logged: false
    };
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    auth.onAuthStateChanged(user => {
      var logged = false;
      if (user) logged = true;
      this._isMounted && this.setState({ logged: logged });
    });

    this.init();
  }

  terminate(auctionTimed) {
    let newDataTimed = this.state.dataTimed;
    newDataTimed.data = this.state.dataTimed.data.filter(val => val.id !== auctionTimed.id);

    let newDataLive = this.state.dataLive;
    newDataLive.data.push(auctionTimed);

    let existTimed = true;
    if (newDataTimed.data.length === 0) existTimed = false;

    this._isMounted && this.setState({ dataTimed: newDataTimed, dataLive: newDataLive, existTimed: existTimed });
  }

  async init() {
    let snapshot = await dbFirestore.collection("viewing_rooms").where("publish", "==", true).where("completed", "==", false).orderBy("updatedAt", "desc").get();

    var jsonLive = { data: [], key: "Live" }, jsonTimed = { data: [], key: "Timed" };
    let seconds, document;

    snapshot.forEach(doc => {
      seconds = Math.round(new Date().getTime() / 1000);

      if (doc.data().start.seconds < seconds) {
        document = doc.data();
        document.countdown = doc.data().end.seconds;
        jsonLive.data.push(document);
      }
      else {
        document = doc.data();
        document.countdown = doc.data().start.seconds;
        jsonTimed.data.push(document);
      }

    });

    if (jsonLive.data.length > 0) this._isMounted && this.setState({ existLive: true });
    else this._isMounted && this.setState({ existLive: false });
    if (jsonTimed.data.length > 0) this.setState({ existTimed: true });
    else this._isMounted && this.setState({ existTimed: false });

    this._isMounted && this.setState({ dataLive: jsonLive, dataTimed: jsonTimed });

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createAuctionLive() {

    if (Object.keys(this.state.dataLive).length !== 0) {
      if (this.state.dataLive.data.length > 0) {
        let auctionLive = [];
        for (var i = 0; i < this.state.dataLive.data.length; i++) {

          const auctionLiveData = this.state.dataLive.data[i];
          const dayEnd = new Date(auctionLiveData.end.seconds * 1000);
          const day = dayEnd.getDate(), month = dayEnd.getMonth(), year = dayEnd.getFullYear();
          var hours = dayEnd.getHours();
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          const minutes = dayEnd.getMinutes();
          if (i % 2 === 0) {
            auctionLive.push(
              <div className="col-12" key={"sep-live" + i}>
                <hr className="auction_separator mt-0" />
              </div>
            );
          }
          auctionLive.push(
            <div className="col-6" key={auctionLiveData.title}>
              <div className="auction_bg_container">
                <Link to={"/viewing-rooms/" + auctionLiveData.id}>
                  <div className="overlay" />
                  <p className="inside_header_auction d-sm-block d-md-none ml-2">{auctionLiveData.title}</p>
                  <img className="auction_img" src={auctionLiveData.principalImage} alt={auctionLiveData.title} />
                  <p className="inside_footer_auction d-sm-block d-md-none ml-2">
                    <Timestamp time={auctionLiveData.end.seconds} precision={2} format="ago" />
                  </p>
                </Link>
                <Link to={"/viewing-rooms/" + auctionLiveData.id}>
                  <CountDownSan date={(new Date(auctionLiveData.end.seconds * 1000)).toString()} className="CountDown-transparent" />
                </Link>
              </div>
              <div className="auctioner_subcontainer d-none d-sm-none d-md-block d-lg-block">
                <div className="row">
                  <div className="col">
                    <h1 className="auctioner_name">{auctionLiveData.title}</h1>
                    <p className="auctioner_close">
                      Closes {monthNames[month]} {day} {year} at{" "}
                      {hours < 10 ? ":0" + hours : hours}
                      {minutes < 10 ? ":0" + minutes : ":" + minutes}{" "}
                      {dayEnd.getHours() >= 12 ? " P.M." : " A.M."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return auctionLive;
      }
    }
  }

  createAuctionTimed() {
    if (Object.keys(this.state.dataTimed).length !== 0) {
      if (this.state.dataTimed.data.length > 0) {
        let auctionLive = [];
        for (var i = 0; i < this.state.dataTimed.data.length; i++) {
          const auctionTimedData = this.state.dataTimed.data[i];
          const dayEnd = new Date(auctionTimedData.end.seconds * 1000);
          const day = dayEnd.getDate();
          const month = dayEnd.getMonth();
          const year = dayEnd.getFullYear();
          var hours = dayEnd.getHours();
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          const minutes = dayEnd.getMinutes();
          if (i % 2 === 0) {
            auctionLive.push(
              <div className="col-12" key={"sep-timed" + i}>
                <hr className="auction_separator" />
              </div>
            );
          }
          auctionLive.push(
            <div className="col-6" key={auctionTimedData.title}>
              <div className="auction_bg_container">
                <Link to={"/viewing-rooms/" + auctionTimedData.id}>
                  <div className="overlay" />
                  <img className="auction_img" src={auctionTimedData.principalImage} alt={auctionTimedData.title} />
                </Link>
                <CountDownSan date={(new Date(auctionTimedData.start.seconds * 1000)).toString()} className="CountDown-transparent" onEnd={() => { this.terminate(auctionTimedData) }} />
              </div>
              <div className="auctioner_subcontainer">
                <div className="row">
                  <div className="col">
                    <h1 className="auctioner_name">{auctionTimedData.title}</h1>
                    <p className="auctioner_close">
                      Closes {monthNames[month]} {day} {year} at{" "}
                      {hours < 10 ? ":0" + hours : hours}
                      {minutes < 10 ? ":0" + minutes : ":" + minutes}{" "}
                      {dayEnd.getHours() >= 12 ? " P.M." : " A.M."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return auctionLive;
      }
    }
  }

  render() {
    if (this.state.existLive === false && this.state.existTimed === false) {
      return (
        <div className="Auctions">
          <Causes />
          <div className="no-auctions">
            <p>We don't have Viewing Rooms at this moment,<br />please come back later.</p>
            <p><i className="far fa-clock" /></p>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="Auctions">
          <Causes />
          <div className="aution_bot_margin row pl-3 pr-3 mt-3">
            {this.state.existLive && (
              <div className="col-12">
                <h1 className="auctions_header closit_title m-0">
                  Current Live Viewing Rooms
                </h1>
                <div className="row">{this.createAuctionLive()}</div>
              </div>
            )}
            {this.state.existTimed && (
              <div className="col-12">
                <h1 className="auctions_header closit_title">
                  Upcoming Viewing Rooms
                </h1>
                <div className="row">{this.createAuctionTimed()}</div>
              </div>
            )}
          </div>
        </div>
      );
    }

  }
}

export default ViewingRoomPage;
