import React, { Component } from "react";
import { storage } from "../../@firebase";
import * as firebase from "firebase/app";
import "firebase/firestore";
import loadImage from "blueimp-load-image";
const uuidv4 = require("uuid/v4");
require("firebase/functions");

class InquireSealedOfferSell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      lastName: "",
      lotId: undefined,
      uid: "",
      office_phone: "",
      cell_phone: "",
      home_phone: "",
      bid_amount: "",
      image: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendInquire = this.sendInquire.bind(this);
    this.handleNewImage = this.handleNewImage.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted &&
      this.setState({
        name: "",
        email: "",
        lastName: "",
        lotId: undefined,
        office_phone: "",
        cell_phone: "",
        home_phone: "",
        bid_amount: ""
      });
    this._isMounted = false;
  }

  sendInquire = async () => {
    try {
      const { name, email, lastName, cell_phone, home_phone, office_phone, bid_amount, image } = this.state;
      const photo = await this.uploadImage(image, "-offer.jpg");
      const data_offer = {
        firstName: name,
        lastName,
        email,
        cell_phone, 
        home_phone, 
        office_phone,
        bid_amount,
        photo,
        termsAndConditions: true,
        lotId: this.props.lot.id,
      }

      const sendInquirePromise = firebase.functions().httpsCallable("sendInquire");

      await sendInquirePromise({ data_offer, product_data: this.props.lot });
      
      this.props.showResult("Your request has been sent successfully.");
      this.toggleModal();
    } catch (error) {
      this.props.showResult("An error has occurred please try again.");
      console.log(error);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.sendInquire();
  }

  toggleModal() {
    this._isMounted &&
      this.setState({
        name: "",
        email: "",
        lastName: "",
        office_phone: "",
        cell_phone: "",
        home_phone: "",
        bid_amount: "",
        lotId: undefined,
      });
    document.querySelector("#modal-review-close").click();
  }

  componentWillReceiveProps(props) {
    this._isMounted &&
      this.setState({
        name: "",
        email: "",
        lastName: "",
        office_phone: "",
        cell_phone: "",
        home_phone: "",
        bid_amount: "",
        lotId: undefined,
      });
  }

  handleNewImage(e) {
    const loadImageOptions = { canvas: true };
    const file = e.target.files[0];
    loadImage.parseMetaData(file, data => {
      if (data.exif && data.exif.get("Orientation")) {
        loadImageOptions.orientation = data.exif.get("Orientation");
      }
      loadImage(
        file,
        canvas => {
          this.setState({
            image: canvas.toDataURL(file.type),
            newImage: true
          });
        },
        loadImageOptions
      );
    });
  }

  uploadImage(file, type) {
    const name = `${uuidv4()}${type}`;
    return storage
      .ref("images/sealed-offer")
      .child(name)
      .putString(file, "data_url")
      .then(snapshot => snapshot.ref.getDownloadURL())
      .catch(error => {
        throw new Error(error);
      });
  }

  render() {
    return (
      <div>
        {!this.props.logged && (
          <button
            className="btn custom btn-primary btn-sm"
            type="button"
            disabled={this.props.disabled}
            onClick={() => {
              this.props.showLoginModal();
            }}
          >
            {this.props.textBtn}
          </button>
        )}
        {this.props.logged && (
          <button
            className="btn custom btn-primary btn-sm"
            type="button"
            data-toggle="modal"
            data-target="#Inquire"
            disabled={this.props.disabled}
          >
            {this.props.textBtn}
          </button>
        )}
        <div
          className="modal fade"
          id="Inquire"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="InquireTitle"
          aria-hidden="true"
        >
          <div
            className="container modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content star-modal">
              <button
                type="button"
                className="close x"
                data-dismiss="modal"
                aria-label="Close"
                id="modal-review-close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-header">
                <div className="col-12 col-lg-12">
                  <p
                    className="modal-title star-title_container mt-3"
                    id="exampleModalCenterTitle"
                  >
                    REGISTRATION FORM | SEALED OFFER ON A MASTERPIECE
                  </p>
                </div>
              </div>
              <div className="modal-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="name"
                      type="text"
                      placeholder="Name"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ name: e.target.value });
                      }}
                      value={this.state.name}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ lastName: e.target.value });
                      }}
                      value={this.state.lastName}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ email: e.target.value });
                      }}
                      value={this.state.email}
                      required
                    />
                    <p className="hidden error-text error-email">
                      Please enter a valid email.
                    </p>
                  </div>

                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="phoneHome"
                      type="text"
                      placeholder="Home Phone"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ home_phone: e.target.value });
                      }}
                      value={this.state.home_phone}
                      required
                    />
                    <p className="hidden error-text error-phone">
                      Please enter a home phone.
                    </p>
                  </div>

                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="phoneCell"
                      type="text"
                      placeholder="Cell Phone"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ cell_phone: e.target.value });
                      }}
                      value={this.state.cell_phone}
                      required
                    />
                    <p className="hidden error-text error-phone">
                      Please enter a cell phone.
                    </p>
                  </div>

                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="phoneOffice"
                      type="text"
                      placeholder="Office Phone"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ office_phone: e.target.value });
                      }}
                      value={this.state.office_phone}
                      required
                    />
                    <p className="hidden error-text error-phone">
                      Please enter a office phone.
                    </p>
                  </div>

                  <div className="col-12">
                    <input
                      className="form-control mb-3 modal-input"
                      name="bidAmount"
                      type="number"
                      placeholder="Bid Amount"
                      onChange={(e) => {
                        this._isMounted &&
                          this.setState({ bid_amount: e.target.value });
                      }}
                      value={this.state.bid_amount}
                      required
                    />
                    <p className="hidden error-text error-phone">
                      Please enter a bid amount.
                    </p>
                  </div>

                  <div className="col-12">
                    <p className="">
                      Please provide a Driver’s License or Government I.D:
                    </p>
                    <input
                      className="form-control mb-3 modal-input"
                      name="photo"
                      type="file"
                      accept="image/x-png,image/jpeg"
                      placeholder="photo"
                      onChange={this.handleNewImage}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <p className="">
                      <b>TERMS & CONDITIONS</b>
                    </p>
                    <p className="">
                      1. Prior to sale, all Collectors must register with Gary
                      Nader. I understand that all offers are subject to the
                      <a
                        style={{
                          fontWeight: "bold",
                          textDecorationStyle: "solid",
                        }}
                        rel="noopener noreferrer"
                        href="https://garynader.com/sealed-offer-conditions"
                        target="_blank"
                      >
                        {" "}
                        "Conditions of Sale.”
                      </a>
                    </p>
                    <p className="">
                      2. I understand that if my offer is successful, I will be
                      obligated to pay the purchase price, plus any applicable
                      state or local tax (or VAT) unless exempted through
                      possession of a valid State Sales Tax Certificate number
                      on file.
                    </p>
                  </div>

                  <div className="col-12">
                    <label>
                      <input
                        name="isGoing"
                        type="checkbox"
                        checked={this.state.isGoing}
                        onChange={this.handleInputChange}
                        required
                      />
                      &nbsp; By check this box, indicates full understanding of
                      and compliance with the above.
                    </label>
                    <br />
                    <p className="hidden error-text error-phone">
                      Please you need to check this before submit.
                    </p>
                  </div>

                  <div className="col-12 center mt-4 mb-4">
                    <button
                      className="btn btn-secondary btn-menu btn-size"
                      type="submit"
                    >
                      Send my registration
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InquireSealedOfferSell;
