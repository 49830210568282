//Dependencies
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { dbFirestore, auth } from "../../@firebase";
import "./wishlist.css";
import Loader from "react-loader-spinner";
import { services, metasTags, formatNumber, calculateNewPrice } from "../../#constants";
class WishlistPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      logged: false,
      user: {},
      infoUser: {},
      wishlist: [],
      existWishList: false,
      sizeSelected: [],
      redirect: false,
      visibleLoader: true,
      wishlistLots: [],
      dataLots: [],
      existWishListLots: false
    };

    this.removeToWish = this.removeToWish.bind(this);
    this.removeToWishLots = this.removeToWishLots.bind(this);
    this.init = this.init.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.init();
    metasTags.setMetaTags();
  }

  async init() {
    auth.onAuthStateChanged(async user => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted && this.setState({ user: userLogged, logged: true });
        let snapshot = await dbFirestore.collection("users").doc(userLogged.uid).get();
        const wishlist = snapshot.data().wishlist;
        const wishlistLots = snapshot.data().wishlist_lots;
        this._isMounted &&
          this.setState({
            wishlist: wishlist,
            infoUser: snapshot.data(),
            wishlistLots: wishlistLots
          });
        await this.getWishlist(wishlist);
        this.getWishlistLots(wishlistLots);
      } else {
        this._isMounted &&
          this.setState({
            user: {},
            logged: false,
            existWishList: false,
            data: [],
            visibleLoader: false,
            existWishListLots: false
          });
      }
    });
  }

  async getWishlist(wishlist) {
    //WISHLIST
    let data = [];
    if (wishlist.length === 0) {
      this._isMounted &&
        this.setState({
          data: data,
          existWishList: false,
          visibleLoader: false
        });
    } else {
      await services.asyncForEach(wishlist, async value => {
        let doc = await dbFirestore
          .collection("products")
          .doc(value)
          .get();
        if (doc.exists) data.push(doc.data());
      });
      this._isMounted &&
        this.setState({
          data: data,
          existWishList: true,
          visibleLoader: false
        });
    }
  }

  async getWishlistLots(wishlistLots) {
    /*WISH LOTS */
    var dataLots = [];
    if (wishlistLots.length === 0) {
      this._isMounted &&
        this.setState({
          dataLots: dataLots,
          existWishListLots: false,
          visibleLoader: false
        });
    } else {
      await services.asyncForEach(wishlistLots, async value => {
        let doc = await dbFirestore
          .collection("lots")
          .doc(value)
          .get();
        if (doc.exists) dataLots.push(doc.data());
      });
      this._isMounted &&
        this.setState({
          dataLots: dataLots,
          existWishListLots: true,
          visibleLoader: false
        });
    }
  }

  removeToWish(idProduct) {
    this._isMounted && this.setState({ visibleLoaderAll: true });
    let newDataWishlist = this.state.wishlist;
    let data = newDataWishlist.filter(value => value !== idProduct);

    dbFirestore
      .collection("users")
      .doc(this.state.user.uid)
      .update({ wishlist: data })
      .then(docRef => {
        let newDataWish = [];
        let oldDataWish = this.state.data;
        for (var i = 0; i < oldDataWish.length; i++) {
          if (oldDataWish[i].id !== idProduct) {
            newDataWish.push(oldDataWish[i]);
          }
        }
        this._isMounted &&
          this.setState({
            data: newDataWish,
            wishlist: data,
            visibleLoaderAll: false
          });
      })
      .catch(error => {
        console.error("Error ", error);
      });
    if (data.length === 0) {
      this.setState({
        existWishList: false
      });
    }
  }

  removeToWishLots(idProduct) {
    this._isMounted && this.setState({ visibleLoaderAll: true });
    let newDataWishlist = this.state.wishlistLots;
    let data = newDataWishlist.filter(value => value !== idProduct);
    dbFirestore
      .collection("users")
      .doc(this.state.user.uid)
      .update({ wishlist_lots: data })
      .then(docRef => {
        let newDataLots = [];
        let oldDataLots = this.state.dataLots;
        for (var i = 0; i < oldDataLots.length; i++) {
          if (oldDataLots[i].id !== idProduct) {
            newDataLots.push(oldDataLots[i]);
          }
        }
        this._isMounted &&
          this.setState({
            dataLots: newDataLots,
            wishlist_lots: data,
            visibleLoaderAll: false
          });
      })
      .catch(error => {
        console.error("Error ", error);
      });
    if (data.length === 0) {
      this.setState({
        existWishListLots: false
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createWishLots() {
    if (this.state.dataLots.length > 0) {
      var items = [];
      this.state.dataLots.forEach((item, i) => {
        items.push(
          <div
            key={"wishItem-" + i}
            className="col-lg-3 col-md-4 col-6 no-pd-mobile card-wishlist-border"
          >
            <div className="card card-wishlist">
              
              <p
                className="remove-wish"
                onClick={() => {
                  this.removeToWishLots(item.id);
                }}
              >
                <i className="fas fa-times" />
              </p>
              <img
                className="wish-img-cover"
                src={item.principalImage}
                alt={item.title}
              />
              <div className="card-body card-body-wishlist">
                <div className="top-card">
                  <h5 className="card-title center closit_text wish-uper">{item.title}</h5>
                  <p
                    className="card-text center pars-str "
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <p className="card-text center wish-price">
                    Estimated Price: ${ formatNumber(item.estimatedPrice) }
                  </p>
                </div>
                <div className="bottom-card">
                  <div className="btn_container center">
                    <Link
                      className="btn btn-primary btn-sm custom btn-wishlist"
                      to={"lot/" + item.id}
                    >
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
      return items;
    }
  }

  createWishItems() {
    if (
      this.state.data !== null &&
      this.state.data !== undefined &&
      this.state.data.length > 0
    ) {
      let items = [];
      for (var i = 0; i < this.state.data.length; i++) {
        const id = this.state.data[i].id;
        items.push(
          <div
            key={"wishItem-" + i}
            className="col-lg-3 col-md-4 col-sm-6 col-6 card-wishlist-border"
          >
            <div className="card card-wishlist">
              {this.state.data[i].sale && (
                <div className="forSale-box">
                  <p>For Sale</p>
                </div>
              )}
              <p
                className="remove-wish"
                onClick={() => {
                  this.removeToWish(id);
                }}
              >
                <i className="fas fa-times" />
              </p>
              <img
                className="wish-img-cover"
                src={this.state.data[i].principalImage}
                alt={this.state.data[i].title}
              />
              <div className="card-body card-body-wishlist">
                <div className="top-card">
                  <h5 className="card-title center closit_text wish-uper">
                    {this.state.data[i].title}
                  </h5>
                  <p className="card-text center">
                    {this.state.data[i].shortDescription}
                  </p>
                  {this.state.data[i].sale && (
                    <p className="card-text center wish-price">
                    <span className="marked-price">${ formatNumber(this.state.data[i].price) }</span>
                    ${formatNumber( calculateNewPrice(this.state.data[i].price, this.state.data[i].discountSale) )}
                  </p>
                  )}
                  {!this.state.data[i].sale && (
                    <p className="card-text center wish-price">
                    ${formatNumber(this.state.data[i].price)}
                  </p>
                  )}
                </div>
                <div className="bottom-card">
                  <div className="btn_container center">
                    <Link
                      className="btn btn-primary btn-sm custom btn-wishlist"
                      to={"product/" + this.state.data[i].id}
                    >
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return items;
    }
  }

  render() {
    return (
      <div className="Wishlist">
        {this.state.visibleLoader && (
          <div className="loader-wish">
            <Loader type="Oval" color="#000" height="50" width="50" />
          </div>
        )}
        {this.state.visibleLoaderAll && (
          <div className="loader-wish-all-area">
            <div className="loader-wish">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        {this.state.existWishList && (
          <div className="container">
            <div className="row">
              <div className="setting-title_container my-4 col-12">
                <p className="title-wish mb-0 closit_title">Store</p>
              </div>
            </div>
            <div className="row">{this.createWishItems()}</div>
          </div>
        )}
        {this.state.existWishListLots && (
          <div className="container">
            <div className="row">
              <div className="setting-title_container my-4 col-12">
                <p className="title-wish mb-0 closit_title">Auctions</p>
              </div>
            </div>
            <div className="row">{this.createWishLots()}</div>
          </div>
        )}
        {!this.state.existWishListLots &&
          !this.state.existWishList &&
          !this.state.visibleLoader && (
            <div className="no-items-wish">
              <p>
                Use the wishlist icon to save <br /> your favorite products
              </p>
              <p>
                <i className="far fa-heart" />
              </p>
            </div>
          )}
        {this.state.redirect && <Redirect push to={"/cart"} />}
      </div>
    );
  }
}

export default WishlistPage;
