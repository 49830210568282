//Dependencies
import React, { Component } from "react";
import "./terms.css";
import PortraitText from "../../@components/portrait-text";
import { metasTags } from "../../#constants";
class TermsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    metasTags.setMetaTags();
  }

  render() {
    return (
      <div className="Terms">
        <PortraitText/>
      </div>
    );
  }
}

export default TermsPage;
