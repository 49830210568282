//Dependencies
import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import { Link } from "react-router-dom";
import "./search.css";
import Loader from "react-loader-spinner";
import { metasTags, alphabet } from "../../#constants";

class SearchPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      data: {},
      visibleLoader: true
    };
    this.searchTextCategory = this.searchTextCategory.bind(this);
    this.getCategorys = this.getCategorys.bind(this);
    this.refresh = this.refresh.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.getCategorys();
    metasTags.setMetaTags();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getCategorys() {
    let letter;
    const all_data = [];

    //buscando designers
    const docs_designer = await dbFirestore
      .collection("categorys")
      .where("publish", "==", true)
      .where("typeOf", "==", "designer")
      .orderBy("title", "asc")
      .get();
    let data_doc;
    docs_designer.docs.forEach(doc => {
      data_doc = doc.data();
      letter = data_doc.title[0].toUpperCase();
      if (letter === "@") data_doc.title = data_doc.title.substring(1);
      all_data.push(data_doc);
    });

    //buscando influencers
    const docs_influencer = await dbFirestore
      .collection("categorys")
      .where("publish", "==", true)
      .where("typeOf", "==", "influencer")
      .orderBy("title", "asc")
      .get();
    docs_influencer.docs.forEach(doc => {
      data_doc = doc.data();
      letter = data_doc.title[0].toUpperCase();
      if (letter === "@") data_doc.title = data_doc.title.substring(1);
      all_data.push(data_doc);
    });

    const sortData = await all_data.sort(this.compare_to_sort);

    const data = {
      hash: [],
      A: [],
      B: [],
      C: [],
      D: [],
      E: [],
      F: [],
      G: [],
      H: [],
      I: [],
      J: [],
      K: [],
      L: [],
      M: [],
      N: [],
      O: [],
      P: [],
      Q: [],
      R: [],
      S: [],
      T: [],
      U: [],
      V: [],
      W: [],
      X: [],
      Y: [],
      Z: []
    };

    sortData.forEach(value => {
      letter = value.title[0].toUpperCase();
      if (data[letter] === undefined) data["hash"].push(value);
      else data[letter].push(value);
    });

    this._isMounted &&
      this.setState({ data: data, dataSearch: data, visibleLoader: false });
  }

  compare_to_sort(x, y) {
    if (x.title < y.title) return -1;
    if (x.title > y.title) return 1;
    return 0;
  }

  searchTextCategory(evt) {
    this._isMounted && this.setState({ data: [], visibleLoader: true });
    var query = evt.target.value;
    query = query.toLowerCase();
    if (query !== "" && query.trim() !== "") {
      let string;

      const new_data = {
        hash: [],
        A: [],
        B: [],
        C: [],
        D: [],
        E: [],
        F: [],
        G: [],
        H: [],
        I: [],
        J: [],
        K: [],
        L: [],
        M: [],
        N: [],
        O: [],
        P: [],
        Q: [],
        R: [],
        S: [],
        T: [],
        U: [],
        V: [],
        W: [],
        X: [],
        Y: [],
        Z: []
      };

      let letter;
      letter = query[0].toUpperCase();
      if (this.state.dataSearch[letter] === undefined) letter = "hash";
      const dataSearch = this.state.dataSearch[letter];

      for (let i = 0; i < dataSearch.length; i++) {
        string = dataSearch[i].title.toLowerCase();
        if (string.includes(query)) {
          new_data[letter].push(dataSearch[i]);
        }
      }
      this._isMounted &&
        this.setState({ data: new_data, visibleLoader: false }, () => {
          document.querySelectorAll(`.searchSeparator`).forEach( val =>{
            val.classList.add("hidden-search");
          });
          document.querySelector(`#title-${letter}`).classList.remove("hidden-search"); 
          document.querySelector(`#title-${letter}`).classList.add("visible-search");
          /*$("html, body")
            .stop()
            .animate({ scrollTop: $("#title-" + letter).offset().top -100 }, 1000);*/
        });
    } else {
      this._isMounted &&
        this.setState({ data: this.state.dataSearch, visibleLoader: false });
        document.querySelectorAll(`.searchSeparator`).forEach( val =>{
          val.classList.remove("hidden-search");
          val.classList.remove("visible-search");
        });
      /*$("html, body")
        .stop()
        .animate({ scrollTop: 0 }, 1000);*/
    }
  }

  renderList() {
    if (Object.keys(this.state.data).length !== 0) {
      var list = [];
      for (let i = 0; i < Object.keys(this.state.data).length; i++) {
        const item = this.state.data[alphabet[i]];

        list.push(
          <div className="col-12 no-pd" key={"title-" + alphabet[i]}>
            <div className="searchSeparator" id={"title-" + alphabet[i]}>
              <p className="search_letter mb-0">
                {alphabet[i] === "hash" ? "#" : alphabet[i]}
              </p>
              {item.length > 0 && (
                <div className="box-list-categorys">
                  {this.createCategory(item)}
                </div>
              )}
            </div>
          </div>
        );
      }
      return list;
    }
  }

  createCategory(item) {
    if (item.length > 0) {
      var categoryList = [];
      for (let i = 0; i < item.length; i++) {
        categoryList.push(
          <Link
            push
            to={"/categories/" + item[i].typeOf + "/" + item[i].id}
            className="category-entry"
            key={"entry-" + item[i].id}
          >
            <p>{item[i].title}</p>
          </Link>
        );
      }
      return categoryList;
    }
  }

  refresh() {
    this._isMounted &&
      this.setState(
        { data: this.state.dataSearch, visibleLoader: false },
        () => {
          document.querySelectorAll(`.searchSeparator`).forEach( val =>{
            val.classList.remove("hidden-search");
            val.classList.remove("visible-search");
          });
          document.querySelector(".Search input").value = "";
          /*$("html, body")
            .stop()
            .animate({ scrollTop: 0 }, 1000);*/
        }
      );
  }

  render() {
    return (
      <div className="Search">
        {this.state.visibleLoader && (
          <div className="loader-search ">
            <Loader type="Oval" color="#000" height="50" width="50" />
          </div>
        )}
        <div className="container-fluid search-fixed no-pd-mobile">
          <div className="row align-items-center">
            <div className="col-8 col-md-10 col-lg-10">
              <input
                className="form-control new-input "
                placeholder="Search..."
                onChange={this.searchTextCategory}
              />
            </div>
            <div className="col-4 col-md-2 col-lg-2">
              <button
                className="btn btn-secondary btn-refresh"
                onClick={() => this.refresh()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid all-list no-pd-mobile">
          <div className="container maxw-100">
            <div className="row">
              {!this.state.visibleLoader && this.renderList()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchPage;
