import * as firebase from "firebase/app";
import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import $ from "jquery";

require("firebase/functions");

export const Constants = {
  Constants() {

  },
  stripeKey() {

    if (process.env.REACT_APP_INSTA_ENV === "production") {
      return "pk_live_RiwMrb0er5IvgSVHMouNon66000zkHuNcD";
    }
    else {
      return "pk_test_zxw80klXt8UOpKduKos5415f00lgVcV48q";
    }
    // else {
    //   return "pk_test_zxw80klXt8UOpKduKos5415f00lgVcV48q";
    // }

  },
  facebookKey() {
    return "1854179467977417";
  }
}

export const services = {
  //ForEach sincrono
  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  },
  closeAuction(id) {
    var closeAuctionFromApp = firebase.functions().httpsCallable('closeAuctionFromApp');

    return closeAuctionFromApp({ id: id });
  }
}

export const MyMapComponent = compose(
  //coments in  map is for delete de warning of multiple imports of google maps
  withProps({
    //googleMapURL:"https://maps.googleapis.com/maps/api/js?key=AIzaSyBHRlOpWakBHXkoZL1aaKGZcAhXBSej-sI&v=3.exp&libraries=geometry,drawing,places&language=en",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  //withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={10}
    center={{ lat: props.markerLat, lng: props.markerLog }}
  >
    {props.isMarkerShown && (
      <Marker position={{ lat: props.markerLat, lng: props.markerLog }} />
    )}
  </GoogleMap>
));

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const errorMessagesStripe = {
  incorrect_number: "The card number is incorrect.",
  incomplete_number: "The card number is incomplete.",
  invalid_number: "The card number is not a valid credit card number.",
  invalid_expiry_month: "The card's expiration month is invalid.",
  invalid_expiry_year: "The card's expiration year is invalid.",
  incomplete_expiry: "The card's expiration is incomplete",
  invalid_cvc: "The card's security code is invalid.",
  expired_card: "The card has expired.",
  incorrect_cvc: "The card's security code is incorrect.",
  incomplete_cvc: "The card's security code is incomplete",
  incorrect_zip: "The card's zip code failed validation.",
  card_declined: "The card was declined.",
  missing: "There is no card on a customer that is being charged.",
  processing_error: "An error occurred while processing the card.",
  rate_limit: "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error."
};

export const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const metasTags = {

  setMetaTags() {
    if (process.env.REACT_APP_INSTA_ENV === "production") {
      this.setMetaTagsProd();
    }
    else {
      this.setMetaTagsDev();
    }
  },
  setMetaTagsDev() {
    /*NORMAL */
    $('meta[name="description"]').attr('content', `offers a wide selection of artworks by leading artists.`);
    $('meta[property="og:url]"').attr('content', 'https://garynader.com');
    $('meta[property="og:title]"').attr('content', 'Gary Nader Art Centre');
    $('meta[property="og:description]"').attr('content', `offers a wide selection of artworks by leading artists.`);

    /**TITLE */
    $('#website-title').text("Gary Nader Art Centre");
  },
  setMetaTagsProd() {
    /*NORMAL */
    $('meta[name="description"]').attr('content', `offers a wide selection of artworks by leading artists.`);
    $('meta[property="og:url]"').attr('content', 'https://garynader.com');
    $('meta[property="og:title]"').attr('content', 'Gary Nader Art Centre');
    $('meta[property="og:description]"').attr('content', `offers a wide selection of artworks by leading artists.`);

    /**TITLE */
    $('#website-title').text("Gary Nader Art Centre");
  }
}

export const metasTagsProds = {

  setMetaTags(prod) {
    if (process.env.REACT_APP_INSTA_ENV === "production") {
      this.setMetaTagsProd(prod);
    }
    else {
      this.setMetaTagsDev(prod);
    }
  },
  setMetaTagsDev(prod) {
    /*NORMAL */
    $('meta[name="description"]').attr('content', `Gary Nader Art Centre: ${prod.shortDescription}`);
    $('meta[property="og:url]"').attr('content', 'https://garynader.com/product/' + prod.id);
    $('meta[property="og:title]"').attr('content', `${prod.title}`);
    $('meta[property="og:description]"').attr('content', `Gary Nader Art Centre: ${prod.shortDescription}`);
    $('meta[property="og:image]"').attr('content', `Gary Nader Art Centre: ${prod.principalImage}`);
    /**TITLE */
    $('#website-title').text(`Gary Nader Art Centre: ${prod.title}`);
  },
  setMetaTagsProd(prod) {
    /*NORMAL */
    $('meta[name="description"]').attr('content', `Gary Nader Art Centre: ${prod.shortDescription}`);
    $('meta[property="og:url]"').attr('content', 'https://garynader.com/product/' + prod.id);
    $('meta[property="og:title]"').attr('content', `${prod.title}`);
    $('meta[property="og:description]"').attr('content', `Gary Nader Art Centre: ${prod.shortDescription}`);
    $('meta[property="og:image]"').attr('content', `Gary Nader Art Centre: ${prod.principalImage}`);
    /**TITLE */
    $('#website-title').text(`Gary Nader Art Centre: ${prod.title}`);
  }
}

export function formatNumber(num) {
  return ("" + num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) { return $1 + "," });
}

export function calculateNewPrice(oldPrice, discountSale) {
  let discount = (oldPrice * discountSale) / 100;
  let newPrice = oldPrice - discount;
  newPrice = newPrice.toFixed(2);
  newPrice = parseFloat(newPrice);
  return newPrice;
}



export const metasTagsLots = {

  setMetaTags(prod) {
    if (process.env.REACT_APP_INSTA_ENV === "production") {
      this.setMetaTagsProd(prod);
    }
    else {
      this.setMetaTagsDev(prod);
    }
  },
  setMetaTagsDev(prod) {
    /*NORMAL */
    $('meta[name="description"]').attr('content', `Gary Nader Art Centre: ${prod.shortDescription}`);
    $('meta[property="og:url]"').attr('content', 'https://garynader.com/lot/' + prod.id);
    $('meta[property="og:title]"').attr('content', `${prod.title}`);
    $('meta[property="og:description]"').attr('content', `Gary Nader Art Centre: ${prod.shortDescription}`);
    $('meta[property="og:image]"').attr('content', `Gary Nader Art Centre: ${prod.principalImage}`);
    /**TITLE */
    $('#website-title').text(`Gary Nader Art Centre: ${prod.title}`);
  },
  setMetaTagsProd(prod) {
    /*NORMAL */
    $('meta[name="description"]').attr('content', `Gary Nader Art Centre: ${prod.shortDescription}`);
    $('meta[property="og:url]"').attr('content', 'https://garynader.com/lot/' + prod.id);
    $('meta[property="og:title]"').attr('content', `${prod.title}`);
    $('meta[property="og:description]"').attr('content', `Gary Nader Art Centre: ${prod.shortDescription}`);
    $('meta[property="og:image]"').attr('content', `Gary Nader Art Centre: ${prod.principalImage}`);
    /**TITLE */
    $('#website-title').text(`Gary Nader Art Centre: ${prod.title}`);
  }
}
export const alphabet = [
  "hash",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z"
];