//Dependencies
import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Link, Redirect } from "react-router-dom";
import "./checkout.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import { metasTags, formatNumber, services } from "../../#constants";
import * as firebase from "firebase/app";
require("firebase/functions");

class CheckoutPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      data: [],
      logged: false,
      user: {},
      infoUser: {},
      address: "",
      favColor: "",
      subtotal: 0.0,
      items: 0,
      rates: [],
      shipping: 0.0,
      card: {},
      existCard: false,
      completeShipping: false,
      visibleTax: false,
      showAlert: false,
      tax: 0,
      redirect: false,
      redirectOrderConfirmation: false,
      orderConfirmationId: "",
      title_alert: "",
      message_alert: "",
      promo_code: "",
      reward_code: "",
      promoCode_used: [],
      rewardCode_used: [],
      total: 0.0,
      visibleLoader: false,
      promoP: false,
      promoS: false,
      promoT: false,
    };
    this._isMounted = false;
    this.assignVerifyCode = this.assignVerifyCode.bind(this);
    this.verifiyRewardsCode = this.verifiyRewardsCode.bind(this);
    this.verifiyPromotionalCode = this.verifiyPromotionalCode.bind(this);
  }

  showNotification() {
    if (this.state.showAlert) {
      this.toastId = toast.info(
        <div>
          <p className="notify-title">{this.state.title_alert}</p>
          <p className="notify-description">{this.state.message_alert}</p>
        </div>,
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "notify",
          bodyClassName: "notify-body"
        }
      );
    }
  }

  componentDidMount() {
    this._isMounted = true;
    metasTags.setMetaTags();
    auth.onAuthStateChanged(user => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({
            user: userLogged,
            logged: true,
            redirect: false
          });
        //this._isMounted && this.setState({ tax: -1 });
        dbFirestore
          .collection("users")
          .doc(userLogged.uid)
          .get()
          .then(snapshot => {
            let infoUser = snapshot.data();
            this._isMounted &&
              this.setState({
                infoUser: infoUser
              });
            let address = infoUser.address;
            if (address.length > 0) {
              let tam = address.length;
              for (let i = 0; i < tam; i++) {
                if (address[i].default === true) {
                  this._isMounted &&
                    this.setState({
                      address: address[i].address,
                      addressShippoTo: address[i].addressShippoTo
                    });
                  break;
                }
              }
            } else {
              this._isMounted && this.setState({ address: "" });
            }

            let cart = infoUser.cart;

            if (cart.length === 0) {
              this._isMounted &&
                this.setState({
                  subtotal: 0.0,
                  shipping: 0.0
                });
              this.getDefaultCard(infoUser);
            } else {
              //obtenemos subtotal, shipping, tarjeta y tax
              this.verifyShipping(cart)
                .then(() => {
                  this.getDefaultCard(infoUser);
                })
                .catch(err => {
                  this.getDefaultCard(infoUser);
                  console.error(err);
                });
            }
          });
      } else {
        this._isMounted &&
          this.setState({
            user: {},
            logged: false,
            redirect: true,
            infoUser: {}
          });
      }
    });
  }

  //Calcular impuestos
  calculateTax = cart => {
    this._isMounted &&
      this.setState({
        visibleTax: true
      });

    var tax = firebase.functions().httpsCallable("calculateTax");
    tax({ cart: cart, addressTo: this.state.addressShippoTo })
      .then(result => {
        let tax = result.data;
        tax = tax.toFixed(2);
        tax = parseFloat(tax);

        let newTotal = this.state.total;
        newTotal += result.data;
        newTotal = newTotal.toFixed(2);
        newTotal = parseFloat(newTotal);
        this._isMounted &&
          this.setState({
            visibleTax: false,
            existTax: true,
            tax: tax,
            total: newTotal
          });
      })
      .catch(err => {
        this._isMounted &&
          this.setState({
            visibleTax: false
          });
        console.error("Error ", err);
      });
  };

  //Buscar tarjeta default
  getDefaultCard(infoUser) {
    if (infoUser.default_card !== "") {
      var getCard = firebase.functions().httpsCallable("getCard");
      getCard({
        customer_id: infoUser.customer_id,
        card_id: infoUser.default_card
      })
        .then(result => {
          this._isMounted &&
            this.setState(
              {
                card: result.data,
                existCard: true
              },
              () => {
                if (this.state.completeShipping && this.state.address !== "") {
                  this.calculateTax(infoUser.cart);
                }
              }
            );
        })
        .catch(err => {
          console.error("Error ", err);
        });
    } else {
      this._isMounted &&
        this.setState(
          {
            card: {},
            existCard: false
          },
          () => {
            if (this.state.completeShipping && this.state.address !== "") {
              this.calculateTax(infoUser.cart);
            }
          }
        );
    }
  }

  //Buscando shipping, subtotal y cantidad total de productos
  async verifyShipping(cart) {
    let subtotal = 0;
    let shipping = 0;
    let quantity = 0;
    let existShipping = true;
    var data = [];

    await services.asyncForEach(cart, async (value, ind) => {
      try {
        const doc = await dbFirestore.collection("products").doc(value.id).get();
        if (doc.exists){
          if (doc.data().publish) {
            const data_product = doc.data();
            data.push(data_product);
            quantity += value.quantity;
            let sizes = data_product.sizes;
            let selectSize = sizes.filter(val => val.label === value.size);
            let newPrice;

            if(doc.data().sale){
              //calculando precio con descuento
              const discount = (selectSize[0].price * data_product.discountSale)/100;
              newPrice = selectSize[0].price- discount;            
            }
            else{
              newPrice = selectSize[0].price;
            }
            const price = parseFloat(newPrice);
            subtotal = value.quantity * price + subtotal;

            shipping += parseFloat(value.shipping);

            if (value.shippingID !== "") existShipping = existShipping && true;
            else existShipping = existShipping && false;
          }
        }
      } 
      catch (err) {
        console.error(err);
      }
    });
    shipping = shipping.toFixed(2);
    shipping = parseFloat(shipping);

    subtotal = subtotal.toFixed(2);
    subtotal = parseFloat(subtotal);

    let total = 0.0;
    total = shipping + subtotal;
    total = total.toFixed(2);
    total = parseFloat(total);
    this._isMounted && this.setState({total: total,subtotal: subtotal,items: quantity,shipping: shipping,completeShipping: existShipping,data:data});
  }

  //verificamos el codigo de promocion ingresado
  verifiyPromotionalCode = () => {
    let codes_used = this.state.promoCode_used;
    const exist = codes_used.find(item => item === this.state.promo_code);
    if (!exist) {
      dbFirestore
        .collection("promotional_banner")
        .where("code", "==", this.state.promo_code)
        .where("active", "==", true)
        .get()
        .then(docsPromotional => {
          if (!docsPromotional.empty) {
            let promotional = {};
            let discount = 0;
            let porc = 0;
            let newValue = 0;

            const promo_codes = docsPromotional.docs; //documentos que poseen el mismo codigo
            const tam_promo_codes = promo_codes.length;

            for (let i = 0; i < tam_promo_codes; i++) {
              promotional = promo_codes[i].data();
              //se verifica si el usuario ya utilizo el codigo, de ser asi se le muestra un mensaje de error al usuario
              const exist_user = promotional.users_actived.filter(
                value => value === this.state.infoUser.id
              );
              if (exist_user.length === 1) {
                this._isMounted &&
                  this.setState(
                    {
                      title_alert: "Promotion Code Error",
                      message_alert: "You already use this code",
                      showAlert: true,
                      visiblePromoCode: false
                    },
                    () => {
                      this.showNotification();
                    }
                  );
                break;
              }

              porc = parseInt(promotional.percentage, 10) / 100;

              if (promotional.type === "products") {
                discount = this.state.subtotal * porc;
                newValue = this.state.subtotal - discount;
                newValue = newValue.toFixed(2);
                newValue = parseFloat(newValue);
                this._isMounted &&
                  this.setState({ subtotal: newValue, promoP: true });
              } else if (promotional.type === "shipping") {
                if (this.state.shipping > 0) {
                  discount = this.state.shipping * porc;
                  newValue = this.state.shipping - discount;
                  newValue = newValue.toFixed(2);
                  newValue = parseFloat(newValue);
                  this._isMounted &&
                    this.setState({ shipping: newValue, promoS: true });
                } else {
                  //caso el shipping es 0
                  this._isMounted &&
                    this.setState(
                      {
                        title_alert: "Promotional Code Error",
                        message_alert: "Your shipping is 0",
                        showAlert: true,
                        visiblePromoCode: false
                      },
                      () => {
                        this.showNotification();
                      }
                    );
                  continue;
                }
              } else {
                if (this.state.tax > 0) {
                  discount = this.state.tax * porc;
                  newValue = this.state.tax - discount;
                  newValue = newValue.toFixed(2);
                  newValue = parseFloat(newValue);
                  this._isMounted &&
                    this.setState({ tax: newValue, promoT: true });
                } else {
                  //caso el taxes es 0
                  this._isMounted &&
                    this.setState(
                      {
                        title_alert: "Promotional Code Error",
                        message_alert: "Your taxes is 0",
                        showAlert: true,
                        visiblePromoCode: false
                      },
                      () => {
                        this.showNotification();
                      }
                    );
                  continue;
                }
              }
            }
            let total =
              this.state.subtotal + this.state.shipping + this.state.tax;
            total = total.toFixed(2);
            total = parseFloat(total);
            codes_used.push(this.state.promo_code);
            this._isMounted &&
              this.setState({
                total: total,
                promoCode_used: codes_used,
                promo_code: ""
              });
          } else {
            this._isMounted &&
              this.setState(
                {
                  title_alert: "Promotion Code Error",
                  message_alert: "You must enter a correct promotion code",
                  showAlert: true
                },
                () => {
                  this.showNotification();
                }
              );
          }
        });
    } else {
      this._isMounted &&
        this.setState(
          {
            title_alert: "Promotion Code Error",
            message_alert: "You already use this code",
            showAlert: true
          },
          () => {
            this.showNotification();
          }
        );
    }
  };

  //verify rewards code
  async verifiyRewardsCode() {
    var codes_used = this.state.rewardCode_used;
    const exist_codes_used = codes_used.find(
      item => item === this.state.reward_code
    );
    if (!exist_codes_used) {
      const rewardID = this.state.reward_code.split("-")[0];
      //buscar reward asociado
      var response = await dbFirestore
        .collection("rewards")
        .doc(rewardID)
        .get();
      if (response.exists) {
        let exist = response
          .data()
          .achievementsUsed.filter(
            value =>
              value.userID === this.state.infoUser.id &&
              value.codeAchievement === this.state.reward_code
          );
        if (exist.length === 1) {
          if (!exist[0].used) {
            let reward = {},
              discount = 0,
              porc = 0,
              newValue = 0;
            reward = response.data();
            porc = parseInt(reward.percentage, 10) / 100;

            if (reward.type === "products") {
              discount = this.state.subtotal * porc;
              newValue = this.state.subtotal - discount;
              newValue = newValue.toFixed(2);
              newValue = parseFloat(newValue);
              this._isMounted &&
                this.setState({ subtotal: newValue, promoP: true });
            } else if (reward.type === "shipping") {
              if (this.state.shipping > 0) {
                discount = this.state.shipping * porc;
                newValue = this.state.shipping - discount;
                newValue = newValue.toFixed(2);
                newValue = parseFloat(newValue);
                this._isMounted &&
                  this.setState({ shipping: newValue, promoS: true });
              } else {
                //caso el shhipping es 0
                this._isMounted &&
                  this.setState(
                    {
                      title_alert: "Reward Code Error",
                      message_alert: "Your shipping is 0",
                      showAlert: true
                    },
                    () => {
                      this.showNotification();
                    }
                  );
                return;
              }
            } else {
              if (this.state.tax > 0) {
                discount = this.state.tax * porc;
                newValue = this.state.tax - discount;
                newValue = newValue.toFixed(2);
                newValue = parseFloat(newValue);
                this._isMounted &&
                  this.setState({ tax: newValue, promoT: true });
              } else {
                //caso el taxes es 0
                this._isMounted &&
                  this.setState(
                    {
                      title_alert: "Reward Code Error",
                      message_alert: "Your taxes is 0",
                      showAlert: true
                    },
                    () => {
                      this.showNotification();
                    }
                  );
                return;
              }
            }
            let total =
              this.state.subtotal + this.state.shipping + this.state.tax;
            total = total.toFixed(2);
            total = parseFloat(total);
            codes_used.push(this.state.promo_code);
            this._isMounted &&
              this.setState({
                total: total,
                rewardCode_used: codes_used,
                reward_code: ""
              });
          } else {
            //caso el codigo ya fue usado
            this._isMounted &&
              this.setState(
                {
                  title_alert: "Reward Code Error",
                  message_alert: "You already used this rewards code",
                  showAlert: true
                },
                () => {
                  this.showNotification();
                }
              );
          }
        } else {
          //caso el codigo no es tuyo
          this._isMounted &&
            this.setState(
              {
                title_alert: "Reward Code Error",
                message_alert: "You must enter a correct rewards code",
                showAlert: true
              },
              () => {
                this.showNotification();
              }
            );
        }
      } else {
        //caso codigo malo
        this._isMounted &&
          this.setState(
            {
              title_alert: "Reward Code Error",
              message_alert: "You must enter a correct rewards code",
              showAlert: true
            },
            () => {
              this.showNotification();
            }
          );
      }
    } else {
      //caso el codigo ya fue usado en esta misma compra
      this._isMounted &&
        this.setState(
          {
            title_alert: "Reward Code Error",
            message_alert: "You already used this rewards code",
            showAlert: true
          },
          () => {
            this.showNotification();
          }
        );
    }
  }

  assignVerifyCode() {
    let exist = this.state.promo_code.indexOf("-");
    if (exist >= 0) {
      if (
        this.state.existCard &&
        this.state.address !== "" &&
        this.state.completeShipping
      ) {
        this.verifiyRewardsCode();
      } else {
        this._isMounted &&
          this.setState(
            {
              title_alert: "Promotion Code Error",
              message_alert:
                "You must select a Shipping Address, Delivery Method and a Payment Method before set a promo code",
              showAlert: true
            },
            () => {
              this.showNotification();
            }
          );
      }
    } else {
      if (
        this.state.existCard &&
        this.state.address !== "" &&
        this.state.completeShipping
      ) {
        this.verifiyPromotionalCode();
      } else {
        this._isMounted &&
          this.setState(
            {
              title_alert: "Reward Code Error",
              message_alert:
                "You must select a Shipping Address, Delivery Method and a Payment Method before set a promo code",
              showAlert: true
            },
            () => {
              this.showNotification();
            }
          );
      }
    }
  }

  async makeCharge() {
    this._isMounted && this.setState({ visibleLoader: true });
    let amount = this.state.total * 100;
    amount = amount.toFixed(2);
    amount = parseFloat(amount);
    var cart = this.state.infoUser.cart;
    var sizes,tamSize,invalidPurchase = false;

    //Se verifica si hay suficientes productos
    await services.asyncForEach(cart, async value => {
      try {
        const doc = await dbFirestore.collection("products").doc(value.id).get();
        if (doc.exists)
          if (doc.data().publish === true) {
            sizes = doc.data().sizes;
            tamSize = sizes.length;

            for (let j = 0; j < tamSize; j++) {
              if (sizes[j].size === value.size) {
                if (
                  parseInt(sizes[j].availableQuantity, 10) < value.quantity ||
                  value.quantity > parseInt(sizes[j].quantityAllowToBuy, 10)
                ) {
                  invalidPurchase = true;
                }
                break;
              }
            }
          }
      } catch (err) {
        console.error(err);
      }
    });

    //Si no hay suficientes productos en el stock, no se realiza la compra
    if (invalidPurchase) {
      this._isMounted &&
        this.setState(
          {
            title_alert: "Checkout feedback",
            message_alert:
              "A product is sold out at the moment, please check and try again.",
            showAlert: true,
            visibleLoader: false
          },
          () => {
            this.showNotification();
          }
        );
      return;
    }

    var makePay = firebase.functions().httpsCallable("makePay");

    try {
      let orderConfirmation = await makePay({
        amount: amount,
        customer_id: this.state.infoUser.customer_id,
        cart: cart,
        name_user: this.state.infoUser.name,
        address: this.state.infoUser.address,
        items: this.state.items,
        subtotal: this.state.subtotal,
        shipping: this.state.shipping,
        total: this.state.total,
        card: this.state.card,
        tax: this.state.tax,
        promotionalDiscounts: this.state.promoCode_used,
        rewardDiscounts: this.state.rewardCode_used,
        data_products: this.state.data
      });
      this.setState({visibleLoader: false,orderConfirmationId: orderConfirmation.data.idOrder,redirectOrderConfirmation: true});
    }
    catch (error) {
      if(error.details){
        this._isMounted &&
        this.setState(
          {
            title_alert: "Checkout feedback",
            message_alert: error.message,
            showAlert: true,
            visibleLoader: false
          },
          () => {
            this.showNotification();
          }
        );
      }
      else{
        this._isMounted &&
        this.setState(
          {
            title_alert: "Checkout feedback",
            message_alert:
              "An error occurred at this time, please check and try again.",
            showAlert: true,
            visibleLoader: false
          },
          () => {
            this.showNotification();
          }
        );
      }
      if (document.getElementById("checkBTN")) {
        document.getElementById("checkBTN").removeAttribute("disabled");
      }
      return;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="Checkout">
        {this.state.visibleLoader && (
          <div className="loader-area">
            <div className="loader-box">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        <div className="container">
          <div className="setting-title_container mb-3">
            <p className="title-history mb-0 closit_title">Place Order</p>
          </div>
        </div>
        {this.state.redirect && <Redirect push to={"/"} />}
        {this.state.redirectOrderConfirmation && (
          <Redirect
            push
            to={"/order-information/" + this.state.orderConfirmationId}
          />
        )}
        <div className="container-fluid no-pd">
          <div className="container">
            <Link
              to={{
                pathname: "/address-book/",
                state: { data: { checkout: true } }
              }}
            >
              <div className="row separator-row bg-white box-info-check align-items-center">
                <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                  <p className="no-m title-check closit_text">
                    Shipping address
                  </p>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  {this.state.address !== "" && (
                    <p className="no-m text-l ">{this.state.address}</p>
                  )}
                  {this.state.address === "" && (
                    <p className="no-m select-check-text">
                      Select a shipping address
                    </p>
                  )}
                </div>
                <div className="col-2 col-sm-2 col-md-2 col-lg-2">
                  {this.state.address !== "" && (
                    <span className="check-icon">
                      <i className="fas fa-check-circle check-green" />
                    </span>
                  )}
                </div>
                <div className="col-1 col-sm-1 col-md-1 col-lg-1">
                  <p className="no-m text-icons">
                    <span className="check-icon">
                      <i className="fas fa-chevron-right" />
                    </span>
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/delivery"}>
              <div className="row separator-row bg-white box-info-check">
                <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                  <p className="no-m title-check closit_text">
                    Delivery method
                  </p>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  {this.state.completeShipping && (
                    <p className="no-m text-l">Total ${this.state.shipping}</p>
                  )}
                  {!this.state.completeShipping && (
                    <p className="no-m select-check-text">
                      Select a shipping method
                    </p>
                  )}
                </div>
                <div className="col-2 col-sm-2 col-md-2 col-lg-2">
                  {this.state.completeShipping && (
                    <span className="check-icon">
                      <i className="fas fa-check-circle check-green" />
                    </span>
                  )}
                </div>
                <div className="col-1 col-sm-1 col-md-1 col-lg-1">
                  <p className="no-m text-icons">
                    <span className="check-icon">
                      <i className="fas fa-chevron-right" />
                    </span>
                  </p>
                </div>
              </div>
            </Link>
            <Link
              to={{ pathname: "/cards/", state: { data: { checkout: true } } }}
            >
              <div className="row separator-row bg-white box-info-check">
                <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                  <p className="no-m title-check closit_text">Payment method</p>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  {this.state.existCard && (
                    <p className="no-m text-l">
                      {this.state.card.brand} {this.state.card.last4}
                    </p>
                  )}
                  {this.state.existCard && (
                    <p className="no-m text-l">
                      {this.state.card.exp_month} - {this.state.card.exp_year}
                    </p>
                  )}
                  {!this.state.existCard && (
                    <p className="no-m select-check-text">
                      Select a payment method
                    </p>
                  )}
                </div>
                <div className="col-2 col-sm-2 col-md-2 col-lg-2">
                  {this.state.existCard && (
                    <span className="check-icon">
                      <i className="fas fa-check-circle check-green" />
                    </span>
                  )}
                </div>
                <div className="col-1 col-sm-1 col-md-1 col-lg-1">
                  <p className="no-m text-icons">
                    <span className="check-icon">
                      <i className="fas fa-chevron-right" />
                    </span>
                  </p>
                </div>
              </div>
            </Link>
            <div className="container my-4">
              <div className="row align-items-center">
                <div className="col-12 col-lg-12 center ">
                  <input
                    className="form-control mb-3 new-input settings-pd promo-input"
                    name="promo"
                    type="text"
                    placeholder="Promo Code"
                    onChange={e => {
                      this._isMounted &&
                        this.setState({ promo_code: e.target.value });
                      this._isMounted &&
                        this.setState({ reward_code: e.target.value });
                    }}
                    defaultValue={this.state.promo_code}
                  />
                </div>
                <div className="col-12 col-lg-12 center">
                  <button
                    disabled={this.state.promo_code === "" ? true : false}
                    className="btn btn-secondary btn-menu btn-size chekout-btn-w"
                    onClick={this.assignVerifyCode}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div className="row bg-white box-info-order-check pr-4 pl-4">
              <div className="col-6 no-pd">
                <p>
                  Items: {this.state.items},Subtotal: ${formatNumber(this.state.subtotal)}{" "}
                  {this.state.promoP && (
                    <span className="label-discount">*</span>
                  )}
                </p>
                <p>
                  Shipping: ${formatNumber(this.state.shipping)}{" "}
                  {this.state.promoS && (
                    <span className="label-discount">*</span>
                  )}
                </p>
                {this.state.tax === 0 && <p> Tax included</p>}
                {this.state.tax > 0 && <p> Tax: ${formatNumber(this.state.tax)}</p>}
              </div>
              <div className="col-6 right no-pd">
                <p className="bold closit_text">Total</p>
                <p className="bold closit_text">
                  ${formatNumber(this.state.total)}{" "}
                  {this.state.promoT && (
                    <span className="label-discount">*</span>
                  )}
                </p>
              </div>
              <div className="col-12 col-lg-12 align-items-center center">
                <button
                  id="checkBTN"
                  className="btn btn-secondary btn-check-place chekout-btn-w"
                  disabled={
                    !(
                      this.state.existCard &&
                      this.state.address !== "" &&
                      this.state.completeShipping
                    )
                  }
                  onClick={() => {
                    document
                      .getElementById("checkBTN")
                      .setAttribute("disabled", true);
                    this.makeCharge();
                  }}
                >
                  Place Order
                </button>
                {(this.state.promoP ||
                  this.state.promoS ||
                  this.state.promoT) && (
                  <p className="text-discount">
                    <span className="label-discount">*</span> Discount was
                    applied.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckoutPage;
