//Dependencies
import React, { Component } from "react";
import "./journal-detail.css";
import { dbFirestore } from "../../@firebase";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import { metasTags } from "../../#constants";
class JournalDetailPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      data: {
        principalImage: "",
        title: "",
        description: ""
      },
      redirect404: false,
      visibleLoader: true
    };
    this.init = this.init.bind(this);
    this._isMounted = false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    this.init(this.props.match.params.id);
  }

  async init(journalId){
    let snapshot = await dbFirestore.collection("journal").doc(journalId).get();
    if (snapshot.exists) {
      this._isMounted && this.setState({data: snapshot.data(),visibleLoader: false});
    } 
    else {
      this._isMounted && this.setState({ redirect404: true, visibleLoader: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createBanner(){
    if(this.state.data.principalImage !== ""){
      let slide = [];

      if(this.state.data.principalImageWeb !== undefined && this.state.data.principalImageWeb !== ""){
        slide.push(
          <div className="image-banner-journal">
            <img className="journal-detail-banner web" src={this.state.data.principalImageWeb} alt={this.state.data.title}/>
            <img className="journal-detail-banner mobile" src={this.state.data.principalImage} alt={this.state.data.title}/>
          </div>
        );
      }
      else{
        slide.push(
          <div className="image-banner-journal">
            <img className="journal-detail-banner" src={this.state.data.principalImage} alt={this.state.data.title}/>
          </div>
        );
      }
      
      return slide;
    }
  }
  render() {
    const data = this.state.data;
    if (this.state.visibleLoader) {
      return (
        <div className="Journal-detail">
          {this.state.visibleLoader && (
            <div className="loader-journal-detail">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          )}
        </div>
      );
    }
    if (data.title !== "") {
      return (
        <div className="Journal-detail">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="journal-detail-title mt-4">{data.title}</h2>
                {this.createBanner()}
                <div className="body-journal-detail">
                  <div
                    className="pars-str"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>{this.state.redirect404 && <Redirect push to={"/404"} />}</div>
      );
    }
  }
}

export default JournalDetailPage;
