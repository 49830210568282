//Dependencies
import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import { Link } from "react-router-dom";
import "./journal-card-mini.css";

class JournalCardMini extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      data: [
        {
          principalImage: ".",
          title: ".",
          description: "."
        }
      ]
    };
    this.init = this.init.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.init();
  }

  async init(){
    let snapshot = await dbFirestore.collection("journal").where("publish", "==", true).orderBy("updatedAt", "desc").limit(6).get();
    let data = [];
    snapshot.forEach(doc => {data.push(doc.data())});
    this._isMounted && this.setState({data: data});   
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  createJournalCardMini(){
    if (this.state !== null && this.state !== undefined) {
      if (this.state.data.length > 1) {
        let journals = [];
        for (var i = 0; i < this.state.data.length; i++) {
          var padding = "pd-l-none";
          if (i % 2 === 0) padding = "pd-r-none";
          journals.push(
            <div
              className={"col-12 col-md-6 no-pd-mobile " + padding}
              key={"journal-card-" + i}
            >
              <div className="card journal-card-mini">
                <Link to={"/journal/" + this.state.data[i].id}>
                  <div
                    className="journal-card-mini-img-box"
                    style={{
                      backgroundImage:
                        "url(" + this.state.data[i].principalImage + ")",
                      backgroundPosition: "center",
                      backgroundSize: "cover"
                    }}
                  >
                    <div className="overlay" />
                  </div>
                </Link>
                <div className="card-body body-journal-card-mini">
                  <Link to={"/journal/" + this.state.data[i].id}>
                    <h5 className="card-title closit_title">
                      {this.state.data[i].title}
                    </h5>
                  </Link>
                </div>
              </div>
            </div>
          );
        }
        return journals;
      }
    }
  };
  render() {
    return (
      <div className="container-fluid separator-section JournalCardMini">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="category_name category-title closit_title">
                Journal
                <span className="category-shop-all">
                  <Link to={"/journal/"}>
                    See all <i className="fas fa-chevron-right" />
                  </Link>
                </span>
              </h3>
            </div>
            <hr className="journal-hr" />
            <div className="container no-pd-mobile journal_margin no-pd">
              <div className="row">{this.createJournalCardMini()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JournalCardMini;
