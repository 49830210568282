import React from 'react'

const Video = (video, index) => {
  return (
    <div key={index}>
      {/* <VideoExpo
        source={{ uri: video.video }}
        rate={1.0}
        volume={1.0}
        isMuted={false}
        resizeMode="contain"
        shouldPlay={false}
        isLooping={false}
        useNativeControls
        style={{ width: "100%", height: 300 }}
      /> */}
      <video loop={false} muted={false} controls style={{ objectFit: 'contain', width: '100%' }}>
        <source src={video.video} />
        Your browser does not support HTML5 video.
      </video>


    </div>
  )
}

export default Video

