//Dependencies
import React, { Component } from "react";
import $ from "jquery";
import "./btn-top.css";
class BtnTop extends Component {
  constructor() {
    super();
    this.scrollTop = this.scrollTop.bind(this);
    this.windowScroll = this.windowScroll.bind(this);
  }
  componentDidMount() {
    this.windowScroll();
    window.addEventListener("scroll", () => {
      this.windowScroll();
    });
  }
  scrollTop(e) {
    $("html, body").animate({ scrollTop: 0 }, 1000);
  }

  windowScroll() {
    if (
      window.pageYOffset > 200 &&
      window.location.pathname !== "/cart" &&
      window.location.pathname !== "/checkout"
    ) {
      document.getElementById("form-btn").classList.add("visible");
    } else {
      document.getElementById("form-btn").classList.remove("visible");
    }
  }
  render() {
    return (
      <div id="form-btn" className="shadow-call" onClick={this.scrollTop}>
        <div>
          <i className="fas fa-arrow-up" />
        </div>
      </div>
    );
  }
}

export default BtnTop;
