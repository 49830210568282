//Dependencies
import React from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import Layout from "./layout";
import HomePage from "./@pages/home";
import ShopInfluencerPage from "./@pages/shop-influencer";
import ShopDesignerPage from "./@pages/shop-designer";
import ShopGeneralPage from "./@pages/shop-general";
import JournalPage from "./@pages/journal";
import JournalDetailPage from "./@pages/journal-detail";
import WishlistPage from "./@pages/wishlist";
import AuctionsPage from "./@pages/auctions";
import CategoriesPage from "./@pages/categories";
import NotFoundPage from "./@pages/not-found";
import ProductDetail from "./@pages/product-detail";
import AuctionDetailPage from "./@pages/auction-detail";
import ViewingRoomPage from "./@pages/viewing-room";
import ViewingRoomDetailPage from "./@pages/viewing-room-detail";
import ViewingRoomDetailLotPage from "./@pages/viewing-room-lot-detail";
import DetailLotPage from "./@pages/detail-lot";
import CheckoutPage from "./@pages/checkout";
import ConfirmLotPage from "./@pages/confirm-lot";
import SearchPage from "./@pages/search";
import SearchDetailsPage from "./@pages/search-detail";
import DeliveryPage from "./@pages/delivery";
import CardsPage from "./@pages/cards";
import AddressBookPage from "./@pages/address-book";
import NewCardPage from "./@pages/new-card";
import PickPlacePage from "./@pages/pick-place";
import NewAddressPage from "./@pages/new-address";
import OrderInformationPage from "./@pages/order-information";
import Settings from "./@pages/settings";
import HistoryPage from "./@pages/history";
import DetailAndPassword from "./@pages/detail-password";
import HistoryDetailPage from "./@pages/history-detail";
import TermsPage from "./@pages/terms";
import ConditionsOfSale from "./@pages/conditions";
import SizeGuidePage from "./@pages/size-guide";
import NewReferralPage from "./@pages/new-referral";
import DiscoverPage from "./@pages/discover";

// Private Sell
import PrivateSellPage from "./@pages/private-sell"; // Private Sell Page
import PrivateSellDetailPage from "./@pages/private-sell-detail"; // Private Sell detail page
import PrivateArtworkDetailPage from "./@pages/private-artwork-detail"; // Private artwork detail page
import PrivateConditionsOfSale from "./@pages/private-condition";

// Sealed Offer Sell
import SealedOfferSellPage from "./@pages/sealed-offer-sell"; // SealedOffer Sell Page
import SealedOfferSellDetailPage from "./@pages/sealed-offer-sell-detail"; // SealedOffer Sell detail page
import SealedOfferArtworkDetailPage from "./@pages/sealed-offer-artwork-detail"; // SealedOffer artwork detail page
import SealedOfferConditionsOfSale from "./@pages/sealed-offer-condition";

const ClositRoutes = () => (
  <Layout>
    <Switch>
      <Route exact={true} path="/" component={HomePage} />
      <Route
        exact={true}
        path="/categories/influencer"
        component={ShopInfluencerPage}
      />
      <Route
        exact={true}
        path="/categories/artists"
        component={ShopDesignerPage}
      />
      <Route
        exact={true}
        path="/categories/general"
        component={ShopGeneralPage}
      />
      <Route exact={true} path="/journal" component={JournalPage} />
      <Route exact={true} path="/journal/:id" component={JournalDetailPage} />
      <Route
        exact={true}
        path="/categories/:cat/:id"
        component={CategoriesPage}
      />
      <Route exact={true} path="/wishlist" component={WishlistPage} />
      <Route exact={true} path="/product/:id" component={ProductDetail} />
      <Route exact={true} path="/auctions" component={AuctionsPage} />
      <Route exact={true} path="/auction/:id" component={AuctionDetailPage} />
      <Route exact={true} path="/lot/:id" component={DetailLotPage} />
      <Route exact={true} path="/viewing-rooms" component={ViewingRoomPage} />
      <Route
        exact={true}
        path="/viewing-rooms/:id"
        component={ViewingRoomDetailPage}
      />
      <Route
        exact={true}
        path="/viewing-room/:idViewingRoom/lot/:id"
        component={ViewingRoomDetailLotPage}
      />
      {/* <Route exact={true} path="/cart" component={CartListPage} /> */}
      <Route exact={true} path="/checkout" component={CheckoutPage} />
      <Route exact={true} path="/confirm-lot" component={ConfirmLotPage} />
      <Route exact={true} path="/search" component={SearchPage} />
      <Route exact={true} path="/search-detail" component={SearchDetailsPage} />
      <Route exact={true} path="/delivery" component={DeliveryPage} />
      <Route exact={true} path="/cards" component={CardsPage} />
      <Route exact={true} path="/address-book" component={AddressBookPage} />
      <Route exact={true} path="/new-card" component={NewCardPage} />
      <Route exact={true} path="/pick-place" component={PickPlacePage} />
      <Route exact={true} path="/new-address" component={NewAddressPage} />
      <Route
        exact={true}
        path="/order-information/:id"
        component={OrderInformationPage}
      />
      <Route exact={true} path="/settings" component={Settings} />
      <Route exact={true} path="/settings/history" component={HistoryPage} />
      <Route
        exact={true}
        path="/settings/details"
        component={DetailAndPassword}
      />
      <Route
        exact={true}
        path="/order-detail/:id"
        component={HistoryDetailPage}
      />
      <Route exact={true} path="/terms" component={TermsPage} />
      <Route exact={true} path="/conditions" component={ConditionsOfSale} />
      <Route exact={true} path="/size-guide" component={SizeGuidePage} />
      <Route exact={true} path="/new-referral" component={NewReferralPage} />
      <Route exact={true} path="/discover" component={DiscoverPage} />
      {/* Private Sell Routes */}
      <Route exact={true} path="/private-sale" component={PrivateSellPage} />
      <Route
        exact={true}
        path="/private-sale/:id"
        component={PrivateSellDetailPage}
      />
      <Route
        exact={true}
        path="/private-artwork/:id"
        component={PrivateArtworkDetailPage}
      />
      <Route
        exact={true}
        path="/private-conditions"
        component={PrivateConditionsOfSale}
      />
      {/* Sealed Offer Sell Routes */}
      <Route
        exact={true}
        path="/sealed-offer"
        component={SealedOfferSellPage}
      />
      <Route
        exact={true}
        path="/sealed-offer/:id"
        component={SealedOfferSellDetailPage}
      />
      <Route
        exact={true}
        path="/sealed-offer-artwork/:id"
        component={SealedOfferArtworkDetailPage}
      />
      <Route
        exact={true}
        path="/sealed-offer-conditions"
        component={SealedOfferConditionsOfSale}
      />
      <Route component={NotFoundPage} />
    </Switch>
  </Layout>
);

export default ClositRoutes;
