import React, { Component } from "react";
import "./rewards.css";
import { auth, dbFirestore } from "../../@firebase";
import { Redirect } from "react-router-dom";
import Achievement from "../achievement";
import ProgressRing from "../progress-ring";
import $ from "jquery";

class Rewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      logged: false,
      image: "",
      infoUser: {},
      name: "",
      progress: 0,
      shareLink: "",
      tooltip: "Copy to clipboard",
      achievements: [],
      referrals: [],
      redirect: false
    };
    this.copyClipboard = this.copyClipboard.bind(this);
    this.createAchiviments = this.createAchiviments.bind(this);
    this.calculateProgress = this.calculateProgress.bind(this);
    this.openShareModal = this.openShareModal.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    $("#copy").tooltip();

    auth.onAuthStateChanged(async user => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({ user: userLogged, logged: true, redirect: false });

        let snapshot = await dbFirestore
          .collection("users")
          .doc(userLogged.uid)
          .get();
        this._isMounted &&
          this.setState({
            infoUser: snapshot.data(),
            image: snapshot.data().photo,
            name: snapshot.data().name,
            referrals: snapshot.data().referrals
          });

        var achivimentsData = [];
        let achiviments = await dbFirestore
          .collection("rewards")
          .where("publish", "==", true)
          .get();
        if (!achiviments.empty) {
          achiviments.forEach(value => {
            achivimentsData.push(value.data());
          });
          this._isMounted && this.setState({ achievements: achivimentsData });
        }
        this.calculateProgress();
      } else {
        this._isMounted &&
          this.setState({ user: {}, logged: false, redirect: true });
      }
    });
  }

  copyClipboard() {
    var copyText = document.getElementById("shareLink");
    copyText.select();
    document.execCommand("copy");
    this._isMounted &&
      this.setState(
        {
          tooltip: "Copied"
        },
        () => {
          $("#copy").tooltip("hide");
          $("#copy").tooltip("show");
        }
      );
  }

  createAchiviments() {
    if (this.state.achievements.length > 0) {
      let achievements = [];
      for (var i = 0; i < this.state.achievements.length; i++) {
        achievements.push(
          <Achievement
            dataAchiviment={this.state.achievements[i]}
            referrals={this.state.referrals}
            userID={this.state.infoUser.id}
            key={"achiviment-" + i}
            openShareModal={this.openShareModal.bind(this)}
          />
        );
      }

      return achievements;
    }
  }

  calculateProgress() {
    var qtyAchievements = 0;
    var qtyUnlockeds = 0;
    for (var i = 0; i < this.state.achievements.length; i++) {
      let exist = this.state.achievements[i].achievementsUsed.filter(
        value => value.userID === this.state.infoUser.id
      );
      if (exist.length === 0) {
        qtyAchievements++;
      } else if (exist.length === 1 && !exist[0].used) {
        qtyAchievements++;
        qtyUnlockeds++;
      } else {
        //do nothing the achiviements is used
      }
    }
    if (qtyAchievements > 0) {
      let res = (qtyUnlockeds * 100) / qtyAchievements;
      if (res === 0) {
        this._isMounted && this.setState({ progress: 1 });
      } else {
        this._isMounted && this.setState({ progress: qtyUnlockeds });
      }
    } else {
      this._isMounted && this.setState({ progress: 0 });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openShareModal(rewardID) {
    var shareLink =
      window.location.origin +
      "/new-referral/?u=" +
      this.state.infoUser.id +
      "&r=" +
      rewardID;
    this._isMounted &&
      this.setState({ shareLink: shareLink }, () => {
        $("#shareModal").modal("show");
      });
  }

  render() {
    const imageUrl = this.state.image;
    return (
      <div className="Rewards">
        {this.state.redirect && <Redirect push to={"/"} />}
        <div className="container header-settings ">
          <div className="row justify-content-center">
            <div className="col-lg-12 sept-3">
              <ProgressRing
                radius={90}
                stroke={4}
                progress={100}
                className={"bg"}
              />
              <ProgressRing
                radius={90}
                stroke={4}
                progress={this.state.progress}
                className={"rewards"}
              />
              <div className="reward_pic_container">
                <img className="reward_pic" src={imageUrl} alt="" />
              </div>
            </div>

            <div className="col-lg-12 sept-2">
              <div className="reward_name_container">
                <h3 className="closit_title reward_name_title">
                  {this.state.name}
                </h3>
              </div>
            </div>

            <div className="col-lg-12 text-center mb-3">
              {/*<a
                className="btn btn-primary btn-sm w-300 share-reward my-4"
                data-toggle="modal"
                data-target="#shareModal"
              >
                Share
                <i className="fas fa-share-square ml-2" />
              </a>*/}
              <h5 className="view_all closit_title title-trophy">
                View all Trophies
              </h5>
            </div>
          </div>
        </div>

        {/*ALL ACHIVIMENTS */}
        {this.createAchiviments()}
        {/*END ALL ACHIVIMENTS */}

        {/*MODAL SHARE LINK */}
        <div
          className="modal fade"
          id="shareModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="shareModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content share_modal">
              <div className="p-3 text-center">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="lot_modal_x" aria-hidden="true">
                    &times;
                  </span>
                </button>
                <h5 id="shareModalLabel" className="closit_text">
                  Share Link
                </h5>
              </div>
              <div className="modal-body text-center container">
                <div className="row align-items-center justify-content-center mb-3">
                  <span className="col-2">
                    <a
                      className="no_hover"
                      href={
                        "mailto:?subject=GaryNader&body=" + this.state.shareLink
                      }
                    >
                      <i className="fas fa-envelope" />
                    </a>
                  </span>
                  <span className="text-left col-10 p-0">
                    <a
                      className="mb-0 share_item"
                      href={
                        "mailto:?subject=GaryNader&body=" + this.state.shareLink
                      }
                    >
                      Share Link by Email
                    </a>
                  </span>
                </div>
                <div className="row align-items-center justify-content-center mb-3 pr-5">
                  <span className="col-2">
                    <a
                      id="copy"
                      data-toggle="tooltip"
                      data-placement="left"
                      data-html="true"
                      data-original-title={this.state.tooltip}
                      onClick={() => {
                        this.copyClipboard();
                      }}
                    >
                      <i className="fas fa-copy" />
                    </a>
                  </span>
                  <input
                    className="form-control new-input p-0 col-10"
                    type="text"
                    value={this.state.shareLink}
                    id="shareLink"
                    readOnly
                  />
                </div>
                <div className="row align-items-center justify-content-center mb-3 pr-5">
                  <span className="col-2">
                    <a
                      data-action="share/whatsapp/share"
                      href={"https://wa.me/?text=" + this.state.shareLink}
                      _blank
                      className="no_hover"
                    >
                      <i className="fab fa-whatsapp" />
                    </a>
                  </span>
                  <span className="text-left col-10 p-0">
                    <a
                      className="mb-0 share_item  "
                      data-action="share/whatsapp/share"
                      href={"https://wa.me/?text=" + this.state.shareLink}
                    >
                      Share Link by WhatsApp
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rewards;
