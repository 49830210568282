import React, { Component } from "react";
import "./progress-ring.css";

class ProgressRing extends Component {
    constructor(props) {
        super(props);
        const { radius, stroke } = this.props;
        this.normalizedRadius = radius - stroke * 2;
        this.circumference = this.normalizedRadius * 2 * Math.PI;
    }
  componentDidMount() {}

  render() {
    const { radius, stroke, progress } = this.props;
    const strokeDashoffset = this.circumference - progress / 100 * this.circumference;
    return (
        <svg
        height={radius * 2}
        width={radius * 2}
        className={ this.props.class === "rewards" ? "centered-progress" :  this.props.class === "bg" ? "bg" :  "achiviment" }
        >
        <circle
            fill="transparent"
            strokeWidth={ stroke }
            strokeDasharray={ this.circumference + ' ' + this.circumference }
            style={ { strokeDashoffset } }
            r={ this.normalizedRadius }
            cx={ radius }
            cy={ radius }
            strokeLinecap="round"   
            />
        </svg>
    );
  }
}
export default ProgressRing;