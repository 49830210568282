//Dependencies
import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroller";
import { metasTags } from "../../#constants";
import "./discover.css";

class DiscoverPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      data: [],
      dataSearch: [],
      searchQuery: "",
      dataQuery: [],
      images: [],
      gridInitial: true,
      visibleLoader: true,
      isRefreshing: false,
      isLoadingMore: false,
      end: 0,
      hasMoreItems: true,
      dataRandom: []
    };
    this.compare = this.compare.bind(this);
    this.arrayImages = this.arrayImages.bind(this);
    this.groupResults = this.groupResults.bind(this);
    this.searchText = this.searchText.bind(this);
    this.shuffle = this.shuffle.bind(this);
    this.handleBlurSearch = this.handleBlurSearch.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.pagination = this.pagination.bind(this);
    this._isMounted = false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.getProducts();
    metasTags.setMetaTags();
  }

  async getProducts() {
    let snapshot = await dbFirestore
      .collection("products")
      .where("publish", "==", true)
      .orderBy("updatedAt", "desc")
      .get();
    try {
      let data = [];
      snapshot.forEach(doc => {
        data.push(doc.data());
      });
      let sortRandom = this.shuffle(data);
      this._isMounted &&
        this.setState({ data: [] }, () => {
          this._isMounted &&
            this.setState(
              { dataRandom: sortRandom, dataSearch: data, isRefreshing: false },
              () => {
                this.pagination();
                this._isMounted && this.setState({ visibleLoader: false });
              }
            );
        });
    } catch (error) {
      console.error(error);
    }
  }

  compare = (a, b) => {
    if (a.cant > b.cant) return -1;
    if (a.cant < b.cant) return 1;
    return 0;
  };

  arrayImages = result => {
    let images = [];
    result.forEach(x => {
      x.content.forEach(value => {
        images.push({ id: value.id, principalImage: value.image });
      });
    });
    return images;
  };

  groupResults = (data, search) => {
    var query = search.toLowerCase();
    var x = {};
    var tam = 0;
    var keyword;
    var existKeyWord;
    data.forEach(value => {
      tam = value.keywords.length;
      existKeyWord = false;
      if (tam > 0) {
        for (let i = 0; i < tam; i++) {
          keyword = value.keywords[i].toLowerCase();

          if (keyword.includes(query)) {
            if (x[keyword] === undefined)
              x[keyword] = {
                title: keyword,
                content: [{ id: value.objectID, image: value.principalImage }],
                cant: 1
              };
            else {
              x[keyword].content.push({
                id: value.objectID,
                image: value.principalImage
              });
              x[keyword].cant++;
            }
            if (!existKeyWord) existKeyWord = true;
          }
          if (i + 1 === tam && !existKeyWord) {
            if (x[value.title] === undefined)
              x[value.title] = {
                title: value.title,
                content: [{ id: value.objectID, image: value.principalImage }],
                cant: 1
              };
            else {
              x[value.title].content.push({
                id: value.objectID,
                image: value.principalImage
              });
              x[value.title].cant++;
            }
            break;
          }
        }
      } else {
        if (x[value.title] === undefined)
          x[value.title] = {
            title: value.title,
            content: [{ id: value.objectID, image: value.principalImage }],
            cant: 1
          };
        else {
          x[value.title].content.push({
            id: value.objectID,
            image: value.principalImage
          });
          x[value.title].cant++;
        }
      }
    });

    //ordenando por cantidad, de mayor a menor
    var result = [];
    for (var i in x) result.push(x[i]);
    result.sort(this.compare);

    //sacando las imagenes
    let images = this.arrayImages(result);

    this._isMounted &&
      this.setState({
        dataQuery: result,
        images: images,
        gridInitial: false,
        visibleLoader: false
      });
  };

  searchText(e) {
    var query = e.target.value.toLowerCase();
    if (query !== "" && query.trim() !== "") {
      let string;
      let data = [];
      document.querySelector(".labels-discover").classList.add("visible");
      this.setState({ searchQuery: query, visibleLoader: true });
      let dataSearch = this.state.dataSearch;
      let tam_data = dataSearch.length;
      let keywords, tam_keywords;
      let exist_keyword;

      for (let i = 0; i < tam_data; i++) {
        exist_keyword = false;
        keywords = dataSearch[i].keywords;
        tam_keywords = keywords.length;

        for (let j = 0; j < tam_keywords; j++) {
          string = keywords[j].toLowerCase();
          if (string.includes(query)) {
            data.push(dataSearch[i]);
            exist_keyword = true;
            break;
          }
        }
        if (!exist_keyword) {
          string = dataSearch[i].title.toLowerCase();
          if (string.includes(query)) {
            data.push(dataSearch[i]);
          }
        }
      }
      this._isMounted && this.setState({ data: data, visibleLoader: false });
      document.querySelector(".labels-discover").classList.remove("visible");
    } 
    else {
      let sortRandom = this.shuffle(this.state.dataSearch);
      this._isMounted &&
        this.setState({ data: sortRandom, visibleLoader: false });
      document.querySelector(".labels-discover").classList.remove("visible");
    }
  }

  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  createProdsGridInitial() {
    if (this.state !== null && this.state !== undefined) {
      let prods = [];
      for (var i = 0; i < this.state.data.length; i++) {
        prods.push(
          <div
            className="col-4 col-md-15 prod-box-discover"
            key={"prod-discover-" + i}
          >
            <Link
              className="w-100 h-100 d-block"
              to={"/product/" + this.state.data[i].id}
            >
              <div className="img-bg-overlay" />
              <img
                className="img-discover"
                src={this.state.data[i].principalImage}
                alt={this.state.data[i].title}
              />
              <p className="title-hover">{this.state.data[i].title}</p>
            </Link>
          </div>
        );
      }
      return prods;
    }
  }

  createProdsGrid() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.images !== null && this.state.images !== undefined) {
        let prods = [];
        for (var i = 0; i < this.state.images.length; i++) {
          prods.push(
            <div
              className="col-4 col-md-15 prod-box-discover"
              key={"prod-discover-" + i}
            >
              <Link
                className="w-100 h-100 d-block"
                to={"/product/" + this.state.images[i].id}
              >
                <div className="img-bg-overlay" />
                <img
                  className="img-discover"
                  src={this.state.images[i].principalImage}
                  alt={"prod-discover-" + i}
                />
                <p className="title-hover">{this.state.data[i].title}</p>
              </Link>
            </div>
          );
        }
        return prods;
      }
    }
  }

  creatorLabels() {
    if (this.state !== undefined && this.state !== null) {
      if (this.state.dataQuery !== undefined && this.state.dataQuery !== null) {
        let options = [];
        for (var i = 0; i < this.state.dataQuery.length; i++) {
          if (i !== 0) {
            options.push(
              <div className="dropdown-divider" key={"op-divider-" + i} />
            );
          }

          options.push(
            <Link
              className="dropdown-item"
              key={"label-" + i}
              to={{
                pathname: "/search-detail",
                state: {
                  data: {
                    products: this.state.dataQuery[i]
                  }
                }
              }}
            >
              <p>
                {this.state.dataQuery[i].title} <br />{" "}
                {this.state.dataQuery[i].cant}
              </p>
              <span className="icon-right">
                <i className="fas fa-chevron-right" />
              </span>
            </Link>
          );
        }
        return options;
      }
    }
  }

  handleBlurSearch(event) {
    setTimeout(() => {
      if (
        (document.querySelector(".labels-discover") !== null) &
        (document.querySelector(".labels-discover") !== undefined)
      ) {
        document.querySelector(".labels-discover").classList.remove("visible");
      }
    }, 100);
  }

  refresh() {
    this._isMounted &&
      this.setState(
        { gridInitial: true, data: [], end: 0, visibleLoader: true },
        () => {
          document.querySelector(".discoverInput").value = "";
          this.componentDidMount();
        }
      );
  }

  pagination() {
    if (
      this.state.dataRandom !== undefined &&
      this.state.dataRandom.length > 0
    ) {
      const data = this.state.dataRandom;
      let length_array = data.length;
      if (this.state.end === length_array) {
        this._isMounted && this.setState({ hasMoreItems: false });
        return;
      }
      let new_array;
      if (length_array < this.state.end + 51) {
        new_array = data.slice(0, length_array);
        this._isMounted &&
          this.setState({ data: new_array, end: length_array });
      } else {
        new_array = data.slice(0, this.state.end + 51);
        this._isMounted &&
          this.setState({ data: new_array, end: this.state.end + 51 });
      }
    }
  }

  render() {
    return (
      <div className="Discover">
        {this.state.visibleLoader && (
          <div className="loader-discover">
            <Loader type="Oval" color="#000" height="50" width="50" />
          </div>
        )}
        <div className="container-fluid">
          <div className="row discover_margin align-items-center">
            <div className="col-12">
              <input
                className="form-control discoverInput"
                placeholder="Search by location, ocassion, style..."
                type="search"
                aria-label="Search"
                onChange={this.searchText}
                onBlur={this.handleBlurSearch}
              />
              <div className="dropdown-menu labels-discover">
                {this.state.searchQuery !== "undefined" && this.creatorLabels()}
              </div>
            </div>
          </div>
          <div className="container no-pd-container">
            <InfiniteScroll
              pageStart={0}
              loadMore={this.pagination.bind(this)}
              hasMore={this.state.hasMoreItems}
            >
              {this.state.gridInitial && (
                <div className="row">{this.createProdsGridInitial()}</div>
              )}
              {!this.state.gridInitial && (
                <div className="row">{this.createProdsGrid()}</div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

export default DiscoverPage;
