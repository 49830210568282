import React, { Component } from "react";
import "./causes.css";

class Causes extends Component {
  state = {};
  render() {
    return (
      <div className="Causes p-0">
        <div className="container">
          <div className="row text-center causes_title_container">
            <div className="col-12 my-2">
              <h1 className="causes_title closit_title">Sealed Offer on A Masterpiece</h1>
            </div>
            <div className="col-12">
              <p className='causes_subtitle'>
                Gary Nader is pleased to announce Sealed Offer on A Masterpiece, a unique approach to art offering great masterpieces for the very knowledgeable collectors.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Causes;
