
import React, { Component } from "react";
import * as firebase from "firebase/app";
import 'firebase/firestore';

class Inquire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            full_name: "",
            email: "",
            city_state: "",
            comment: "",
            product_data: undefined,
            message: ""

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendInquire = this.sendInquire.bind(this);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted && this.setState({ comment: "", full_name: "", email: "", city_state: "", product_data: undefined, message: "" });
        this._isMounted = false;
    }

    async sendInquire() {
        const { full_name, email, city_state, comment } = this.state;
        try {
            const makeInquire = firebase.functions().httpsCallable("makeInquire");
            await makeInquire({ full_name, email, city_state, comment, product_data: this.props.product });
            this.props.showResult("Your request has been sent successfully.");
            this.toggleModal();
        } catch (error) {
            this.props.showResult("An error has occurred please try again.");
            console.error(error);
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.sendInquire();
    }

    toggleModal() {
        this._isMounted && this.setState({ comment: "", full_name: "", email: "", city_state: "", product_data: undefined, message: "" });
        document.querySelector("#modal-review-close").click();
    }

    componentWillReceiveProps(props) {
        this._isMounted && this.setState({ comment: "", full_name: "", email: "", city_state: "", product_data: undefined, message: "" });
    }

    render() {
        return (
            <div>
                {!this.props.logged && (
                    <button className="btn custom btn-primary btn-sm" type="button" disabled={this.props.disabled} onClick={() => { this.props.showLoginModal() }}>{this.props.textBtn}</button>
                )}
                {this.props.logged && (
                    <button className="btn custom btn-primary btn-sm" type="button" data-toggle="modal" data-target="#Inquire" disabled={this.props.disabled}>
                        {this.props.textBtn}
                    </button>
                )}
                <div
                    className="modal fade"
                    id="Inquire"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="InquireTitle"
                    aria-hidden="true"
                >
                    <div className="container modal-dialog modal-dialog-centered" role="document" >
                        <div className="modal-content star-modal">
                            <button
                                type="button"
                                className="close x"
                                data-dismiss="modal"
                                aria-label="Close"
                                id="modal-review-close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="modal-header">
                                <div className="col-12 col-lg-12">
                                    <p className="modal-title star-title_container mt-3" id="exampleModalCenterTitle">Inquire Now</p>
                                </div>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="col-12">
                                        <input
                                            className="form-control mb-3 modal-input"
                                            name="name"
                                            type="text"
                                            placeholder="Full name"
                                            onChange={e => {
                                                this._isMounted && this.setState({ full_name: e.target.value });
                                            }}
                                            value={this.state.full_name}
                                            required
                                        />
                                    </div>
                                    <div className="col-12">
                                        <input
                                            className="form-control mb-3 modal-input"
                                            name="email"
                                            type="email"
                                            placeholder="Email"
                                            onChange={e => {
                                                this._isMounted && this.setState({ email: e.target.value });
                                            }}
                                            value={this.state.email}
                                            required
                                        />
                                        <p className="hidden error-text error-email">Please enter a valid email.</p>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            className="form-control mb-3 modal-input"
                                            name="city"
                                            type="text"
                                            placeholder="City, State"
                                            onChange={e => {
                                                this._isMounted && this.setState({ city_state: e.target.value });
                                            }}
                                            value={this.state.city_state}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <textarea
                                            className="form-control modal-input"
                                            placeholder="Comments"
                                            id="exampleFormControlTextarea0"
                                            rows="6"
                                            onChange={e => {
                                                this._isMounted && this.setState({ comment: e.target.value });
                                            }}
                                            value={this.state.comment}
                                            required
                                        />
                                    </div>

                                    <div className="col-12 center mt-4 mb-4">
                                        <button className="btn btn-secondary btn-menu btn-size" type="submit">Inquire</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Inquire;
