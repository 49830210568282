import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { auth, storage, dbFirestore } from "../../@firebase";
import { Redirect } from "react-router-dom";
import loadImage from "blueimp-load-image";
import Loader from "react-loader-spinner";
import "./account.css";
const uuidv4 = require("uuid/v4");

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      logged: false,
      redirect: false,
      image: "",
      previous_image: "",
      newImage: false,
      infoUser: {},
      visibleLoader: false,
    };
    this.handleNewImage = this.handleNewImage.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.deleteOldImage = this.deleteOldImage.bind(this);
    this.updatePhoto = this.updatePhoto.bind(this);
    this.signOut = this.signOut.bind(this);
    this._isMounted = false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    auth.onAuthStateChanged((user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({
            user: userLogged,
            logged: true,
            redirect: false,
          });
        dbFirestore
          .collection("users")
          .doc(userLogged.uid)
          .get()
          .then((snapshot) => {
            this._isMounted &&
              this.setState({
                infoUser: snapshot.data(),
                image: snapshot.data().photo,
                previous_image: snapshot.data().photo
              });
          });
      } else {
        this._isMounted &&
          this.setState({
            user: {},
            logged: false,
            redirect: true,
          });
      }
    });
  }

  handleNewImage(e) {
    const loadImageOptions = { canvas: true };
    const file = e.target.files[0];
    loadImage.parseMetaData(file, (data) => {
      if (data.exif && data.exif.get("Orientation")) {
        loadImageOptions.orientation = data.exif.get("Orientation");
      }
      loadImage(
        file,
        (canvas) => {
          this.setState(
            {
              image: canvas.toDataURL(file.type),
              newImage: true,
            },
            () => {
              this.updatePhoto();
            }
          );
        },
        loadImageOptions
      );
    });
  }

  uploadImage(file, type) {
    const name = `${uuidv4()}${type}`;
    return storage
      .ref("images")
      .child(name)
      .putString(file, "data_url")
      .then((snapshot) => snapshot.ref.getDownloadURL())
      .catch((error) => {
        throw new Error(error);
      });
  }

  async deleteOldImage() {
    if (this.state.previous_image) {
      var image = storage.refFromURL(this.state.previous_image);
      try {
        await image.delete();
        return true;
      } catch (error) {
        console.log(error);
      }
    }
  }

  async updatePhoto() {
    this._isMounted && this.setState({ visibleLoader: true });
    this.windowlock();
    try {
      let photo = await this.uploadImage(this.state.image, "-profile.jpg");
      await this.deleteOldImage();
      await dbFirestore
        .collection("users")
        .doc(this.state.infoUser.id)
        .update({ photo: photo });
      this.windowUnlock();
      this._isMounted &&
        this.setState({ visible: false, visibleLoader: false });
    } catch (error) {
      console.error("Error upload images", error);
      this.setState({ visibleLoader: false });
    }
  }

  windowlock() {
    if (this.state.visibleLoader === false) {
      document.querySelector("#root").classList.add("blockScroll");
    } else {
      document.querySelector("#root").classList.remove("blockScroll");
    }
  }

  windowUnlock() {
    if (this.state.visibleLoader) {
      document.querySelector("#root").classList.remove("blockScroll");
    } else {
      document.querySelector("#root").classList.add("blockScroll");
    }
  }

  signOut() {
    auth
      .signOut()
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
    this.setState({ user: {}, logged: false });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <div className="Account">
        {this.state.visibleLoader && (
          <div className="account_loader">
            <div className="loader-box">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        {this.state.redirect && <Redirect push to={"/"} />}
        <div className="container settings-width">
          <div className="row">
            <div className="col-lg-12 center no-pd">
              <div className="header-settings">
                {/* <div className="username_container">
                  <h1 className="username_welcome">
                    Welcome,
                    {this.state.username === "" && (
                      <span className="username"> {this.state.user.email}</span>
                    )}
                    {this.state.username !== "" && (
                      <span className="username"> {this.state.username}</span>
                    )}
                  </h1>
                </div>
                <div className="grid">
                  <figure className="effect-zoe">
                    {this.state.image !== "" && (
                      <img src={imageUrl} alt="Upload" className="image" />
                    )}
                    {!this.state.image !== "" && (
                      <img
                        src="/unknown-person.png"
                        alt="Upload"
                        className="image"
                      />
                    )}
                    <input
                      name="newImage"
                      type="file"
                      multiple
                      accept="image/*"
                      className="input-new-image"
                      onChange={this.handleNewImage}
                    />
                    <figcaption>
                      <p>Upload Image</p>
                      <input
                        name="newImage"
                        type="file"
                        multiple
                        accept="image/*"
                        className="input-new-image"
                        onChange={this.handleNewImage}
                      />
                      <span>
                        <i className="fas fa-camera" />
                      </span>
                    </figcaption>
                  </figure>
                </div> */}
              </div>

              <div className="container dropdowns_container pl-3 pr-3">
                <div className="accordion" id="accordionExample">
                  <div className="dropdown-border pointer">
                    <NavLink to="/settings/history">
                      <div id="headingOne">
                        <p className="title_collapse closit_title">
                          Orders and Return
                          <span className="collapse_icon">
                            <i className="fas fa-angle-right" />
                          </span>
                        </p>
                      </div>
                    </NavLink>
                  </div>

                  <div className="dropdown-border pointer">
                    <NavLink to="/settings/details">
                      <div id="headingTwo">
                        <p className="title_collapse closit_title">
                          Details & Password
                          <span className="collapse_icon">
                            <i className="fas fa-angle-right" />
                          </span>
                        </p>
                      </div>
                    </NavLink>
                  </div>

                  <div className="dropdown-border pointer">
                    <NavLink
                      to={{
                        pathname: "/address-book/",
                        state: { data: { checkout: false } }
                      }}
                    >
                      <div id="headingThree">
                        <p className="title_collapse closit_title">
                          Address Book
                          <span className="collapse_icon">
                            <i className="fas fa-angle-right" />
                          </span>
                        </p>
                      </div>
                    </NavLink>
                  </div>

                  <div className="dropdown-border pointer">
                    <NavLink
                      to={{
                        pathname: "/cards/",
                        state: { data: { checkout: false } }
                      }}
                    >
                      <div id="headingFourth">
                        <p className="title_collapse closit_title">
                          Payment Method
                          <span className="collapse_icon">
                            <i className="fas fa-angle-right" />
                          </span>
                        </p>
                      </div>
                    </NavLink>
                  </div>

                  <div className="dropdown-border pointer">
                    <div id="headingFifth">
                      <p
                        className="title_collapse closit_title"
                        onClick={this.signOut}
                      >
                        Sign Out
                        <span className="collapse_icon">
                          <i className="fas fa-angle-right" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
