import React, { Component } from "react";

class SuccessCreateAccount extends Component {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="row align-items-center justify-content-center text-center">
          <p
            className="notify-title mb-0"
            style={{
              color: "#fff",
            }}
          >
            Account created successfully{" "}
            <i className="fas fa-check-circle green" />
          </p>
        </div>
      </div>
    );
  }
}

export default SuccessCreateAccount;
