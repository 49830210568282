import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Redirect } from "react-router-dom";
import "./viewing-room-lot-detail.css";
import LoginForm from "../../@components/login";
import SimilarLots from "../../@components/similar-lots";
import ShareModal from "../../@components/share-modal";
import CountDownSan from "../../@components/countdown";
import { metasTagsLots, formatNumber } from "../../#constants";
import InquirePrivateSell from "../../@components/inquire-private-sell";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class PrivateArtworkDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableQuantity: 1,
      data: {
        title: "",
        estimatedPrice: 0,
        description: "<p></p>",
        minimiumPrice: 0,
      },
      redirect404: false,
      logged: false,
      bidSelected: "",
      formVisible: false,
      timed: true,
      active: false,
      messageButton: "Bid",
      redirect: false,
      bid: true,
      existWishlistLots: false,
      wishlistLots: [],
      modalShare: false,
      redirectAuctions: false,
      auction: {},
      message_alert: "",
    };
    this.makeBid = this.makeBid.bind(this);
    this.handleChangeBids = this.handleChangeBids.bind(this);
    this.terminate = this.terminate.bind(this);
    this.addToWishlist = this.addToWishlist.bind(this);
    this.removeToWishlist = this.removeToWishlist.bind(this);
    this.init = this.init.bind(this);

    this._isMounted = false;
    this._completed = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    const lotId = this.props.match.params.id;

    auth.onAuthStateChanged(async (user) => {
      var logged;
      if (user) logged = true;
      else {
        logged = false;
      }
      this._isMounted && this.setState({ logged: logged, user: user });

      if (logged) {
        const userLogged = await dbFirestore
          .collection("users")
          .doc(user.uid)
          .get();
        var wishlist = userLogged.data().wishlist_lots;
        var existWishlistLots;
        if (wishlist.indexOf(lotId) >= 0) existWishlistLots = true;
        else existWishlistLots = false;

        this._isMounted &&
          this.setState({
            existWishlistLots: existWishlistLots,
            wishlistLots: userLogged.data().wishlist_lots,
          });
      }
      this.init();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== null && nextProps !== undefined) {
      this.props = nextProps;
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async init() {
    //normal flow user enter to lot detail
    const lotId = this.props.match.params.id;
    let lot = null;

    try {
      let snapshot = await dbFirestore
        .collection("private_artwork")
        .doc(lotId)
        .get();
      if (!snapshot.exists || !snapshot.data().publish) {
        this._isMounted && this.setState({ redirect404: true });
      }

      lot = snapshot.data();
      //meta datos
      metasTagsLots.setMetaTags(lot);
      //end meta datos
      let photo = [];
      photo.push({ principalImage: snapshot.data().principalImage });

      this._isMounted && this.setState({ photo: photo, data: snapshot.data() });

      //Se generan los bids correspodientes según los datos de la subasta
      // this.generarBids(parseInt(snapshot.data().minimiumPrice, 10), parseInt(snapshot.data().maximiumPrice, 10), parseInt(snapshot.data().bidInterval, 10), snapshot.data().bidAmount);

      let snapshotAuction = await dbFirestore
        .collection("private_sell")
        .doc(snapshot.data().auction)
        .get();
      if (!snapshotAuction.exists) {
        this._isMounted && this.setState({ redirect404: true });
      }

      const auction = snapshotAuction.data();
      let initDay = new Date(auction.start.seconds * 1000).getTime();
      let today = new Date().getTime();
      var timed = true;
      if (initDay < today) {
        timed = false;
      }

      this._isMounted && this.setState({ auction: auction, timed: timed });

      let seconds = Math.round(new Date().getTime() / 1000);
      let end = auction.end.seconds;
      let start = auction.start.seconds;
      this._isMounted && this.setState({ end: end, start: start });

      if (end < seconds) {
        if (this.state.logged) {
          if (this.state.user.uid === lot.userWin) {
            this._isMounted &&
              this.setState({ messageButton: "Thank you for your bid" });
          } else {
            this._isMounted && this.setState({ messageButton: "Sold" });
          }
        } else {
          this._isMounted && this.setState({ messageButton: "Sold" });
        }
        //Se setea la que la subasta no está activa y que no está en timed.
        this._isMounted && this.setState({ active: false, timed: false });
      } else {
        if (start < seconds) {
          if (this.state.logged) {
            if (this.state.user.uid === lot.userWin) {
              this._isMounted &&
                this.setState({
                  messageButton: "Thank you for your bid",
                  active: false,
                });
            } else {
              this._isMounted &&
                this.setState({ active: true, messageButton: "Bid" });
            }
            this._isMounted && this.setState({ timed: false });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  userBided() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.data.bid && this.state.bid) {
        this._isMounted && this.setState({ bid: false });
        let bids_users = this.state.data.bids_users;

        if (bids_users[bids_users.length - 1] === this.state.user.uid) {
          return;
        }

        bids_users.push(this.state.user.uid);
        dbFirestore
          .collection("private_artwork")
          .doc(this.state.data.id)
          .update({
            userWin: this.state.user.uid,
            bidAmount: this.props.location.state.data.bidSelected,
            bids_users: bids_users,
          })
          .catch((err) => console.error(err));
      }
    }
  }

  createHeaderTimed() {
    if (
      this.state.auction.end !== undefined &&
      this.state.auction.start !== undefined
    ) {
      let header = [];
      //auction timed
      header.push(
        <div
          className="row align-center align-items-center mt-3 header-lots"
          key="row-header"
        >
          <div className="col-12 col-md-6 mb-2">
            <p className="lot_detail_title closit_text">
              {this.state.data.title}
            </p>
          </div>
          <div className="col-12 col-md-6">
            {/*<Countdown date={new Date(this.state.auction.start.seconds * 1000).getTime()} renderer={this.renderer}/>*/}
            <CountDownSan
              date={new Date(
                this.state.auction.start.seconds * 1000
              ).toString()}
              className="CountDown"
              onEnd={() => {
                this.terminate();
              }}
            />
          </div>
        </div>
      );
      return header;
    }
  }

  createHeaderLived() {
    if (
      this.state.auction.end !== undefined &&
      this.state.auction.start !== undefined
    ) {
      let header = [];

      //auction live
      header.push(
        <div
          className="row align-center align-items-center mt-3 header-lots"
          key="row-header"
        >
          <div className="col-12 col-md-6 mb-2">
            <p className="lot_detail_title closit_text">
              {this.state.data.title}
            </p>
          </div>
          <div className="col-12 col-md-6">
            {/*<Countdown date={new Date(this.state.auction.end.seconds * 1000).getTime()} renderer={this.renderer}/>*/}
            <CountDownSan
              date={new Date(this.state.auction.end.seconds * 1000).toString()}
              className="CountDown"
            />
          </div>
        </div>
      );

      return header;
    }
  }

  createInfoBoxLot() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.data !== null && this.state.data !== undefined) {
        let infoBox = [];
        infoBox.push(
          <div className="information-box-lot" key="info-box-lot">
            <h1 className="lot_title closit_text">{this.state.data.title}</h1>
            {this.state.data.show_price && (
              <p className="lot-description">
                Estimated: ${formatNumber(this.state.data.estimatedPrice)}
              </p>
            )}
          </div>
        );
        return infoBox;
      }
    }
  }

  createDescriptionLot() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.data !== null && this.state.data !== undefined) {
        let description = [];
        description.push(
          <div className="row" key="description-box">
            <div className="col-12 ">
              <p className="lot_detail_title closit_text mb-2">
                {this.state.data.title}
              </p>
              <hr className="auction_separator my-0 mb-2" />
              <div
                className="lot-detail-description pars-str"
                dangerouslySetInnerHTML={{
                  __html: this.state.data.description,
                }}
              />
            </div>
          </div>
        );
        return description;
      }
    }
  }

  createOptionsLot() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.bids !== null && this.state.bids !== undefined) {
        let options = [];
        for (let index = 0; index < this.state.bids.length; index++) {
          options.push(
            <option
              value={this.state.bids[index].value}
              key={"op-lot-" + index}
            >
              {this.state.bids[index].label}
            </option>
          );
        }
        return options;
      }
    }
  }

  generarBids = (min, max, porcentaje, bidAmount) => {
    bidAmount = Number(bidAmount);
    let bids = [];
    let bid;

    if (bidAmount === 0) bid = min;
    else bid = bidAmount;

    do {
      if (bid < 1000) bid += 50;
      else if (bid < 2000) bid += 100;
      else if (bid < 5000) bid += 250;
      else if (bid < 10000) bid += 500;
      else if (bid < 20000) bid += 1000;
      else if (bid < 50000) bid += 2000;
      else if (bid < 100000) bid += 5000;
      else if (bid >= 100000) bid += 10000;
      bids.push({ label: "$ " + formatNumber(bid.toString()), value: bid });
    } while (bid <= max);

    this._isMounted && this.setState({ bids: bids });
  };

  makeBid() {
    if (this.state.logged) {
      if (this.state.bidSelected === "") {
        //notify has need select a bid
        document.getElementById("lot-bid").classList.add("error");
        document.querySelector(".lot-error-text").classList.add("visible");
      } else {
        //do the bid redirect to confirm
        this._isMounted && this.setState({ redirect: true });
      }
    } else {
      //show login
      this._isMounted && this.setState({ formVisible: true });
    }
  }

  handleChangeBids(e) {
    document.getElementById("lot-bid").classList.remove("error");
    document.querySelector(".lot-error-text").classList.remove("visible");
    this._isMounted && this.setState({ bidSelected: e.target.value });
  }

  displayForm() {
    this._isMounted && this.setState({ formVisible: true, formType: "login" });
  }

  close() {
    this._isMounted && this.setState({ formVisible: false });
  }

  terminate() {
    if (this.state.timed) {
      this._isMounted && this.setState({ timed: false });
    } else {
      if (this.state.logged) {
        if (this.state.user.uid === this.state.data.userWin) {
          this._isMounted &&
            this.setState({ messageButton: "Thank you for your bid" });
        } else {
          this._isMounted && this.setState({ messageButton: "Sold" });
        }
      } else {
        this._isMounted && this.setState({ messageButton: "Sold" });
      }
      //Se setea la que la subasta no está activa
      this._isMounted && this.setState({ active: false });
    }
  }

  async addToWishlist() {
    const lotId = this.props.match.params.id;
    if (this.state.logged) {
      var wishlist_lots = this.state.wishlistLots;
      wishlist_lots.push(lotId);
      try {
        await dbFirestore
          .collection("users")
          .doc(this.state.user.uid)
          .update({ wishlist_lots: wishlist_lots });
        this._isMounted &&
          this.setState({
            existWishlistLots: true,
            wishlistLots: wishlist_lots,
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      this._isMounted && this.setState({ formVisible: true });
    }
  }

  async removeToWishlist() {
    const lotId = this.props.match.params.id;
    let newWishlist = this.state.wishlistLots.filter(
      (value) => value !== lotId
    );
    try {
      await dbFirestore
        .collection("users")
        .doc(this.state.user.uid)
        .update({ wishlist_lots: newWishlist });
      this._isMounted &&
        this.setState({ existWishlistLots: false, wishlistLots: newWishlist });
    } catch (error) {
      console.log(error);
    }
  }

  showResult(message) {
    this._isMounted &&
      this.setState({ message_alert: message }, () => {
        this.showNotification();
      });
  }

  showNotification() {
    toast.info(
      <div>
        <p className="notify-text">{this.state.message_alert}</p>
      </div>,
      {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "notify",
        bodyClassName: "notify-body",
      }
    );
  }

  render() {
    if (this.state.logged === false) {
      return (
        <div className="Auctions">
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "block",
            }}
          >
            <p>Sorry, You have to Log in or Sign up</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="DetailLotPage">
          {this.state.redirect404 && <Redirect push to={"/not-found"} />}
          {this.state.redirectAuctions && (
            <Redirect push to={"/private-sale"} />
          )}

          <div className="container">
            {this.state.timed && this.createHeaderTimed()}
            {!this.state.timed && this.createHeaderLived()}
            <hr className="auction_separator" />
            <div className="row align-items-center mb-3">
              <div className="col-12 col-lg-6 center">
                <img
                  className="main_lot_img"
                  src={this.state.data.principalImage}
                  alt={this.state.data.title}
                />
                <div className="lot_icon_container text-center mt-3">
                  {!this.state.existWishlistLots && (
                    <a
                      className="add-heart col-4"
                      onClick={() => {
                        this.addToWishlist();
                      }}
                    >
                      <i className="far fa-heart" />
                    </a>
                  )}
                  {this.state.existWishlistLots && (
                    <a
                      className="remove-heart col-4"
                      onClick={() => {
                        this.removeToWishlist();
                      }}
                    >
                      <i className="fas fa-heart" />
                    </a>
                  )}
                  <a
                    className="share-bag col-4"
                    data-toggle="modal"
                    data-target="#shareModal"
                  >
                    <i className="fas fa-share-square" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                {this.createDescriptionLot()}
                {this.state.data.show_price ? (
                  this.createInfoBoxLot()
                ) : (
                  <div className="information-box-lot" key="info-box-lot">
                    <h1 className="lot_title closit_text">
                      {this.state.data.title}
                    </h1>
                  </div>
                )}

                <div className="lot_bid_container">
                  <div className="btn_container center">
                    <InquirePrivateSell
                      textBtn={!this.state.data.sold > 0 ? "Inquire" : "Sold"}
                      disabled={this.state.data.sold}
                      logged={this.state.logged}
                      showLoginModal={this.displayForm.bind(this)}
                      lot={this.state.data}
                      showResult={this.showResult.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container lot-description-area">
            <SimilarLots lot={this.props.match.params.id} />
          </div>
          {/*MODAL */}
          <ShareModal shareLink={window.location.href} />
          {/* END MODAL */}

          {this.state.redirect && (
            <Redirect
              push
              to={{
                pathname: "/private_artwork",
                state: {
                  data: {
                    auction: this.state.auction,
                    bidSelected: this.state.bidSelected,
                    id: this.state.data.id,
                  },
                },
              }}
            />
          )}
          {this.state.formVisible && (
            <LoginForm typeForm="login" close={this.close.bind(this)} />
          )}
        </div>
      );
    }
  }
}

export default PrivateArtworkDetailPage;
