import React, { Component } from "react";
import "./sort.css";

class Sort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleI: true,
      visibleG: true,
      visibleD: true,
    };
    this.verifyDisplay = this.verifyDisplay.bind(this);
    this.findMetadata = this.findMetadata.bind(this);
    this._isMounted = false;
  }

  verifyDisplay() {
    if (this.props.general.length > 0) {
      this.setState({ visibleG: true });
    } else {
      this.setState({ visibleG: false });
    }

    if (this.props.influencer.length > 0) {
      this.setState({ visibleI: true });
    } else {
      this.setState({ visibleI: false });
    }

    if (this.props.designer.length > 0) {
      this.setState({ visibleD: true });
    } else {
      this.setState({ visibleD: false });
    }
  }

  findMetadata() {
    this.props.findMetadata();
  }

  orderBy(value) {
    this.props.orderBy(value);
  }

  setOrders(x, y, z) {
    this.props.setOrders(x, y, z);
  }

  setMinorPriceRange(val) {
    this.props.setMinorPriceRange(val);
  }

  setMaxPriceRange(val) {
    this.props.setMaxPriceRange(val);
  }

  filterByPrice() {
    this.props.filterByPrice();
    this.toggleModal();
  }

  toggleModal() {
    document.querySelector("#modal-review-close").click();
  }

  filterByCategory(typeOf, id) {
    this.props.filterByCategorys(typeOf, id);
    this.toggleModal();
  }

  filterByColor(color) {
    this.props.filterByColor(color);
    this.toggleModal();
  }

  createColors() {
    if (this.props.colors.length > 0) {
      let colors = [];
      this.props.colors.forEach((element, index) => {
        colors.push(
          <li key={"colors-" + index} className="hoverable">
            {element}
            <input
              type="radio"
              className="radio-list"
              name="radAnswer"
              onChange={() => {
                this.filterByColor(element);
              }}
            />
          </li>
        );
      });
      return colors;
    }
  }

  createInfluencers() {
    if (this.props.influencer.length > 0) {
      let influencers = [];
      this.props.influencer.forEach((element, index) => {
        influencers.push(
          <li key={"influencer-" + index} className="hoverable">
            {element.title}
            <input
              type="radio"
              className="radio-list"
              name="radAnswer"
              onChange={() => {
                this.filterByCategory(element.typeOf, element.id);
              }}
            />
          </li>
        );
      });
      return influencers;
    }
  }

  createDesigners() {
    if (this.props.designer.length > 0) {
      let designers = [];
      this.props.designer.forEach((element, index) => {
        designers.push(
          <li key={"designer-" + index} className="hoverable">
            {element.title}
            <input
              type="radio"
              className="radio-list"
              name="radAnswer"
              onChange={() => {
                this.filterByCategory(element.typeOf, element.id);
              }}
            />
          </li>
        );
      });
      return designers;
    }
  }

  createGenerals() {
    if (this.props.general.length > 0) {
      let generals = [];
      this.props.general.forEach((element, index) => {
        generals.push(
          <li key={"general-" + index} className="hoverable">
            {element.title}
            <input
              type="radio"
              className="radio-list"
              name="radAnswer"
              onChange={() => {
                this.filterByCategory(element.typeOf, element.id);
              }}
            />
          </li>
        );
      });
      return generals;
    }
  }

  createSubCategory() {
    if (this.props.subcategorys.length > 0) {
      let subcategorys = [];
      this.props.subcategorys.forEach((element, index) => {
        subcategorys.push(
          <li key={"sub-general-" + index} className="hoverable">
            {element}
            <input
              type="radio"
              className="radio-list"
              name="radAnswer"
              onChange={() => {
                this.filterByCategory("sub_general", element);
              }}
            />
          </li>
        );
      });
      return subcategorys;
    }
  }

  render() {
    return (
      <div className="col-12 center sort_bg mb-3 no-pd">
        <button
          className="btn btn-secondary btn-menu btn-size sort_btn mb-3"
          type="button"
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          Sort & Filters
          <span className="collapse_icon">
            <i className="fas fa-sliders-h" />
          </span>
        </button>

        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="container modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content sort-modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="close sort_x"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="modal-review-close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="col-12 col-lg-12">
                  <p
                    className="modal-title star-title_container mt-3"
                    id="exampleModalCenterTitle"
                  >
                    Refine
                  </p>
                </div>
                <a
                  className="modal-title clear-filters"
                  onClick={(e) => {
                    this.findMetadata();
                    document.querySelector("#clear-f").click();
                    this.toggleModal();
                  }}
                >
                  Clear filters
                </a>
                <input
                  id="clear-f"
                  type="radio"
                  className="hidden d-none"
                  name="radAnswer"
                />
              </div>

              <div className="modal-body">
                <div className="sort_row mb-3">
                  <div className="col-12 mb-3">
                    <p className="sort_title closit_text">Sort By</p>
                  </div>
                  <div>
                    <div className="row justify-content-between sort-w mb-3">
                      <div>
                        <label className="sort_text mr-2" htmlFor="">
                          New Items
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="sort_radio"
                          name="radAnswer"
                          onChange={(e) => {
                            this.orderBy("newitems");
                            this.setOrders(true, false, false);
                            this.toggleModal();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row justify-content-between sort-w mb-3">
                      <div>
                        <label className="sort_text mr-2" htmlFor="">
                          Price (hight first)
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="sort_radio"
                          name="radAnswer"
                          onChange={(e) => {
                            this.orderBy("priceHight");
                            this.setOrders(false, true, false);
                            this.toggleModal();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row justify-content-between sort-w mb-3">
                      <div>
                        <label className="sort_text mr-2" htmlFor="">
                          Price (low first)
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="sort_radio"
                          name="radAnswer"
                          onChange={(e) => {
                            this.orderBy("priceLow");
                            this.setOrders(false, false, true);
                            this.toggleModal();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="filter_row">
                  <div className="col-12 mb-3">
                    <p className="sort_title closit_text">Filter By</p>
                  </div>

                  <div className="accordion filter-w" id="accordionExample">
                    {this.props.influencer.length > 0 && (
                      <div className="filter-border">
                        <div id="headingOne ">
                          <p
                            className="title_collapse"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Influencer
                            <span className="collapse_icon">
                              <i className="fas fa-chevron-down" />
                            </span>
                          </p>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-12 no-pd">
                                <div className="product_description ">
                                  <ul className="text-l list">
                                    {this.createInfluencers()}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {this.props.general.length > 0 && (
                      <div className="filter-border">
                        <div id="headingTwo">
                          <p
                            className="title_collapse"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Category
                            <span className="collapse_icon">
                              <i className="fas fa-chevron-down" />
                            </span>
                          </p>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-12 no-pd">
                                <div className="product_description">
                                  <ul className="text-l list">
                                    {this.createGenerals()}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {this.props.designer.length > 0 && (
                      <div className="filter-border">
                        <div id="headingThree">
                          <p
                            className="title_collapse"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Designer
                            <span className="collapse_icon">
                              <i className="fas fa-chevron-down" />
                            </span>
                          </p>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-12 no-pd">
                                <div className="product_description">
                                  <ul className="text-l list">
                                    {this.createDesigners()}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="filter-border">
                      <div id="headingFourth">
                        <p
                          className="title_collapse"
                          data-toggle="collapse"
                          data-target="#collapseFourth"
                          aria-expanded="false"
                          aria-controls="collapseFourth"
                        >
                          Price Range
                          <span className="collapse_icon">
                            <i className="fas fa-chevron-down" />
                          </span>
                        </p>
                      </div>
                      <div
                        id="collapseFourth"
                        className="collapse"
                        aria-labelledby="headingFourth"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="product_description range-price">
                                <div className="col-lg-12">
                                  <input
                                    type="number"
                                    name="minorPrice"
                                    placeholder="Minor Price*"
                                    onChange={(minor) => {
                                      this.setMinorPriceRange(
                                        parseInt(minor.target.value, 10)
                                      );
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <input
                                    type="number"
                                    name="maxPrice"
                                    placeholder="Max  Price*"
                                    onChange={(max) => {
                                      this.setMaxPriceRange(
                                        parseInt(max.target.value, 10)
                                      );
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-lg-12">
                                  <button
                                    className="btn btn-secondary btn-menu"
                                    onClick={() => {
                                      this.filterByPrice();
                                    }}
                                  >
                                    Show
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {this.props.colors.length > 0 && (
                      <div className="filter-border">
                        <div id="headingFith">
                          <p
                            className="title_collapse"
                            data-toggle="collapse"
                            data-target="#collapseFith"
                            aria-expanded="false"
                            aria-controls="collapseFith"
                          >
                            Colors
                            <span className="collapse_icon">
                              <i className="fas fa-chevron-down" />
                            </span>
                          </p>
                        </div>
                        <div
                          id="collapseFith"
                          className="collapse"
                          aria-labelledby="headingFith"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="product_description">
                                  <ul className="text-l list">
                                    {this.createColors()}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}

                    {/* {this.props.subcategorys.length > 0 && (
                      <div className="filter-border">
                        <div id="headingSix">
                          <p
                            className="title_collapse"
                            data-toggle="collapse"
                            data-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            Sub Category
                            <span className="collapse_icon">
                              <i className="fas fa-chevron-down" />
                            </span>
                          </p>
                        </div>
                        <div
                          id="collapseSix"
                          className="collapse"
                          aria-labelledby="headingSix"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="product_description">
                                  <ul className="text-l list">
                                    {this.createSubCategory()}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sort;
