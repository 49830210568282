//Dependencies
import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import "./slider.css";
import Loader from "react-loader-spinner";

const orientation = ["topl", "topr", "botl", "botr"];

class Slider extends Component {
  constructor() {
    super();
    this.state = {
      category: "",
      idCategory: "",
      prevent: false,
      redirect: false,
      data: [],
      dataCategorys: [],
      redirect404: false,
      visibleLoader: true,
    };
    this.CarouselSwipe = this.CarouselSwipe.bind(this);
    this.squareImageMobile = this.squareImageMobile.bind(this);
    this.init = this.init.bind(this);
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.init();
  }

  async init() {
    try {
      let snapshot = await dbFirestore
        .collection("sliders")
        .where("publish", "==", true)
        .orderBy("updatedAt", "desc")
        .limit(9)
        .get();
      let data = [];
      let slidesData = [];
      let i = 0;
      //Get images and text
      snapshot.forEach((doc) => {
        slidesData.push({ data: doc.data() });
      });
      this._isMounted &&
        this.setState({ data: slidesData, visibleLoader: false });

      //Get the data to redirect to categorys
      snapshot.forEach(async (doc) => {
        let id = doc.data().linkTo.id;
        let name = doc.data().linkTo.name;
        let response = await dbFirestore.collection(name).doc(id).get();
        if (response.exists) {
          data.push({
            dataCategoryOrProduct: response.data(),
            i: i,
            redirect404: false,
          });
          i++;
        } else {
          data.push({
            dataCategoryOrProduct: response.data(),
            i: i,
            redirect404: true,
          });
        }
      });
      this._isMounted && this.setState({ dataCategorys: data });

      console.log(this.state.dataCategorys, "data");
    } catch (error) {
      console.log(error);
    }

    this.CarouselSwipe();
    this.squareImageMobile();
    $(window).resize(() => {
      this.squareImageMobile();
    });
  }

  createDots = () => {
    if (this.state !== null && this.state !== undefined) {
      let dots = [];
      for (let i = 0; i < this.state.data.length; i++) {
        if (i === 0)
          dots.push(
            <li
              data-target="#carousel-closit-main"
              data-slide-to="0"
              className="active"
              key={"dot-carousel-main" + i}
            />
          );
        else
          dots.push(
            <li
              data-target="#carousel-closit-main"
              data-slide-to={i}
              key={"dot-carousel-main" + i}
            />
          );
      }
      return dots;
    }
  };

  createSlide() {
    if (this.state.data.length > 0) {
      let slides = [];
      for (let i = 0; i < this.state.data.length; i++) {
        if (i === 0)
          slides.push(
            <div
              className="carousel-item active"
              key={"carousel-item-main" + i}
            >
              <div
                className={
                  "slider-title closit_title " +
                  orientation[this.state.data[i].data.orientationText]
                }
              >
                <h1>{this.state.data[i].data.title}</h1>
                <h1>{this.state.data[i].data.subtitle}</h1>
              </div>

              <img
                className="w-100 img-desk"
                src={this.state.data[i].data.principalImageWeb}
                onClickCapture={(e) => {
                  this.handleClick(e, i);
                }}
                alt={this.state.data[i].data.title}
                data-holder-rendered={true}
              />
              {/*for mobile */}
              <img
                className="w-100 img-mobile"
                src={this.state.data[i].data.principalImage}
                onClickCapture={(e) => {
                  this.handleClick(e, i);
                }}
                alt={this.state.data[i].data.title}
                data-holder-rendered={true}
              />
            </div>
          );
        else
          slides.push(
            <div className="carousel-item" key={"carousel-item-main" + i}>
              <div
                className={
                  "slider-title closit_title " +
                  orientation[this.state.data[i].data.orientationText]
                }
              >
                <h1>{this.state.data[i].data.title}</h1>
                <h1>{this.state.data[i].data.subtitle}</h1>
              </div>

              <img
                className="w-100 img-desk"
                src={this.state.data[i].data.principalImageWeb}
                onClickCapture={(e) => {
                  this.handleClick(e, i);
                }}
                alt={this.state.data[i].data.title}
                data-holder-rendered={true}
              />

              {/*for mobile */}
              <img
                className="w-100 img-mobile"
                src={this.state.data[i].data.principalImage}
                onClickCapture={(e) => {
                  this.handleClick(e, i);
                }}
                alt={this.state.data[i].data.title}
                data-holder-rendered={true}
              />
            </div>
          );
      }
      return slides;
    }
  }

  handleClick(e, index) {
    if (this.state.prevent) {
      //DO nothing is swap
      this._isMounted && this.setState({ prevent: false });
    } else {
      //redirect to category
      if (!this.state.dataCategorys[index].redirect404) {
        let category = this.state.dataCategorys[index].dataCategoryOrProduct
          .typeOf;
        let idCategory = this.state.dataCategorys[index].dataCategoryOrProduct
          .id;
        console.log(category);
        this._isMounted &&
          this.setState({
            category: category,
            idCategory: idCategory,
            prevent: false,
            redirect: true,
          });
      } else {
        this._isMounted && this.setState({ redirect404: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  CarouselSwipe() {
    var self = this;
    $("#carousel-closit-main").swipe({
      swipe: function (event, direction) {
        event.preventDefault();
        if (direction === "left") $(this).carousel("next");
        if (direction === "right") $(this).carousel("prev");
        self.setState({ prevent: true });
      },
      allowPageScroll: "vertical",
      threshold: 100,
    });
  }

  squareImageMobile() {
    var $widthWindow = window.innerWidth;
    var $width = $("#carousel-closit-main").width();
    if ($widthWindow < 768) {
      $("#carousel-closit-main").height($width);
    } else {
      $("#carousel-closit-main").height(540);
    }
  }

  render() {
    return (
      <div
        id="carousel-closit-main"
        className="carousel slide"
        data-ride="carousel"
      >
        {this.state.visibleLoader && (
          <div className="loader-slider-area">
            <div className="loader-slider">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        <ol className="carousel-indicators">{this.createDots()}</ol>
        <div className="carousel-inner">{this.createSlide()}</div>
        {this.state.redirect && (
          <Redirect
            push
            to={
              "/categories/" + this.state.category + "/" + this.state.idCategory
            }
          />
        )}
        {this.state.category === "sealed-offer" && (
          <Redirect
            push
            to={"/" + this.state.category + "/" + this.state.idCategory}
          />
        )}
        {this.state.redirect404 && <Redirect push to={"/not-found"} />}
      </div>
    );
  }
}

export default Slider;
