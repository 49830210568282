import React, { Component } from "react";
import "./success.css";

class Success extends Component {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <p className="notify-title mb-0">
            Login Successful <i className="fas fa-check-circle green" />
          </p>
        </div>
      </div>
    );
  }
}

export default Success;
