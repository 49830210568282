import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Link } from "react-router-dom";
import "./category.css";
import Loader from "react-loader-spinner";
import LoginForm from "../login";
import {
  ResponsiveEllipsis,
  formatNumber,
  calculateNewPrice,
} from "../../#constants";
import LazyLoad from "react-lazyload";

class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dataCategory: {},
      visibleLoader: true,
      infoUser: {},
      logged: false,
      user: {},
      wishlist: [],
      formVisible: false,
      formType: "",
      hasProducts: true,
    };
    this.existWishList = this.existWishList.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.gotoWishlistItem = this.gotoWishlistItem.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
    this.close = this.close.bind(this);
    this.displayForm = this.displayForm.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getProducts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getProducts() {
    await this.checkLogin();
    const typeOf = this.props.categoryType;
    const maxPost = this.props.postSize;
    let snapshot = await dbFirestore
      .collection("categorys")
      .where("publish", "==", true)
      .where("typeOf", "==", typeOf)
      .orderBy("updatedAt", "desc")
      .limit(maxPost)
      .get();

    let dataSource = [],
      dataCategory = {},
      hasProducts = true;

    if (snapshot.docs.length <= this.props.index) {
      // No hay una categoria para mostrar con el indice pasado
      this._isMounted &&
        this.setState({
          data: dataSource,
          dataCategory: dataCategory,
          visibleLoader: false,
          hasProducts: false,
        });
    } else {
      dataCategory = snapshot.docs[this.props.index].data();
      let result = await dbFirestore
        .collection("products")
        .where("publish", "==", true)
        .where("linkTo." + typeOf, "==", dataCategory.id)
        .orderBy("updatedAt", "desc")
        .limit(maxPost)
        .get();
      result.forEach((doc) => {
        dataSource.push(doc.data());
      });
      if (dataSource.length === 0) {
        hasProducts = false;
      }

      this._isMounted &&
        this.setState({
          data: dataSource,
          dataCategory: dataCategory,
          visibleLoader: false,
          hasProducts: hasProducts,
        });
    }
  }

  checkLogin = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted && this.setState({ user: userLogged });
        let snapshot = await dbFirestore
          .collection("users")
          .doc(user.uid)
          .get();
        this._isMounted &&
          this.setState({
            infoUser: snapshot.data(),
            logged: true,
            wishlist: snapshot.data().wishlist,
          });
      } else {
        this._isMounted &&
          this.setState({ user: {}, logged: false, infoUser: {} });
      }
    });
  };

  async refreshWish() {
    let snapshot = await dbFirestore
      .collection("users")
      .doc(this.state.user.uid)
      .get();
    this._isMounted &&
      this.setState({
        infoUser: snapshot.data(),
        wishlist: snapshot.data().wishlist,
      });
  }

  existWishList(id) {
    let exist = this.state.wishlist.filter((value) => value === id);
    if (exist.length === 1) {
      return true;
    }
    return false;
  }

  async gotoWishlistItem(id, action) {
    if (this.state.logged) {
      let newDataWishlist = this.state.wishlist;
      if (action) {
        newDataWishlist.push(id);
        try {
          await dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist: newDataWishlist });
          this._isMounted &&
            this.setState({ data: this.state.data, wishlist: newDataWishlist });
          this.props.refresh();
        } catch (error) {
          console.error("Error ", error);
        }
      } else {
        //remove item
        let data = newDataWishlist.filter((value) => value !== id);
        try {
          await dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist: data });
          this._isMounted &&
            this.setState({ data: this.state.data, wishlist: data });
          this.props.refresh();
        } catch (error) {
          console.error("Error ", error);
        }
      }
    } else {
      //go to login
      this._isMounted &&
        this.setState({ formVisible: true, formType: "login" });
    }
  }

  createCategoryProducts = () => {
    if (this.state.data.length > 0) {
      let category = [];
      for (let i = 0; i < this.state.data.length; i++) {
        category.push(
          <LazyLoad
            height={320}
            offset={200}
            once
            resize
            key={this.state.data[i].title + "-" + i}
          >
            <div className="col-6 col-md-4 col-lg-2">
              <div className="card card-category">
                {this.state.data[i].sale && (
                  <div className="forSale-box">
                    <p>For Sale</p>
                  </div>
                )}
                {/* <div className="wishlist-box">
                {!this.state.logged && (
                  <i
                    className=" far fa-heart"
                    onClick={() => this.displayForm("login")}
                  />
                )}

                {this.state.logged &&
                  !this.existWishList(this.state.data[i].id) && (
                    <i
                      className=" far fa-heart"
                      onClick={e => {
                        this.gotoWishlistItem(this.state.data[i].id, 1);
                      }}
                    />
                  )}

                {this.state.logged &&
                  this.existWishList(this.state.data[i].id) && (
                    <i
                      className=" fas fa-heart"
                      onClick={e => {
                        this.gotoWishlistItem(this.state.data[i].id, 0);
                      }}
                    />
                  )}
              </div> */}
                <Link
                  to={"/product/" + this.state.data[i].id}
                  className="special_height"
                >
                  <img
                    className="card-category-img"
                    src={this.state.data[i].principalImage}
                    alt={this.state.data[i].title}
                  />
                </Link>
                <div className="card-body card-category-body">
                  <Link to={"/product/" + this.state.data[i].id}>
                    <ResponsiveEllipsis
                      className="card-title card-category-title closit_text three-lines-titles"
                      text={this.state.data[i].title}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </Link>

                  <ResponsiveEllipsis
                    className="card-text card-category-text"
                    text={this.state.data[i].shortDescription}
                    maxLine="4"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />

                  {this.state.data[i].sale && (
                    <div className="card-text card-category-price">
                      {this.state.data[i].sizes[0].price === undefined && <p />}
                      {this.state.data[i].sizes[0].price !== undefined && (
                        <p>
                          <span className="marked-price">
                            ${formatNumber(this.state.data[i].sizes[0].price)}{" "}
                          </span>
                          $
                          {formatNumber(
                            calculateNewPrice(
                              this.state.data[i].sizes[0].price,
                              this.state.data[i].discountSale
                            )
                          )}
                        </p>
                      )}
                    </div>
                  )}

                  {this.state.data[i].show_price ? (
                    <p className="card-text card-category-price">
                      $ {formatNumber(this.state.data[i].sizes[0].price)}
                    </p>
                  ) : // <p className="card-text card-category-price">
                  //   Price on request
                  // </p>
                  null}
                  {!this.state.data[i].sale &&
                    this.state.data[i].sold_online === true && (
                      <p className="card-text card-category-price">
                        $
                        {this.state.data[i].sizes[0].price === undefined
                          ? ""
                          : formatNumber(this.state.data[i].sizes[0].price)}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </LazyLoad>
        );
      }
      return category;
    }
  };

  createCategoryLabel = () => {
    if (Object.keys(this.state.dataCategory).length !== 0) {
      let label = [];
      label.push(
        <div className="col-12" key={this.state.dataCategory.title}>
          <h3 className="category_name category-title closit_title">
            {this.state.dataCategory.title}
            <span className="category-shop-all">
              <Link
                to={
                  "categories/" +
                  this.props.categoryType +
                  "/" +
                  this.state.dataCategory.id
                }
              >
                Shop all <i className="fas fa-chevron-right" />
              </Link>
            </span>
          </h3>
        </div>
      );
      return label;
    }
  };

  close() {
    this._isMounted && this.setState({ formVisible: false });
  }

  displayForm(value) {
    this._isMounted && this.setState({ formVisible: true, formType: value });
  }

  render() {
    if (!this.state.hasProducts) {
      return <div />;
    }

    return (
      <div className="Category separator-section">
        {this.state.visibleLoader && (
          <div className="loader-categories-area">
            <div className="loader-categories">
              <Loader type="Oval" color="#000" height="50" width="50" />
            </div>
          </div>
        )}
        <div className="container-fluid">
          <div className="container no-pd">
            <div className="row">{this.createCategoryLabel()}</div>
            <hr className="category-hr" />
            <div className="row category_margin">
              {this.createCategoryProducts()}
            </div>
          </div>
        </div>
        {this.state.formVisible && (
          <LoginForm
            typeForm={this.state.formType}
            close={this.close.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Category;
