import React from "react";
import { Link } from "react-router-dom";

const Lot = (lot, viewingRoom, index) => {
  // Actions.detaillotviewingroom({
  //   viewingRoomData: viewingRoom,
  //   item: lot,
  //   start: viewingRoom.start.seconds,
  //   end: viewingRoom.end.seconds,
  // });

  return (
    <div key={index}>
      <div style={styles.containerImageLot}>
        <img
          className="lot-img-cover"
          src={lot.principalImage}
          style={styles.imageLot}
          alt=""
        />
      </div>
      <div style={styles.containerInformationLot}>
        <div style={{ paddingLeft: "20px" }}>
          <p style={styles.titleLot}>{lot.title}</p>
          <div
            style={{
              color: "#979797",
              lineHeight: "0.825rem",
            }}
            dangerouslySetInnerHTML={{
              __html: lot.sizeAndFit,
            }}
          />
        </div>

        {!lot.sold && (
          <Link to={`/viewing-room/${viewingRoom.id}/lot/${lot.id}`}>
            <div
              className={`button-inquire ${
                lot.sold ? "button-inquire-sold" : ""
              }`}
              disabled={lot.sold}
            >
              Inquire
            </div>
          </Link>
        )}
        {lot.sold && "Sold"}
      </div>
    </div>
  );
};

export default Lot;

const styles = {
  containerImageLot: {
    // flex: 1,
    backgroundColor: "#fff",
  },
  imageLot: {
    // width: '100%',
    // height: 300,
    // resizeMode: 'contain',
    // marginVertical: 40
  },
  titleLot: {
    fontWeight: "700",
    textTransform: "capitalize",
    marginBottom: 7,
  },
  containerInformationLot: {
    paddingHorizontal: 25,
    marginTop: 17,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};
