import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Link, Redirect } from "react-router-dom";
import Sort from "../../@components/sort";
import $ from "jquery";
import "./category-details.css";
import Loader from "react-loader-spinner";
import LoginForm from "../login";
import { services, formatNumber, calculateNewPrice } from "../../#constants";
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class CategoryDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isModalVisible: false,
      newItems: true,
      priceHight: false,
      priceLow: false,
      influencer: [],
      designer: [],
      general: [],
      minorPrice: 0,
      maxPrice: 0,
      visible: false,
      isEmpty: false,
      imageCategory: "",
      imageCategoryMobile: "",
      titleCategory: "",
      colors: [],
      redirect404: false,
      visibleLoader: true,
      subcategorys: [],
      user: {},
      logged: false,
      wishlist: [],
      formVisible: false,
      formType: "",
      descriptionCategory: "",
    };
    this.init = this.init.bind(this);
    this._toggleModal = this._toggleModal.bind(this);
    this.orderBy = this.orderBy.bind(this);
    this.filterByPrice = this.filterByPrice.bind(this);
    this.filterByCategorys = this.filterByCategorys.bind(this);
    this.setOrders = this.setOrders.bind(this);
    this.setMinorPriceRange = this.setMinorPriceRange.bind(this);
    this.setMaxPriceRange = this.setMaxPriceRange.bind(this);
    this.squareImageMobile = this.squareImageMobile.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
    this.existWishList = this.existWishList.bind(this);
    this.gotoWishlistItem = this.gotoWishlistItem.bind(this);
    this.close = this.close.bind(this);
    this.displayForm = this.displayForm.bind(this);
    this.setvisist = this.setvisist.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    /**
     * Verify category exist
     */
    if (
      this.props.categoryType !== "featured" &&
      this.props.categoryType !== "designer" &&
      this.props.categoryType !== "editor" &&
      this.props.categoryType !== "influencer" &&
      this.props.categoryType !== "general"
    ) {
      this.setState({ redirect404: true });
    } else {
      /**
       * Banner Image
       */
      dbFirestore
        .collection("categorys")
        .doc(this.props.idCategory)
        .get()
        .then((snap) => {
          if (snap.exists) {
            if (
              snap.data().principalImageWeb !== undefined &&
              snap.data().principalImageWeb !== null &&
              snap.data().principalImageWeb !== ""
            ) {
              this.setState({
                imageCategory: snap.data().principalImageWeb,
                imageCategoryMobile: snap.data().principalImage,
                titleCategory: snap.data().title,
                descriptionCategory: snap.data().description,
              });
            } else {
              this.setState({
                imageCategory: snap.data().principalImage,
                imageCategoryMobile: snap.data().principalImage,
                titleCategory: snap.data().title,
                descriptionCategory: snap.data().description,
              });
            }

            /**
             * ALL Logic of categories
             */
            this.findMetadata();
            this.setvisist();
          } else {
            //no id exist
            this.setState({ redirect404: true, visibleLoader: false });
          }
        });
    }
  }

  async setvisist() {
    if (
      this.props.categoryType === "designer" ||
      this.props.categoryType === "influencer"
    ) {
      const catId = this.props.idCategory;
      const doc = dbFirestore.collection("categories_visits").doc(catId);
      try {
        await dbFirestore.runTransaction(async (transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          let snap = await transaction.get(doc);
          if (!snap.exists) {
            throw Error("Document does no exist");
          }
          var visits = snap.data().visits;
          var allVisit = snap.data().countTotal;
          const today =
            "" +
            (new Date().getMonth() + 1) +
            "/" +
            new Date().getDate() +
            "/" +
            new Date().getFullYear();
          if (visits[today] !== undefined) {
            visits[today] = visits[today] + 1;
            allVisit = allVisit + 1;
            transaction.update(doc, { visits: visits, countTotal: allVisit });
          } else {
            visits[today] = 1;
            allVisit = allVisit + 1;
            transaction.update(doc, { visits: visits, countTotal: allVisit });
          }
        });
      } catch (error) {
        //console.log("error transaction failed", error);
      }
    }
  }

  async init() {
    await this.checkLogin();
    let linkTo = "linkTo." + this.props.categoryType;
    let dataSource = [];
    let json = { data: [], key: "" };
    json.key = this.props.categoryType; //Whatever
    try {
      let snapshot;
      if (this.props.categoryType === "designer") {
        snapshot = await dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo, "==", this.props.idCategory)
          .orderBy("orderPosition", "asc")
          .get();
      } else {
        snapshot = await dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo, "==", this.props.idCategory)
          .orderBy("updatedAt", "desc")
          .get();
      }
      snapshot.forEach((doc) => {
        json.data.push(doc.data());
      });
      dataSource.push(json);
      this.setState({
        data: dataSource,
        dataOriginal: dataSource,
        visibleLoader: false,
      });
    } catch (err) {
      console.error(err);
    }
  }

  squareImageMobile() {
    var $widthWindow = window.innerWidth;
    var $width = $(".banner-category-detail").width();
    if ($widthWindow < 768) {
      $(".banner-category-detail").height($width);
    } else {
      $(".banner-category-detail").height(540);
    }
  }

  async orderBy(type) {
    let linkTo = "linkTo." + this.props.categoryType;
    let dataSource = [];
    let json = { data: [], key: "" };
    json.key = this.props.categoryType; //Lo que quieras

    //quitando filters
    this.setState({ iFilter: false, minorPrice: 0, maxPrice: 0 });
    if (type === "newitems") {
      try {
        const snapshot = await dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo, "==", this.props.idCategory)
          .orderBy("updatedAt", "desc")
          .get();
        snapshot.forEach((doc) => {
          json.data.push(doc.data());
        });
        dataSource.push(json);
        this.setState({ data: dataSource, visibleLoader: false });
      } catch (error) {
        console.error(error);
      }
    } else if (type === "priceHight") {
      try {
        const snapshot = await dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo, "==", this.props.idCategory)
          .orderBy("price", "desc")
          .get();
        snapshot.forEach((doc) => {
          json.data.push(doc.data());
        });
        dataSource.push(json);
        this.setState({ data: dataSource, visibleLoader: false });
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const snapshot = await dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo, "==", this.props.idCategory)
          .orderBy("price", "asc")
          .get();
        snapshot.forEach((doc) => {
          json.data.push(doc.data());
        });
        dataSource.push(json);
        this.setState({ data: dataSource, visibleLoader: false });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async findMetadata() {
    await this.init();
    const data = this.state.data[0].data;
    this.getColors(data);
    let id_influencers = [];
    let id_designers = [];
    let id_general = [];
    let subcategorys = [];
    let linkTo;
    data.forEach((value) => {
      linkTo = value.linkTo;
      if (this.props.categoryType === "general") {
        const exist_influencer = id_influencers.find(
          (item) => item === linkTo.influencer
        );
        const exist_designer = id_designers.find(
          (item) => item === linkTo.designer
        );
        const exist_subcategory = subcategorys.find(
          (item) => item === linkTo.sub_general
        );
        if (!exist_influencer && linkTo.influencer !== "")
          id_influencers.push(linkTo.influencer);
        if (!exist_designer && linkTo.designer !== "")
          id_designers.push(linkTo.designer);
        if (!exist_subcategory && linkTo.sub_general !== "")
          subcategorys.push(linkTo.sub_general);
      } else if (this.props.categoryType === "designer") {
        const exist_influencer = id_influencers.find(
          (item) => item === linkTo.influencer
        );
        const exist_general = id_general.find(
          (item) => item === linkTo.general
        );
        if (!exist_influencer && linkTo.influencer !== "")
          id_influencers.push(linkTo.influencer);
        if (!exist_general && linkTo.general !== "")
          id_general.push(linkTo.general);
      } else if (this.props.categoryType === "influencer") {
        const exist_designer = id_designers.find(
          (item) => item === linkTo.designer
        );
        const exist_general = id_general.find(
          (item) => item === linkTo.general
        );
        if (!exist_designer && linkTo.designer !== "")
          id_designers.push(linkTo.designer);
        if (!exist_general && linkTo.general !== "")
          id_general.push(linkTo.general);
      } else {
        const exist_influencer = id_influencers.find(
          (item) => item === linkTo.influencer
        );
        const exist_designer = id_designers.find(
          (item) => item === linkTo.designer
        );
        const exist_general = id_general.find(
          (item) => item === linkTo.general
        );
        if (!exist_influencer && linkTo.influencer !== "")
          id_influencers.push(linkTo.influencer);
        if (!exist_designer && linkTo.designer !== "")
          id_designers.push(linkTo.designer);
        if (!exist_general && linkTo.general !== "")
          id_general.push(linkTo.general);
      }
    });
    if (this.props.categoryType === "general") {
      this.setState({ subcategorys: subcategorys });
      this.getCategorys("influencer", id_influencers);
      this.getCategorys("designer", id_designers);
    } else if (this.props.categoryType === "designer") {
      this.getCategorys("influencer", id_influencers);
      this.getCategorys("general", id_general);
    } else if (this.props.categoryType === "influencer") {
      this.getCategorys("general", id_general);
      this.getCategorys("designer", id_designers);
    } else {
      this.getCategorys("influencer", id_influencers);
      this.getCategorys("designer", id_designers);
      this.getCategorys("general", id_general);
    }
  }

  async getCategorys(typeOf, IDs) {
    let data = [];
    await services.asyncForEach(IDs, async (value) => {
      try {
        const doc = await dbFirestore.collection("categorys").doc(value).get();
        if (doc.exists) {
          data.push(doc.data());
        }
      } catch (err) {
        console.error(err);
      }
    });
    this.setState({ visibleLoader: false });
    this.setState(byPropKey(typeOf, data));
  }

  filterByPrice = () => {
    if (
      this.state.minorPrice >= 0 &&
      this.state.maxPrice >= 0 &&
      this.state.maxPrice > this.state.minorPrice
    ) {
      let linkTo = "linkTo." + this.props.categoryType;
      let dataSource = [];
      let json = { data: [], key: "" };
      json.key = this.props.categoryType; //Lo que quieras

      if (this.state.newItems) {
        dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo, "==", this.props.idCategory)
          .where("price", ">=", this.state.minorPrice)
          .where("price", "<=", this.state.maxPrice)
          .orderBy("price", "desc")
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              json.data.push(doc.data());
            });
            dataSource.push(json);

            this.setState(
              {
                data: dataSource,
              },
              () => {}
            );
          })
          .catch((err) => console.error(err));
      } else if (this.state.priceHight) {
        dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo, "==", this.props.idCategory)
          .where("price", ">=", this.state.minorPrice)
          .where("price", "<=", this.state.maxPrice)
          .orderBy("price", "desc")
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              json.data.push(doc.data());
            });
            dataSource.push(json);

            this.setState(
              {
                data: dataSource,
              },
              () => {
                this._toggleModal();
              }
            );
          })
          .catch((err) => console.error(err));
      } else {
        dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo, "==", this.props.idCategory)
          .where("price", ">=", this.state.minorPrice)
          .where("price", "<=", this.state.maxPrice)
          .orderBy("price", "asc")
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              json.data.push(doc.data());
            });
            dataSource.push(json);

            this.setState(
              {
                data: dataSource,
              },
              () => {
                this._toggleModal();
              }
            );
          })
          .catch((err) => console.error(err));
      }
    } else {
      console.log("Error NF.");
    }
  };

  async filterByCategorys(category_filter, category_id) {
    const linkTo1 = "linkTo." + this.props.categoryType;
    const linkTo2 = "linkTo." + category_filter;

    let dataSource = [];
    let json = { data: [], key: "" };
    json.key = this.props.categoryType; //Lo que quieras

    if (this.state.newItems) {
      try {
        const snapshot = await dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo1, "==", this.props.idCategory)
          .where(linkTo2, "==", category_id)
          .orderBy("updatedAt", "desc")
          .get();
        snapshot.forEach((doc) => {
          json.data.push(doc.data());
        });
        dataSource.push(json);

        this.setState(
          {
            data: dataSource,
          },
          () => {
            this._toggleModal();
          }
        );
      } catch (err) {
        console.error(err);
      }
    } else if (this.state.priceHight) {
      try {
        const snapshot = await dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo1, "==", this.props.data[0].id)
          .where(linkTo2, "==", category_id)
          .orderBy("price", "desc")
          .get();

        snapshot.forEach((doc) => {
          json.data.push(doc.data());
        });
        dataSource.push(json);

        this.setState(
          {
            data: dataSource,
          },
          () => {
            this._toggleModal();
          }
        );
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        const snapshot = await dbFirestore
          .collection("products")
          .where("publish", "==", true)
          .where(linkTo1, "==", this.props.data[0].id)
          .where(linkTo2, "==", category_id)
          .orderBy("price", "asc")
          .get();

        snapshot.forEach((doc) => {
          json.data.push(doc.data());
        });
        dataSource.push(json);

        this.setState(
          {
            data: dataSource,
          },
          () => {
            this._toggleModal();
          }
        );
      } catch (err) {
        console.error(err);
      }
    }
  }

  _toggleModal() {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  }

  setOrders(newItems, priceHight, priceLow) {
    this.setState({
      newItems: newItems,
      priceHight: priceHight,
      priceLow: priceLow,
    });
  }

  setMinorPriceRange(e) {
    this._isMounted && this.setState({ minorPrice: e });
  }

  setMaxPriceRange(e) {
    this._isMounted && this.setState({ maxPrice: e });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createProdCard = () => {
    if (this.state !== null && this.state !== undefined) {
      let products = [];
      if (
        this.state.data !== null &&
        this.state.data !== undefined &&
        this.state.data.length > 0
      ) {
        for (var i = 0; i < this.state.data[0].data.length; i++) {
          const prod = this.state.data[0].data[i];
          products.push(
            <div
              key={"prod-category-" + i}
              className="col-6 col-md-15  separator-row"
            >
              <div className="card card-prod-card ">
                {prod.sale && (
                  <div className="forSale-box">
                    <p>For Sale</p>
                  </div>
                )}
                {/* <div className="wishlist-box">
                  {!this.state.logged && (
                    <i
                      className=" far fa-heart"
                      onClick={() => this.displayForm("login")}
                    />
                  )}

                  {this.state.logged && !this.existWishList(prod.id) && (
                    <i
                      className=" far fa-heart"
                      onClick={e => {
                        this.gotoWishlistItem(prod.id, 1);
                      }}
                    />
                  )}

                  {this.state.logged && this.existWishList(prod.id) && (
                    <i
                      className=" fas fa-heart"
                      onClick={e => {
                        this.gotoWishlistItem(prod.id, 0);
                      }}
                    />
                  )}
                </div> */}
                <Link to={"/product/" + prod.id} className="special_height">
                  <img
                    className="card-prod-img categories-details-img"
                    src={prod.principalImage}
                    alt={prod.title}
                  />
                </Link>

                <div className="card-body card-body-prod-card">
                  <div className="top-card">
                    <Link to={"/product/" + prod.id}>
                      <h5 className="card-title">{prod.title}</h5>
                    </Link>
                    <p className="card-text">{prod.shortDescription}</p>
                    {prod.show_price ? (
                      <p className="card-text card-price">
                        ${formatNumber(prod.sizes[0].price)}
                      </p>
                    ) : // <p className="card-text card-price">Price on request</p>
                    null}
                    {prod.sale && prod.sold_online && (
                      <p className="card-text card-price">
                        <span className="marked-price">
                          ${formatNumber(prod.sizes[0].price)}{" "}
                        </span>
                        $
                        {formatNumber(
                          calculateNewPrice(
                            prod.sizes[0].price,
                            prod.discountSale
                          )
                        )}
                      </p>
                    )}
                    {!prod.sale && prod.sold_online && (
                      <p className="card-text card-price">
                        ${formatNumber(prod.sizes[0].price)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return products;
      }
    }
  };

  getColors = (data) => {
    let sizes, exist_color;
    let colors = [];
    data.forEach((value) => {
      sizes = value.sizes;
      sizes.forEach((size) => {
        if (size.color !== "") {
          exist_color = colors.find((item) => item === size.color);
          if (!exist_color) colors.push(size.color);
        }
      });
    });
    this._isMounted && this.setState({ colors: colors });
  };

  toggleModal() {
    document.querySelector("#modal-review-close").click();
  }

  async filterByColor(color) {
    if (this.state.color !== "") {
      let linkTo = "linkTo." + this.props.categoryType;
      let dataSource = [];
      let json = { data: [], key: "" };
      json.key = this.props.categoryType;

      this._isMounted &&
        this.setState({ iFilter: true, minorPrice: 0, maxPrice: 0 });

      if (this.state.newItems) {
        try {
          const snapshot = await dbFirestore
            .collection("products")
            .where("publish", "==", true)
            .where(linkTo, "==", this.props.idCategory)
            .orderBy("updatedAt", "desc")
            .get();

          let sizes, i, tam;
          snapshot.forEach((doc) => {
            sizes = doc.data().sizes;
            tam = sizes.length;
            for (i = 0; i < tam; i++) {
              if (sizes[i].color === color) {
                json.data.push(doc.data());
                break;
              }
            }
          });
          dataSource.push(json);

          this._isMounted &&
            this.setState(
              {
                data: dataSource,
              },
              () => {
                this.toggleModal();
              }
            );
        } catch (err) {
          console.error(err);
        }
      } else if (this.state.priceHight) {
        try {
          const snapshot = await dbFirestore
            .collection("products")
            .where("publish", "==", true)
            .where(linkTo, "==", this.props.idCategory)
            .orderBy("price", "desc")
            .get();

          let sizes, i, tam;
          snapshot.forEach((doc) => {
            sizes = doc.data().sizes;
            tam = sizes.length;
            for (i = 0; i < tam; i++) {
              if (sizes[i].color === color) {
                json.data.push(doc.data());
                break;
              }
            }
          });
          dataSource.push(json);

          this._isMounted &&
            this.setState(
              {
                data: dataSource,
              },
              () => {
                this.toggleModal();
              }
            );
        } catch (err) {
          console.error(err);
        }
      } else {
        try {
          const snapshot = await dbFirestore
            .collection("products")
            .where("publish", "==", true)
            .where(linkTo, "==", this.props.data[0].id)
            .orderBy("price", "asc")
            .get();

          let sizes, i, tam;
          snapshot.forEach((doc) => {
            sizes = doc.data().sizes;
            tam = sizes.length;
            for (i = 0; i < tam; i++) {
              if (sizes[i].color === color) {
                json.data.push(doc.data());
                break;
              }
            }
          });
          dataSource.push(json);

          this._isMounted &&
            this.setState(
              {
                data: dataSource,
              },
              () => {
                this.toggleModal();
              }
            );
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  async checkLogin() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({
            user: userLogged,
            logged: true,
          });
        let snapshot = await dbFirestore
          .collection("users")
          .doc(user.uid)
          .get();
        this._isMounted &&
          this.setState({
            infoUser: snapshot.data(),
            wishlist: snapshot.data().wishlist,
          });
      } else {
        this._isMounted &&
          this.setState({
            user: {},
            logged: false,
            infoUser: {},
          });
      }
    });
  }

  existWishList(id) {
    let exist = this.state.wishlist.filter((value) => value === id);
    if (exist.length === 1) {
      return true;
    }
    return false;
  }

  async gotoWishlistItem(id, action) {
    if (this.state.logged) {
      let newDataWishlist = this.state.wishlist;
      if (action) {
        newDataWishlist.push(id);
        try {
          dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist: newDataWishlist });
          this._isMounted && this.setState({ wishlist: newDataWishlist });
        } catch (error) {
          console.error("Error ", error);
        }
      } else {
        //remove item
        let data = newDataWishlist.filter((value) => value !== id);
        try {
          dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist: data });
          this._isMounted && this.setState({ wishlist: data });
        } catch (error) {
          console.error("Error ", error);
        }
      }
    } else {
      //go to login
      this._isMounted &&
        this.setState({ formVisible: true, formType: "login" });
    }
  }

  close() {
    this._isMounted && this.setState({ formVisible: false });
  }

  displayForm(value) {
    this._isMounted && this.setState({ formVisible: true, formType: value });
  }

  render() {
    const influencer = this.state.influencer;
    const general = this.state.general;
    const designer = this.state.designer;
    const colors = this.state.colors;
    const subcategorys = this.state.subcategorys;
    return (
      <div className="details-category">
        <div className="container no-pd-mobile">
          {this.state.redirect404 && <Redirect push to={"/404"} />}
          <div className="container-fluid no-pd">
            <div className="row">
              <div className="col-12">
                <img
                  className="banner-category-detail desk"
                  src={this.state.imageCategory}
                  alt={this.state.titleCategory}
                />
                <img
                  className="banner-category-detail mobile"
                  src={this.state.imageCategoryMobile}
                  alt={this.state.titleCategory}
                />
              </div>
            </div>
          </div>

          <div className="container-fluid no-pd description-box-category">
            <div className="row">
              <div className="col-12">
                <p className="description-text-category">
                  {this.state.descriptionCategory}
                </p>
              </div>
            </div>
          </div>

          <Sort
            findMetadata={this.findMetadata.bind(this)}
            orderBy={this.orderBy.bind(this)}
            filterByPrice={this.filterByPrice.bind(this)}
            filterByCategorys={this.filterByCategorys.bind(this)}
            setOrders={this.setOrders.bind(this)}
            influencer={influencer}
            general={general}
            designer={designer}
            colors={colors}
            subs={subcategorys}
            setMinorPriceRange={this.setMinorPriceRange.bind(this)}
            setMaxPriceRange={this.setMaxPriceRange.bind(this)}
            filterByColor={this.filterByColor.bind(this)}
          />
          {!this.state.isEmpty && (
            <div className="container-fluid">
              {this.state.visibleLoader && (
                <div className="loader-categories">
                  <Loader type="Oval" color="#000" height="50" width="50" />
                </div>
              )}
              <div className="container">
                <div className="row">{this.createProdCard()}</div>
              </div>
            </div>
          )}
        </div>
        {this.state.formVisible && (
          <LoginForm
            typeForm={this.state.formType}
            close={this.close.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default CategoryDetails;
