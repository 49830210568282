import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Link, Redirect } from "react-router-dom";
import "./detail-lot.css";
import LoginForm from "../../@components/login";
import SimilarLots from "../../@components/similar-lots";
import ShareModal from "../../@components/share-modal";
import CountDownSan from "../../@components/countdown";
import { metasTagsLots, formatNumber } from "../../#constants";
import CarouselProduct from "../../@components/carousel-product";
class DetailLotPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableQuantity: 1,
      data: {
        title: "",
        estimatedPrice: 0,
        estimatedPriceMax: 0,
        description: "<p></p>",
        minimiumPrice: 0,
        bidAmount: 0,
        bids_users: [],
      },
      redirect404: false,
      logged: false,
      bidSelected: "",
      formVisible: false,
      timed: true,
      active: false,
      messageButton: "BID",
      redirect: false,
      bid: true,
      existWishlistLots: false,
      wishlistLots: [],
      modalShare: false,
      redirectAuctions: false,
      auction: {},
      photos: [],
      allLots: [],
      redirectNextLot: false,
      idNextLot: ""
    };
    this.makeBid = this.makeBid.bind(this);
    this.handleChangeBids = this.handleChangeBids.bind(this);
    this.terminate = this.terminate.bind(this);
    this.addToWishlist = this.addToWishlist.bind(this);
    this.removeToWishlist = this.removeToWishlist.bind(this);
    this.init = this.init.bind(this);

    this._isMounted = false;
    this._completed = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    const lotId = this.props.match.params.id;
    this.setState({ redirectNextLot: false, idNextLot: "" })
    auth.onAuthStateChanged(async (user) => {
      var logged;
      if (user) logged = true;
      else {
        logged = false;
      }
      this._isMounted && this.setState({ logged: logged, user: user });

      if (logged) {
        const userLogged = await dbFirestore
          .collection("users")
          .doc(user.uid)
          .get();
        var wishlist = userLogged.data().wishlist_lots;
        var existWishlistLots;
        if (wishlist.indexOf(lotId) >= 0) existWishlistLots = true;
        else existWishlistLots = false;

        this._isMounted &&
          this.setState({
            existWishlistLots: existWishlistLots,
            wishlistLots: userLogged.data().wishlist_lots,
          });
      }
      this.init();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== null && nextProps !== undefined) {
      this.props = nextProps;
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async init() {
    //normal flow user enter to lot detail
    const lotId = this.props.match.params.id;
    let lot = null;

    try {
      let snapshot = await dbFirestore.collection("lots").doc(lotId).get();
      if (!snapshot.exists || !snapshot.data().publish) {
        this._isMounted && this.setState({ redirect404: true });
      }

      lot = snapshot.data();
      //meta datos
      metasTagsLots.setMetaTags(lot);
      //end meta datos

      let photos = [];
      photos.push({ principalImage: lot.principalImage });

      let tam = lot.secondaryImages.length;
      for (let i = 0; i < tam; i++) {
        photos.push({ principalImage: lot.secondaryImages[i] });
      }

      this._isMounted && this.setState({ photos: photos, data: lot });

      //Se generan los bids correspodientes según los datos de la subasta
      this.generarBids(
        parseInt(lot.minimiumPrice, 10),
        parseInt(lot.maximiumPrice, 10),
        parseInt(lot.bidInterval, 10),
        lot.bidAmount
      );


      const infoAuctionPromise = this.getInfoAuction(lot);
      const allLotsPromise = this.getAllLots(lot);
      await Promise.all([infoAuctionPromise, allLotsPromise]);


    } catch (error) {
      console.log(error);
    }
  }

  getInfoAuction = async (lot) => {
    let snapshotAuction = await dbFirestore
      .collection("auctions")
      .doc(lot.auction)
      .get();
    if (!snapshotAuction.exists) {
      this._isMounted && this.setState({ redirect404: true });
    }

    const auction = snapshotAuction.data();
    let initDay = new Date(auction.start.seconds * 1000).getTime();
    let today = new Date().getTime();
    var timed = true;
    if (initDay < today) {
      timed = false;
    }

    this._isMounted && this.setState({ auction: auction, timed: timed });

    let seconds = Math.round(new Date().getTime() / 1000);
    let end = auction.end.seconds;
    let start = auction.start.seconds;
    this._isMounted && this.setState({ end: end, start: start });

    if (end < seconds) {
      if (this.state.logged) {
        if (this.state.user.uid === lot.userWin) {
          this._isMounted &&
            this.setState({ messageButton: "Thank you for your bid" });
        } else {
          this._isMounted && this.setState({ messageButton: "SOLD" });
        }
      } else {
        this._isMounted && this.setState({ messageButton: "SOLD" });
      }
      //Se setea la que la subasta no está activa y que no está en timed.
      this._isMounted && this.setState({ active: false, timed: false });
    } else {
      if (start < seconds) {
        if (this.state.logged) {
          if (this.state.user.uid === lot.userWin) {
            this._isMounted &&
              this.setState({
                messageButton: "Thank you for your bid",
                active: false,
              });
          } else {
            this._isMounted &&
              this.setState({ active: true, messageButton: "BID" });
          }
          this._isMounted && this.setState({ timed: false });
        }
      }
    }
  }

  getAllLots = async (lot) => {

    let snapshot = await dbFirestore
      .collection("lots")
      .where("publish", "==", true)
      .where("auction", "==", lot.auction)
      .orderBy("order", "asc")
      .get();

    let nextItem;
    if (!snapshot.empty) {
      let dataLot;
      const sizeDocs = snapshot.size;
      for(let i=0; i<sizeDocs; i++){
        dataLot = snapshot.docs[i].data();
        if (dataLot.id === lot.id) {
          if (i+1 < sizeDocs) nextItem = snapshot.docs[i+1].data().id;
          else nextItem = snapshot.docs[0].data().id;
          break;
        }
      }
    }
    this._isMounted && this.setState({ idNextLot: nextItem });
  }

  userBided() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.data.bid && this.state.bid) {
        this._isMounted && this.setState({ bid: false });
        let bids_users = this.state.data.bids_users;

        if (bids_users[bids_users.length - 1] === this.state.user.uid) {
          return;
        }

        bids_users.push(this.state.user.uid);
        dbFirestore
          .collection("lots")
          .doc(this.state.data.id)
          .update({
            userWin: this.state.user.uid,
            bidAmount: this.props.location.state.data.bidSelected,
            bids_users: bids_users,
          })
          .catch((err) => console.error(err));
      }
    }
  }

  createHeaderTimed() {
    if (
      this.state.auction.end !== undefined &&
      this.state.auction.start !== undefined
    ) {
      let header = [];
      //auction timed
      header.push(
        <div
          className="row align-center align-items-center mt-3 header-lots"
          key="row-header"
        >
          <div className="col-12 col-md-6 mb-2">
            <p className="lot_detail_title closit_text">
              {this.state.data.title}
            </p>
          </div>
          <div className="col-12 col-md-6">
            {/*<Countdown date={new Date(this.state.auction.start.seconds * 1000).getTime()} renderer={this.renderer}/>*/}
            <CountDownSan
              date={new Date(
                this.state.auction.start.seconds * 1000
              ).toString()}
              className="CountDown"
              onEnd={() => {
                this.terminate();
              }}
            />
          </div>
        </div>
      );
      return header;
    }
  }

  createHeaderLived() {
    if (
      this.state.auction.end !== undefined &&
      this.state.auction.start !== undefined
    ) {
      let header = [];

      //auction live
      header.push(
        <div
          className="row align-center align-items-center mt-3 header-lots"
          key="row-header"
        >
          <div className="col-12 col-md-6 mb-2">
            {this.processTitle(
              this.state.data.title,
              "lot_detail_title closit_text",
              "0.8rem"
            )}
          </div>
          <div className="col-12 col-md-6">
            <p
              style={{
                textAlign: "center",
              }}
            >
              AUCTIONS ENDS IN:
            </p>
            {/*<Countdown date={new Date(this.state.auction.end.seconds * 1000).getTime()} renderer={this.renderer}/>*/}
            <CountDownSan
              date={new Date(this.state.auction.end.seconds * 1000).toString()}
              className="CountDown"
            />
          </div>
        </div>
      );

      return header;
    }
  }

  processTitle = (title, className, size) => {
    if (title !== "") {
      const split_title_one = title.split("[");
      if (split_title_one.length === 1) {
        return <h1 className={className}>{title}</h1>;
      }
      const split_title_two = split_title_one[1].split("]");

      return (
        <h1 className={className}>
          {split_title_one[0]}
          <span style={{ fontSize: size, fontWeight: "normal" }}>
            [{split_title_two[0].toUpperCase()}]
          </span>
        </h1>
      );
    }
  };

  createInfoBoxLot() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.data !== null && this.state.data !== undefined) {
        let infoBox = [];
        infoBox.push(
          <div className="information-box-lot" key="info-box-lot">
            {this.processTitle(
              this.state.data.title,
              "lot_title closit_text",
              "1rem"
            )}

            <p className="lot-description">
              Estimated: ${formatNumber(this.state.data.estimatedPrice)} - $
              {formatNumber(this.state.data.estimatedPriceMax)}
            </p>
            <p className="lot-description">
              (Bids: {this.state.data.bids_users.length})
            </p>
            {this.state.data.bidAmount === 0 ? (
              <p className="bit-amount">
                Starting Bid: ${formatNumber(this.state.data.minimiumPrice)}
              </p>
            ) : (
                <p className="bit-amount">
                  Current Bid: ${formatNumber(this.state.data.bidAmount)}
                </p>
              )}
          </div>
        );
        return infoBox;
      }
    }
  }

  createDescriptionLot() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.data !== null && this.state.data !== undefined) {
        let description = [];
        description.push(
          <div className="row" key="description-box">
            <div className="col-12 ">
              <p className="lot_detail_title closit_text mb-2">
                About {this.state.data.title}
              </p>
              <hr className="auction_separator my-0 mb-2" />
              <div
                className="lot-detail-description pars-str"
                dangerouslySetInnerHTML={{
                  __html: this.state.data.description,
                }}
              />
            </div>
          </div>
        );
        return description;
      }
    }
  }

  createOptionsLot() {
    if (this.state !== null && this.state !== undefined) {
      if (this.state.bids !== null && this.state.bids !== undefined) {
        let options = [];
        for (let index = 0; index < this.state.bids.length; index++) {
          options.push(
            <option
              value={this.state.bids[index].value}
              key={"op-lot-" + index}
            >
              {this.state.bids[index].label}
            </option>
          );
        }
        return options;
      }
    }
  }

  generarBids = (min, max, porcentaje, bidAmount) => {
    bidAmount = Number(bidAmount);
    let bids = [];
    let bid;

    if (bidAmount === 0) bid = min;
    else bid = bidAmount;

    do {
      if (bid < 1000) bid += 50;
      else if (bid < 2000) bid += 100;
      else if (bid < 5000) bid += 250;
      else if (bid < 10000) bid += 500;
      else if (bid < 20000) bid += 1000;
      else if (bid < 50000) bid += 2000;
      else if (bid < 100000) bid += 5000;
      else if (bid >= 100000) bid += 10000;
      bids.push({ label: "$ " + formatNumber(bid.toString()), value: bid });
    } while (bid <= max);

    this._isMounted && this.setState({ bids: bids });
  };

  makeBid() {
    if (this.state.logged) {
      if (this.state.bidSelected === "") {
        //notify has need select a bid
        document.getElementById("lot-bid").classList.add("error");
        document.querySelector(".lot-error-text").classList.add("visible");
      } else {
        //do the bid redirect to confirm
        this._isMounted && this.setState({ redirect: true });
      }
    } else {
      //show login
      this._isMounted && this.setState({ formVisible: true });
    }
  }

  handleChangeBids(e) {
    document.getElementById("lot-bid").classList.remove("error");
    document.querySelector(".lot-error-text").classList.remove("visible");
    this._isMounted && this.setState({ bidSelected: e.target.value });
  }

  displayForm() {
    this._isMounted && this.setState({ formVisible: true, formType: "login" });
  }

  close() {
    this._isMounted && this.setState({ formVisible: false });
  }

  terminate() {
    if (this.state.timed) {
      this._isMounted && this.setState({ timed: false });
    } else {
      if (this.state.logged) {
        if (this.state.user.uid === this.state.data.userWin) {
          this._isMounted &&
            this.setState({ messageButton: "Thank you for your bid" });
        } else {
          this._isMounted && this.setState({ messageButton: "Sold" });
        }
      } else {
        this._isMounted && this.setState({ messageButton: "Sold" });
      }
      //Se setea la que la subasta no está activa
      this._isMounted && this.setState({ active: false });
    }
  }

  async addToWishlist() {
    const lotId = this.props.match.params.id;
    if (this.state.logged) {
      var wishlist_lots = this.state.wishlistLots;
      wishlist_lots.push(lotId);
      try {
        await dbFirestore
          .collection("users")
          .doc(this.state.user.uid)
          .update({ wishlist_lots: wishlist_lots });
        this._isMounted &&
          this.setState({
            existWishlistLots: true,
            wishlistLots: wishlist_lots,
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      this._isMounted && this.setState({ formVisible: true });
    }
  }

  async removeToWishlist() {
    const lotId = this.props.match.params.id;
    let newWishlist = this.state.wishlistLots.filter(
      (value) => value !== lotId
    );
    try {
      await dbFirestore
        .collection("users")
        .doc(this.state.user.uid)
        .update({ wishlist_lots: newWishlist });
      this._isMounted &&
        this.setState({ existWishlistLots: false, wishlistLots: newWishlist });
    } catch (error) {
      console.log(error);
    }
  }

  nextLot = () => {
    if (this.state.idNextLot !== "") {
      this.setState({ redirectNextLot: true });
    }
  }

  render() {
    return (
      <div className="DetailLotPage">
        {this.state.redirect404 && <Redirect push to={"/not-found"} />}
        {this.state.redirectAuctions && <Redirect push to={"/auctions"} />}
        {this.state.redirectNextLot && <Redirect push to={"/lot/" + this.state.idNextLot} />}
        <div className="container">
          {this.state.timed && this.createHeaderTimed()}
          {!this.state.timed && this.createHeaderLived()}
          <hr className="auction_separator" />
          <div className="row align-items-center mb-3">
            <div className="col-12 col-lg-6 center">
              {/* <img className="main_lot_img" src={this.state.data.principalImage} alt={this.state.data.title} /> */}
              <CarouselProduct
                photos={this.state.photos}
                data={this.state.data}
              />
              <div className="lot_icon_container text-center mt-3">
                {!this.state.existWishlistLots && (
                  <a
                    className="add-heart col-4"
                    onClick={() => {
                      this.addToWishlist();
                    }}
                  >
                    <i className="far fa-heart" />
                  </a>
                )}
                {this.state.existWishlistLots && (
                  <a
                    className="remove-heart col-4"
                    onClick={() => {
                      this.removeToWishlist();
                    }}
                  >
                    <i className="fas fa-heart" />
                  </a>
                )}
                <a
                  className="share-bag col-4"
                  data-toggle="modal"
                  data-target="#shareModal"
                >
                  <i className="fas fa-share-square" />
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              {this.createInfoBoxLot()}
              <div className="lot_bid_container">
                <div className="form-group">
                  <select
                    className="form-control"
                    id="lot-bid"
                    onChange={this.handleChangeBids}
                    value={this.state.bidSelected}
                  >
                    <option value="" disabled>
                      Select Bid
                    </option>
                    {this.createOptionsLot()}
                  </select>
                  <p className="lot-error-text">You must select a bid</p>
                </div>
                <div className="btn_container center">
                  <button
                    className="btn btn-secondary btn-sm btn-lot-detail"
                    // disabled={!this.state.active}
                    onClick={this.makeBid}
                  >
                    {this.state.messageButton}
                  </button>

                  <button
                    onClick={() => this.nextLot()}
                    className="btn btn-secondary btn-sm btn-lot-detail"
                    style={{
                      marginTop: 10,
                    }}
                  >
                    NEXT LOT
                    </button>

                  <Link to={"/auction/" + this.state.data.auction}>
                    <button
                      className="btn btn-secondary btn-sm btn-lot-detail"
                      style={{
                        marginTop: 10,
                      }}
                    >
                      SEE MORE LOTS
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container lot-description-area">
          {this.createDescriptionLot()}
          <SimilarLots lot={this.props.match.params.id} />
        </div>
        {/*MODAL */}
        <ShareModal shareLink={window.location.href} />
        {/* END MODAL */}

        {this.state.redirect && (
          <Redirect
            push
            to={{
              pathname: "/confirm-lot",
              state: {
                data: {
                  auction: this.state.auction,
                  bidSelected: this.state.bidSelected,
                  id: this.state.data.id,
                },
              },
            }}
          />
        )}
        {this.state.formVisible && (
          <LoginForm typeForm="login" close={this.close.bind(this)} />
        )}
      </div>
    );
  }
}

export default DetailLotPage;
