import React, { Component } from "react";
import "./carousel-product.css";
import $ from "jquery";

class CarouselProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      data: {}
    };
    this.CarouselSwipe = this.CarouselSwipe.bind(this);
    this.createDots = this.createDots.bind(this);
    this.createCarouselProduct = this.createCarouselProduct.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      this.setState(
        { photos: this.props.photos, data: this.props.data },
        () => {
          this.CarouselSwipe();
        }
      );
  }

  createDots() {
    if (this.state.photos.length > 0) {
      let dots = [];
      for (let i = 0; i < this.state.photos.length; i++) {
        if (i === 0)
          dots.push(
            <li
              data-target="#carousel-Prod-Detail"
              data-slide-to="0"
              className="active"
              key={"dot-carousel-prod" + i}
            />
          );
        else
          dots.push(
            <li
              data-target="#carousel-Prod-Detail"
              data-slide-to={i}
              key={"dot-carousel-prod" + i}
            />
          );
      }
      return dots;
    }
  }

  createCarouselProduct() {
    if (this.state.photos.length > 0) {
      let carousel = [];
      for (var i = 0; i < this.state.photos.length; i++) {
        if (i === 0) {
          carousel.push(
            <div
              className="carousel-item h-100 active"
              key={"carousel-prod-detail" + i}
            >
              <img
                className="d-block w-100 h-100 img-cover"
                src={this.state.photos[i].principalImage}
                alt={this.state.data.title}
              />
            </div>
          );
        } else {
          carousel.push(
            <div
              className="carousel-item h-100"
              key={"carousel-prod-detail" + i}
            >
              <img
                className="d-block w-100 h-100 img-cover"
                src={this.state.photos[i].principalImage}
                alt={this.state.data.title}
              />
            </div>
          );
        }
      }
      return carousel;
    }
  }

  CarouselSwipe() {
    $("#carousel-Prod-Detail").swipe({
      swipe: function(event,direction) {
        if (direction === "left") $(this).carousel("next");
        if (direction === "right") $(this).carousel("prev");
      },
      allowPageScroll: "vertical",
      threshold: 100
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.photos !== undefined && nextProps.data !== undefined) {
      this._isMounted=true;
      this._isMounted && this.setState({photos: nextProps.photos,data: nextProps.data},()=>{this.CarouselSwipe()});
    }
  }
  
  componentWillUnmount(){
    this._isMounted=false;
  }

  render(){     
    return(
      <div id="carousel-Prod-Detail" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">{this.createDots()}</ol>
        <div className="carousel-inner h-100">{this.createCarouselProduct()}</div>
          <a className="carousel-control-prev" href="#carousel-Prod-Detail" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true">
              <i className="fas fa-angle-left"></i>
            </span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carousel-Prod-Detail" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true">
              <i className="fas fa-angle-right"></i>
            </span>
            <span className="sr-only">Next</span>
          </a>
      </div>
    )
  }
}

export default CarouselProduct;
