//Dependencies
import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Redirect } from "react-router-dom";
import {Elements, StripeProvider} from 'react-stripe-elements';
import SaveCard from '../../@components/save-card';
import { Constants, metasTags } from "../../#constants";
import "react-credit-cards/es/styles-compiled.css";
import "./new-card.css";

class NewCardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: "",
      name: "Josue",
      expiry: "",
      cvc: "",
      focused: "",
      card: {},
      logged: false,
      user: {},
      infoUser: {},
      isMounted: false,
      visible: false,
      errorNumber: false,
      errorName: false,
      errorExpery: false,
      errorCvc: false,
      first: true,
      redirectWithAuction: false,
      redirectWithout: false,
      auction: false,
      auctionData:{},
      redirectLog: false,
      checkout:false,
      bidSelected:"",
      stripeData: {},
    };
    this.init = this.init.bind(this);
    this.getData = this.getData.bind(this);
    this._isMounted = false;
    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted =true;
    metasTags.setMetaTags();

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.data.auction) {
        this._isMounted && this.setState({auction: true,auctionData:this.props.location.state.data.auctionData ,lot: this.props.location.state.data.lot,bidSelected: this.props.location.state.data.bidSelected});
      }
      if (this.props.location.state.data.checkout) {
        this._isMounted && this.setState({checkout: true});
      }
    }
    else{
      //sin props se debe mandar al home
      this._isMounted && this.setState({redirectLog: true});
    }
    auth.onAuthStateChanged( async user => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted && this.setState({user: userLogged,logged: true,redirectLog: false});
        this.init();
          
      } else {
        this._isMounted && this.setState({user: {},logged: false,redirectLog: true,infoUser: {}});
      }
    });
    
  }

  async init(){
    let snapshot = await dbFirestore.collection("users").doc(auth.currentUser.uid).get();
    let infoUser = snapshot.data();
    this._isMounted && this.setState({infoUser: infoUser });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getData(token){
    this.setState({stripeData:token},()=>{
      if(this.state.auction){
        this.setState({redirectWithAuction:true});
      }
      else{
        this.setState({redirectWithout:true});
      }
    });
  }

  back(){
    this.props.history.goBack();
  }

  render() {
    const key = Constants.stripeKey();
    return (
      <StripeProvider apiKey={key}>
        <div className="NewCard">
          {this.state.redirectLog && <Redirect push to={"/"} />}
          <div className="container-fluid">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1 className="text-center title-new-card my-3 closit_text">Enter Information of the Credit Card</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Elements>
                    <SaveCard getData={this.getData.bind(this)} back={this.back.bind(this)}/>
                  </Elements>
                </div>
              </div>
            </div>
          </div>

          {this.state.redirectWithAuction && (
            <Redirect
              push
              to={{
                pathname: "/new-address",
                state: {
                  data: {
                    auction: true,
                    lot: this.state.lot,
                    cardNumber: this.state.number,
                    cardName: this.state.name,
                    cardExpery: this.state.expiry,
                    cardCvc: this.state.cvc,
                    checkout: this.state.checkout,
                    bidSelected: this.state.bidSelected,
                    auctionData: this.state.auctionData,
                    stripeData: this.state.stripeData
                  }
                }
              }}
            />
          )}
          {this.state.redirectWithout && (
            <Redirect
              to={{
                pathname: "/new-address",
                state: {
                  data: {
                    cardNumber: this.state.number,
                    cardName: this.state.name,
                    cardExpery: this.state.expiry,
                    cardCvc: this.state.cvc,
                    checkout: this.state.checkout,
                    stripeData: this.state.stripeData
                  }
                }
              }}
            />
          )}
        </div>
      </StripeProvider>
    );
  }
}

export default NewCardPage;
