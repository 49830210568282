import React from 'react'

const Banner = (banner, index) => {
  return (
    <div key={index} style={{ marginTop: 35 }}>
      <div>
        <img
          src={banner.bannerImage}
          alt=""
          style={{ width: '100%', objectFit: 'cover' }}
        />
      </div>
      <div>
        <p style={{ padding: '0 30px', paddingTop: 15, textAlign: 'right' }}>
          {banner.description}
        </p>
      </div>
    </div>
  )
}

export default Banner
