import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { Link } from "react-router-dom";
import "./similar.css";
import LoginForm from "../login";
import {
  ResponsiveEllipsis,
  formatNumber,
  calculateNewPrice,
} from "../../#constants";

class Similar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dataCategory: [],
      random_general: false,
      random_designer: false,
      infoUser: {},
      logged: false,
      user: {},
      wishlist: [],
      formVisible: false,
      formType: "",
    };
    this.checkLogin = this.checkLogin.bind(this);
    this.existWishList = this.existWishList.bind(this);
    this.gotoWishlistItem = this.gotoWishlistItem.bind(this);
    this.close = this.close.bind(this);
    this.displayForm = this.displayForm.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.init();
  }

  componentWillReceiveProps(newProps) {
    this.props = newProps;
    this.init();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async checkLogin() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        var userLogged = auth.currentUser;
        this._isMounted &&
          this.setState({
            user: userLogged,
            logged: true,
          });
        let snapshot = await dbFirestore
          .collection("users")
          .doc(user.uid)
          .get();
        this._isMounted &&
          this.setState({
            infoUser: snapshot.data(),
            wishlist: snapshot.data().wishlist,
          });
      } else {
        this._isMounted &&
          this.setState({ user: {}, logged: false, infoUser: {} });
      }
    });
  }

  async init() {
    await this.checkLogin();
    const res = await this.getSimilarItems();
    if (
      res === false &&
      (this.state.random_general === false ||
        this.state.random_designer === false)
    ) {
      this.init();
    }
  }

  async getSimilarItems() {
    let dataSource = [];
    let json = { data: [], key: "Similar Items" };
    const result = await this.getCategory();
    if (result[1] !== "") {
      dbFirestore
        .collection("products")
        .where("publish", "==", true)
        .where(result[0], "==", result[1])
        .orderBy("updatedAt", "desc")
        .limit(6)
        .get()
        .then((docsProd) => {
          docsProd.forEach((doc) => {
            if (doc.data().id !== this.props.id) {
              json.data.push(doc.data());
            }
          });
          dataSource.push(json);
          this._isMounted && this.setState({ data: dataSource });
        })
        .catch((err) => console.error(err));
    } else {
      return false;
    }
  }

  async getCategory() {
    const products = await dbFirestore
      .collection("products")
      .doc(this.props.id)
      .get();
    const item = products.data();

    const random = Math.round(Math.random() * 1);
    let category;
    let linkTo = "linkTo.";
    let id;
    switch (random) {
      case 0:
        category = "designer";
        this._isMounted && this.setState({ random_designer: true });
        id = item.linkTo.designer;
        break;
      case 1:
        category = "general";
        this._isMounted && this.setState({ random_general: true });
        id = item.linkTo.general;
        break;

      default:
        break;
    }
    linkTo = linkTo + category;
    return [linkTo, id];
  }

  existWishList(id) {
    let exist = this.state.wishlist.filter((value) => value === id);
    if (exist.length === 1) {
      return true;
    }
    return false;
  }

  async gotoWishlistItem(id, action) {
    if (this.state.logged) {
      let newDataWishlist = this.state.wishlist;
      if (action) {
        newDataWishlist.push(id);
        try {
          dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist: newDataWishlist });
          this._isMounted && this.setState({ wishlist: newDataWishlist });
        } catch (error) {
          console.error("Error ", error);
        }
      } else {
        //remove item
        let data = newDataWishlist.filter((value) => value !== id);
        try {
          dbFirestore
            .collection("users")
            .doc(this.state.user.uid)
            .update({ wishlist: data });
          this._isMounted && this.setState({ wishlist: data });
        } catch (error) {
          console.error("Error ", error);
        }
      }
    } else {
      //go to login
      this._isMounted &&
        this.setState({ formVisible: true, formType: "login" });
    }
  }

  close() {
    this._isMounted && this.setState({ formVisible: false });
  }

  displayForm(value) {
    this._isMounted && this.setState({ formVisible: true, formType: value });
  }

  renderItems() {
    if (this.state.data !== null && this.state.data !== undefined) {
      if (this.state.data[0] !== null && this.state.data[0] !== undefined) {
        if (
          this.state.data[0].data !== null &&
          this.state.data[0].data !== undefined
        ) {
          let similars = [];
          for (var i = 0; i < this.state.data[0].data.length; i++) {
            const prod = this.state.data[0].data[i];
            similars.push(
              <div className="col-4 col-md-15" key={"similar-" + i}>
                <div className="card card-category mt-3">
                  {prod.sale && (
                    <div className="forSaleSimilars-box">
                      <p className="mb-0">For Sale</p>
                    </div>
                  )}

                  {/* <div className="wishlist-box">
                    {!this.state.logged && (
                      <i
                        className=" far fa-heart"
                        onClick={() => this.displayForm("login")}
                      />
                    )}

                    {this.state.logged && !this.existWishList(prod.id) && (
                      <i
                        className=" far fa-heart"
                        onClick={e => {
                          this.gotoWishlistItem(prod.id, 1);
                        }}
                      />
                    )}

                    {this.state.logged && this.existWishList(prod.id) && (
                      <i
                        className=" fas fa-heart"
                        onClick={e => {
                          this.gotoWishlistItem(prod.id, 0);
                        }}
                      />
                    )}
                  </div> */}
                  <Link
                    to={"/product/" + this.state.data[0].data[i].id}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    className="special_height"
                  >
                    <img
                      className="card-category-img"
                      src={this.state.data[0].data[i].principalImage}
                      alt={this.state.data[0].data[i].title}
                    />
                  </Link>
                  <div className="card-body card-category-body">
                    <Link to={"/product/" + this.state.data[0].data[i].id}>
                      <ResponsiveEllipsis
                        className="card-title card-category-title closit_text three-lines-titles"
                        text={this.state.data[0].data[i].title}
                        maxLine="3"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </Link>
                    <ResponsiveEllipsis
                      className="card-text card-category-text"
                      text={this.state.data[0].data[i].shortDescription}
                      maxLine="4"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                    {prod.sale && prod.sold_online && (
                      <p className="card-text card-category-text">
                        <span className="marked-price">
                          ${formatNumber(prod.sizes[0].price)}{" "}
                        </span>
                        $
                        {formatNumber(
                          calculateNewPrice(
                            prod.sizes[0].price,
                            prod.discountSale
                          )
                        )}
                      </p>
                    )}
                    {!prod.sale && prod.sold_online && (
                      <p className="card-text card-category-text">
                        ${formatNumber(prod.sizes[0].price)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          }
          return similars;
        }
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.data.length > 0 && this.state.data[0].data.length > 0 && (
          <div className="Similar my-4">
            <div className="container">
              <div className="row">
                <div className="col-12 similar_title_container">
                  <p className="similar_title closit_title mb-0">
                    Similar Items
                  </p>
                </div>
                {this.renderItems()}
              </div>
            </div>
          </div>
        )}
        {this.state.formVisible && (
          <LoginForm
            typeForm={this.state.formType}
            close={this.close.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Similar;
