//Dependencies
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./not-found.css";
import { metasTags } from "../../#constants"
class NotFoundPage extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
    metasTags.setMetaTags();
  }
  render() {
    return (
      <div className="Not-found">
        <div className="bg-404">
          <p>404</p>
        </div>
        <div className="container-fluid">      
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="text-box-404">
                  <p className="title-404">Sorry, the page you were looking for doesn’t exist at this URL.</p>
                  <p className="text-404">Please contact us with any questions.</p>
                  <button className="btn btn-secondary btn-404"> <Link to="/"> Go to home page </Link></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
