import React, { Component } from "react";
import { dbFirestore } from "../../@firebase";
import "./notification-slider.css";

class NotificationSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      exist: false
    };
    this.init = this.init.bind(this);
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.init();
  }
  async init() {
    let docPromotional = await dbFirestore
      .collection("promotional_banner")
      .where("publish", "==", true)
      .where("active", "==", true)
      .get();
    if (!docPromotional.empty) {
      let promotionals = [];
      docPromotional.forEach(value => {
        promotionals.push(value.data());
      });
      this._isMounted &&
        this.setState({ title: promotionals[0].title, exist: true });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <div className="PromotionsBanner">
        {this.state.exist && (
          <div className="NotificationSlider">
            <p className="notification_title closit_text">{this.state.title}</p>
          </div>
        )}
      </div>
    );
  }
}

export default NotificationSlider;
