import React, { Component } from "react";
import { dbFirestore, auth } from "../../@firebase";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import "./history.css";
import Loader from "react-loader-spinner";
import { monthNames, metasTags } from "../../#constants";
class HistoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirect: false,
      visibleLoader: true
    };
    this.renderItems = this.renderItems.bind(this);
    this.init.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    metasTags.setMetaTags();
    auth.onAuthStateChanged(user => {
      if (user) {
        this.init();
      } 
      else {
        this._isMounted && this.setState({ redirect: true, visibleLoader: false });
      }
    });
  }

  async init() {
    let snapshot = await dbFirestore.collection("orders").where("idUser", "==", auth.currentUser.uid).orderBy("createdAt", "desc").get();
    let orders = [];
    if(!snapshot.empty){
      for (let index = 0; index < snapshot.size; index++) {
        orders.push(snapshot.docs[index].data());       
      }
      this._isMounted && this.setState({ data: orders }, () => {
        this._isMounted && this.setState({ visibleLoader: false });
      });
    }
    else{
      this._isMounted && this.setState({ visibleLoader: false });
    }
    
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  createImgProd(item) {
    if (item !== null && item !== undefined) {
      if (item.dataOrder !== null && item.dataOrder !== undefined) {
        let img = [];
        for (var i = 0; i < item.dataOrder.length; i++) {
          img.push(
            <div className="col-8" key={"img-" + i}>
              <img
                className="item-cart-img"
                alt={item.dataOrder[i].dataProduct.title}
                src={item.dataOrder[i].dataProduct.principalImage}
              />
            </div>
          );
        }
        return img;
      }
    }
  }

  renderItems() {
    if (this.state !== null && this.state !== undefined) {
      let orders = [];
      this.state.data.forEach( (item, index) => {
        var date = new Date(item.createdAt.seconds * 1000);
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        orders.push(
          <div className="col-12 col-md-8 new-col-pd" key={"order-" + index}>
            <NavLink className="order_link" to={"/order-detail/" + item.id}>
              <div className="card item-card-cart">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-12 mt-3">
                      <p className="text-l subtitle-history">
                        <span className="closit_text">Order Date:</span>{" "}
                        <span className="subtitle-history">
                          {monthNames[month]} {day}, {year}
                        </span>
                      </p>
                    </div>
                    <div className="col-12 mt-1 mb-3">
                      <p className="text-l mb-0 subtitle-history closit_text">
                        Order Number:
                      </p>
                      <span className="normal">{item.id}</span>
                    </div>
                    <div className="col-10 col-lg-7 no-pd">
                      {this.createImgProd(item)}
                    </div>
                    <i className="fas fa-angle-right angle-size positioned" />
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        );
      });
      return orders;
    }
  }

  render() {
    return (
      <div className="History">
        {this.state.redirect && <Redirect push to={"/"} />}
        {this.state.visibleLoader && (
          <div className="loader-history">
            <Loader type="Oval" color="#000" height="50" width="50" />
          </div>
        )}
        <div className="container">
          <div className="setting-title_container my-4">
            <p className="title-history closit_title mb-0">Orders History</p>
          </div>
          {!this.state.visibleLoader && this.state.data.length > 0 && (
            <div className="row justify-content-center">
              {this.renderItems()}
              <div className="col-12">
                <button
                  className="btn btn-secondary btn-back-order"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Go Back
                </button>
              </div>
            </div>
          )}
          {!this.state.visibleLoader && this.state.data.length === 0 && (
            <div className="row justify-content-center no-orders-box">
              <div className="col-12">
                <h3 className="no-orders">
                  Sorry, you don't have any order yet.
                </h3>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default HistoryPage;
